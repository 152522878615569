import './style.scss';
import { useContext } from 'react';
import { Context } from 'hooks/store';
import Table from 'components/table';
import Button from 'components/button';
import SearchComponent from './components/search';
import { parsingDate } from 'services/valueConvertor';
import { ReactComponent as ClusterIcon } from 'assets/images/clusterIcon.svg';
import StatusBadge from './components/statusBadge';
import { IoClose } from 'react-icons/io5';
import { ReactComponent as RunningIcon } from 'assets/images/badgeIcons/running.svg';
import CustomButtonWrapper from 'components/buttonsWrapper/CustomButtonWrapper';
import { useEffect, useState } from 'react';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import Loader from 'components/loader';
import RefreshButton from 'components/refreshButton';
import ReactApexChart from 'react-apexcharts';
import useTableDynamicPageSize from 'hooks/useTableDynamicPageSize';
import useResizableColumns from 'components/table/useResizableColumns';
import TodoList from '../../components/todoList';

const AsyncJobs = () => {
    const httpRequest = useHttpRequest();
    const [state, dispatch] = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStartGroup, setLoadingStartGroup] = useState({});
    const [loadingStart, setLoadingStart] = useState({});
    const [loadingCancelGroup, setLoadingCancelGroup] = useState({});
    const [loadingCancel, setLoadingCancel] = useState({});
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const pageSize = useTableDynamicPageSize();

    useEffect(() => {
        state?.newJobIndicator && dispatch({ type: 'SET_NEW_JOB_INDICATOR', payload: false });
        fetchData().catch();
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await httpRequest('GET', apiEndpoints.TASKS_GET_ALL_GROUPS);
            const sortedData = response
                .sort((a, b) => a.id - b.id)
                .map((item) => ({
                    ...item,
                    tasks: item.tasks.sort((a, b) => a.id - b.id)
                }));
            setData(sortedData);
            setInitialData(sortedData);
        } catch (error) {
            setIsLoading(false);
        } finally {
            setIsLoading(false);
        }
    };

    const arrangeData = (data) => {
        let chartData = [];
        if (data?.num_completed_tasks > 0) {
            chartData.push({
                name: 'Completed tasks',
                color: 'var(--badge-success)',
                data: [data?.num_completed_tasks]
            });
        }
        if (data?.num_failed_tasks > 0) {
            chartData.push({
                name: 'Failed tasks',
                color: 'var(--failed-color)',
                data: [data?.num_failed_tasks]
            });
        }
        if (data?.num_running_tasks > 0) {
            chartData.push({
                name: 'Running tasks',
                color: 'var(--primary-color)',
                data: [data?.num_running_tasks]
            });
        }
        if (data?.num_pending_tasks > 0) {
            chartData.push({
                name: 'Pending tasks',
                color: 'var(--page-subtitle-gray-color)',
                data: [data?.num_pending_tasks]
            });
        }

        if (data?.num_canceled_tasks > 0) {
            chartData.push({
                name: 'Canceled tasks',
                color: 'var(--badge-warning)',
                data: [data?.num_canceled_tasks]
            });
        }

        return chartData;
    };

    const handleStartGroup = async (id) => {
        setLoadingStartGroup((prev) => ({ ...prev, [id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.TASKS_START_GROUP, { task_group_id: id });
        } catch (error) {
            setLoadingStartGroup((prev) => ({ ...prev, [id]: false }));
        } finally {
            setLoadingStartGroup((prev) => ({ ...prev, [id]: false }));
            await fetchData();
            setLoadingStart((prev) => ({ ...prev, [id]: false }));
        }
    };

    const handleCancelGroup = async (id) => {
        setLoadingCancelGroup((prev) => ({ ...prev, [id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.TASKS_CANCEL_GROUP, { task_group_id: id });
        } catch (error) {
            setLoadingCancelGroup((prev) => ({ ...prev, [id]: false }));
        } finally {
            await fetchData();
            setLoadingCancelGroup((prev) => ({ ...prev, [id]: false }));
        }
    };

    const handleStart = async (id) => {
        setLoadingStart((prev) => ({ ...prev, [id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.TASKS_START, { task_id: id });
        } catch (error) {
            setLoadingStart((prev) => ({ ...prev, [id]: false }));
        } finally {
            setLoadingStart((prev) => ({ ...prev, [id]: false }));
        }
    };

    const handleCancel = async (id) => {
        setLoadingCancel((prev) => ({ ...prev, [id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.TASKS_CANCEL, { task_id: id });
        } catch (error) {
            setLoadingCancel((prev) => ({ ...prev, [id]: false }));
        } finally {
            await fetchData();
            setLoadingCancel((prev) => ({ ...prev, [id]: false }));
        }
    };

    const expandedRowRender = (record) => {
        const columns = [
            {
                title: 'Job name',
                key: 'jobName',
                width: '69%',
                render: (text, record) => record.type + ' - ' + record.data
            },
            {
                title: 'Status',
                key: 'status',
                render: (text, record) => {
                    return <StatusBadge type={record.status} reason={record.failed_reason} />;
                }
            },
            {
                title: 'Action',
                key: 'action',
                render: (text, record) => (
                    <div className="table-action-wrapper">
                        {record.status === 'canceled' && (
                            <Button
                                placeholder={<CustomButtonWrapper icon={<RunningIcon />} text="Run" withArrow={false} />}
                                onClick={() => handleStart(record.id)}
                                customClassName="table-action-btn"
                                loading={loadingStart[record.id]}
                            />
                        )}
                        {record.status === 'pending' && (
                            <Button
                                loading={loadingCancel[record.id]}
                                onClick={() => handleCancel(record.id)}
                                placeholder={<IoClose />}
                                customClassName="table-action-btn rounded"
                            />
                        )}
                    </div>
                )
            }
        ];

        return <Table columns={columns} dataSource={record?.tasks?.map((task) => ({ ...task, key: task?.id }))} pagination={false} />;
    };

    const columns = [
        {
            title: 'Job id',
            key: 'id',
            render: (text, record) => {
                return <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>{record.id}</div>;
            },
            width: 1
        },
        {
            title: 'Cluster',
            key: 'cluster',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                        <ClusterIcon />
                        {record.cluster_name}
                    </div>
                );
            },
            width: 1
        },
        {
            title: 'Created at',
            key: 'created_at',
            render: (text, record) => parsingDate(record.created_at),
            width: 1
        },
        {
            title: 'Owner name',
            key: 'owner_name',
            render: (text, record) => record.created_by,
            width: 1
        },
        {
            title: 'Progress',
            key: 'status',
            render: (text, record) => {
                let series = arrangeData(record);
                return (
                    <div className="apex-chart-cell-wrapper">
                        <ReactApexChart options={chartOptions} series={series} type="bar" height={70} width={250} />
                    </div>
                );
            },
            width: 1
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <div className="table-action-wrapper">
                    {record.num_running_tasks === 0 && (record.num_canceled_tasks > 0 || record.num_pending_tasks > 0) && (
                        <Button
                            loading={loadingStartGroup[record.id]}
                            onClick={() => handleStartGroup(record.id)}
                            placeholder={<CustomButtonWrapper icon={<RunningIcon />} text="Run" withArrow={false} />}
                            customClassName="table-action-btn"
                        />
                    )}
                    {record.num_running_tasks > 0 && record.num_pending_tasks > 0 && (
                        <Button
                            loading={loadingCancelGroup[record.id]}
                            onClick={() => handleCancelGroup(record.id)}
                            placeholder={<IoClose />}
                            customClassName="table-action-btn rounded"
                        />
                    )}
                </div>
            ),
            width: 1
        }
    ];

    const chartOptions = {
        grid: {
            show: false
        },
        chart: {
            type: 'bar',
            height: 40,
            stacked: true,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                horizontal: true,
                dataLabels: {
                    total: {
                        enabled: true,
                        offsetX: 0
                    }
                }
            }
        },
        stroke: {
            width: 1,
            colors: ['#fff']
        },
        xaxis: {
            axisBorder: {
                show: false
            },
            axisTicks: {
                show: false
            },
            labels: {
                show: false
            }
        },
        yaxis: {
            show: false
        },
        tooltip: {
            enabled: true,
            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                return '<div class="custom-tooltip">' + w.globals.seriesNames[seriesIndex] + ': ' + series[seriesIndex][dataPointIndex] + '</div>';
            }
        },
        fill: {
            opacity: 1
        },
        legend: {
            show: false
        }
    };

    const handleSearch = (value) => {
        if (value?.length === 0) {
            setData(initialData);
        } else {
            const lowercasedValue = value?.toLowerCase();
            const filteredData = initialData?.filter((item) => item?.cluster_name?.toLowerCase().includes(lowercasedValue));
            setData(filteredData);
        }
    };

    const { components, resizableColumns } = useResizableColumns(columns);

    return isLoading ? (
        <Loader isFullHeight={true} background={false} />
    ) : (
        <div className="async-jobs management-layout-container">
            <div className="async-jobs-heading">
                <div className="async-jobs-heading-left">
                    <h1>Tasks</h1>
                </div>
            </div>

            {!isLoading && (
                <div className="async-jobs-body">
                    <TodoList showTitle={false} height="calc( 100vh - 180px )" isTasksPage={true} />
                </div>
            )}
        </div>
    );
};

export default AsyncJobs;
