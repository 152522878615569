import React from 'react';
import { Tooltip } from 'antd';

const TooltipComponent = ({ className, text, placement = 'bottom', children, disabled = false, overlayStyle }) => {
    const fieldProps = {
        title: text || '',
        color: 'var(--bg-white)',
        placement,
        overlayInnerStyle: { fontFamily: 'Inter', color: 'var(--text-color)' },
        className: className,
        zIndex: 9998,
        overlayStyle
    };

    return disabled ? (
        children
    ) : (
        <Tooltip {...fieldProps}>
            <>{children}</>
        </Tooltip>
    );
};

export default TooltipComponent;
