import './style.scss';

import { useEffect, useState, useContext } from 'react';
import { Form, Result } from 'antd';
import { ReactComponent as KeysIcon } from 'assets/images/keysIcon.svg';
import { ReactComponent as WarningResultIcon } from 'assets/images/warningResultIcon.svg';
import { ReactComponent as SuccessResultIcon } from 'assets/images/successResultIcon.svg';
import ConnectorModalHeader from './keyModalComponents/modalHeader';
import KeyModalForm from './keyModalComponents/form';
import ConnectorModalFooter from './keyModalComponents/modalFooter';
import { apiEndpoints } from 'services/apiEndpoints';
import { Context } from 'hooks/store';
import Spinner from 'components/spinner';
import Modal from 'components/modal';
import { useHttpRequest } from 'services/http';

const Index = ({ isModalOpen, setIsModalOpen, currentCluster, keyToUpdate }) => {
    const httpRequest = useHttpRequest();
    const [state, dispatch] = useContext(Context);
    const [form] = Form.useForm();
    const [currentModalState, setCurrentModalState] = useState('create');
    const [isUpdated, setIsUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    useEffect(() => {
        keyToUpdate ? form.setFieldsValue({ ...keyToUpdate }) : form.resetFields();
    }, [keyToUpdate]);

    useEffect(() => {
        if (isModalOpen) {
            setCurrentModalState('create');
        } else if (!keyToUpdate) form.resetFields();
        else form.setFieldsValue({ ...keyToUpdate });
    }, [isModalOpen, keyToUpdate, form]);

    const checkIfUpdated = (keyToUpdate, updatedKey) => {
        let updated = false;
        for (const key in updatedKey) {
            if (Array.isArray(updatedKey[key])) {
                if (updatedKey[key].length !== keyToUpdate[key].length || updatedKey[key].every((a1) => !keyToUpdate[key].includes(a1))) {
                    updated = true;
                    break;
                }
            } else if (updatedKey[key] !== keyToUpdate[key]) {
                updated = true;
                break;
            }
        }
        setIsUpdated(updated);
    };

    const getUpdatedObject = (keyToUpdate, updatedKey) => {
        let updatedKeys = {};
        for (const key in updatedKey) {
            if (Array.isArray(updatedKey[key])) {
                if (updatedKey[key].length !== keyToUpdate[key].length || updatedKey[key].every((a1) => !keyToUpdate[key].includes(a1))) {
                    updatedKeys[key] = updatedKey[key];
                }
            } else if (updatedKey[key] !== keyToUpdate[key]) {
                updatedKeys[key] = updatedKey[key];
            }
        }
        return updatedKeys;
    };

    const onFinish = async (values) => {
        if (keyToUpdate) values = getUpdatedObject(keyToUpdate, values);
        createKey(values);
    };

    const createKey = async (values) => {
        setIsLoading(true);
        try {
            let data;
            if (keyToUpdate) {
                data = await httpRequest('PUT', apiEndpoints.UPDATE_KEY, { id: keyToUpdate?.id, ...values });
                dispatch({ type: 'SET_KEYS_LIST', payload: state?.keysList?.map((key) => (key.id === keyToUpdate.id ? data?.key : key)) });
            } else {
                data = await httpRequest('POST', apiEndpoints.CREATE_KEY, values);
                dispatch({ type: 'SET_KEYS_LIST', payload: state?.keysList?.length === 0 ? [data?.key] : [...state?.keysList, data?.key] });
            }
            setCurrentModalState('created');
            setIsLoading(false);
            setErrMessage('');
        } catch (error) {
            if (error.status === 499) {
                setErrMessage(error.data.error);
                setCurrentModalState('failed');
            }
            setIsLoading(false);
        }
    };

    const connectorState = {
        create: {
            icon: <KeysIcon />,
            title: keyToUpdate ? 'Modify a key' : 'Add a new key'
        },
        creating: {
            icon: <KeysIcon />,
            title: 'Connecting'
        },
        failed: {
            icon: <KeysIcon />,
            title: 'Key creation failed'
        },
        created: {
            icon: <KeysIcon />,
            title: keyToUpdate ? 'Key has been updated' : 'New key has been created'
        }
    };

    return (
        <Modal isModalOpen={isModalOpen} clickOutside={() => setIsModalOpen(false)} getContainer={false}>
            <div className="ms-modal connect-cluster-modal">
                <div className="ms-modal-header">
                    <ConnectorModalHeader {...connectorState[currentModalState]} />
                </div>
                <div className="ms-modal-body">
                    {currentModalState === 'create' && (
                        <KeyModalForm
                            onFinish={onFinish}
                            form={form}
                            currentCluster={currentCluster}
                            keyToUpdate={keyToUpdate}
                            updated={(value) => keyToUpdate && checkIfUpdated(keyToUpdate, value)}
                        />
                    )}
                    {currentModalState === 'creating' && (
                        <div className="connector-modal-loading">
                            <div className="connector-modal-loading-spinner">
                                <Spinner fontSize={60} />
                            </div>
                            <h2>Creating key</h2>
                        </div>
                    )}
                    {currentModalState === 'failed' && <Result rootClassName="result-section" icon={<WarningResultIcon />} subTitle={errMessage} />}
                    {currentModalState === 'created' && (
                        <Result rootClassName="result-section" icon={<SuccessResultIcon />} title={<h2>Successfully {keyToUpdate ? 'updated' : 'created'}</h2>} />
                    )}
                </div>
                <div className="ms-modal-footer">
                    <ConnectorModalFooter
                        setIsModalOpen={setIsModalOpen}
                        onClick={() => form.submit()}
                        currentModalState={currentModalState}
                        onBack={() => {
                            if (currentModalState !== 'failed') form.resetFields();
                            setCurrentModalState('create');
                            setErrMessage('');
                            if (!keyToUpdate?.id) form.resetFields();
                        }}
                        edit={Boolean(keyToUpdate)}
                        isUpdated={isUpdated}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default Index;
