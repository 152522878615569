import './style.scss';

const Badge = ({ text, type, icon, onClick = null, color, backgroundColor }) => {
    return (
        <div className={'ms-badge ' + type + (onClick ? ' pointer' : '')} style={{ color, backgroundColor }} onClick={onClick ? () => onClick() : null}>
            {icon && icon}
            <label style={onClick ? { cursor: 'pointer' } : {}}>{text}</label>
        </div>
    );
};

export default Badge;
