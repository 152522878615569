import './style.scss';
import Button from '../button';
import { TbHexagonMinus } from 'react-icons/tb';
import { IoMdCheckboxOutline } from 'react-icons/io';
import Tooltip from '../tooltip/tooltip';
import { FaLock, FaPlay } from 'react-icons/fa';
import Badge from '../badge';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { CgDanger } from 'react-icons/cg';

const ListSubItem = ({ item, triggerConfirmationForSubTask, loadingItemId = null, fixable, tasks }) => {
    const statuses = {
        fixed: 'Fixed',
        ignored: 'Marked as ignore',
        failed: 'Failed'
    };

    const isVisible = (item) => {
        const status = item?.status;
        return !['ignored', 'fixed', 'failed', 'locked'].includes(status);
    };

    return (
        <div className="sub-list-item">
            <div className="top">
                <div className="sub-list-item-title">
                    <span style={{ color: 'var(--page-subtitle-gray-color)' }}>Name:</span>
                    {item?.data ? (
                        <Tooltip text={item?.data} disabled={item?.data?.length < 50}>
                            <div className="eclipse-text" style={{ maxWidth: 330 }}>
                                {item?.data}
                            </div>
                        </Tooltip>
                    ) : (
                        'N/A'
                    )}
                </div>
                <div className="list-item-actions">
                    {item?.status === 'locked' && (
                        <Tooltip text={'This topic is protected'}>
                            <Badge text={<FaLock fontSize={12} color={'var(--primary-color)'} />} type={'secondary'} />
                        </Tooltip>
                    )}
                    {['fixed', 'ignored'].includes(item?.status) && (
                        <Badge
                            text={
                                <div style={{ display: 'flex', alignItems: 'center', gap: 4, padding: '2px 7px' }}>
                                    {item?.status === 'fixed' ? <FaRegCircleCheck /> : <TbHexagonMinus />}
                                    {statuses[item?.status]}
                                </div>
                            }
                            type={'secondary'}
                        />
                    )}
                    {item?.status === 'failed' && (
                        <Tooltip text={item?.failed_reason}>
                            <Badge
                                text={
                                    <div style={{ display: 'flex', alignItems: 'center', gap: 4, padding: '2px 7px' }}>
                                        <CgDanger />
                                        {statuses[item?.status]}
                                    </div>
                                }
                                type={'danger'}
                            />
                        </Tooltip>
                    )}
                    {isVisible(item) && (
                        <Tooltip text={'Mark as ignored'} placement={'top'}>
                            <Button
                                shape="circle"
                                placeholder={<TbHexagonMinus color={'var(--text-color)'} />}
                                disabled={item?.status === 'fixed' || item?.status === 'ignored'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    triggerConfirmationForSubTask('markAsIgnored', item);
                                }}
                            />
                        </Tooltip>
                    )}
                    {isVisible(item) && (
                        <Tooltip text={'Mark as fixed'} placement={'top'}>
                            <Button
                                shape="circle"
                                placeholder={<IoMdCheckboxOutline color={'var(--text-color)'} />}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    triggerConfirmationForSubTask('markAsCompleted', item);
                                }}
                            />
                        </Tooltip>
                    )}
                    {isVisible(item) && (
                        <Tooltip
                            text={
                                item?.fixable === false
                                    ? item?.task_type === 'reduce_payload'
                                        ? 'We couldn’t find any supported producers for that topic. Please use our library to enable it.'
                                        : item?.fixable_reason
                                    : 'Fix'
                            }
                            placement={'top'}
                        >
                            <Button
                                shape="circle"
                                placeholder={<FaPlay color={'var(--ready-text-color)'} />}
                                disabled={item?.status === 'fixed' || item?.status === 'ignored' || item?.fixable === false}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    triggerConfirmationForSubTask('fix', item);
                                }}
                            />
                        </Tooltip>
                    )}
                </div>
            </div>
            <div className="body">
                <div className="meta">
                    {item?.metadata &&
                        Object.entries(item.metadata).map(([key, { label, value }], index) => (
                            <div
                                key={index}
                                className={`meta-item ${key === 'cgs' ? 'full-width' : ''} ${
                                    ((index + 2) % 3 !== 0 || index === 1) && key !== 'cgs' ? 'divider-vertical' : ''
                                }`}
                            >
                                {label}:
                                {key === 'cgs' && value !== null ? (
                                    Array.isArray(value) && typeof value[0] === 'object' ? (
                                        <>
                                            <Tooltip
                                                overlayStyle={{ maxWidth: 350, fontSize: 12 }}
                                                placement={'left'}
                                                text={
                                                    <ul style={{ paddingLeft: 20, display: 'inline-flex', flexDirection: 'column', gap: 7 }}>
                                                        {value
                                                            .filter(({ isActive }) => isActive)
                                                            .map(({ name }) => (
                                                                <li style={{ wordBreak: 'break-all' }} key={name}>
                                                                    {name}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                }
                                                disabled={
                                                    (value &&
                                                        Array.isArray(value) &&
                                                        value.filter(({ isActive }) => isActive).reduce((acc, { name }) => acc + (name ? name.length : 0), 0) < 70) ||
                                                    false
                                                }
                                            >
                                                <span>
                                                    {value && Array.isArray(value)
                                                        ? value
                                                              .filter(({ isActive }) => isActive)
                                                              .map(({ name }) => <span key={name}>{name}</span>)
                                                              .reduce((prev, curr) => [prev, ', ', curr], [])
                                                        : 'N/A'}
                                                </span>
                                            </Tooltip>
                                            <br />
                                            Inactive Consumer Groups:
                                            <Tooltip
                                                overlayStyle={{ maxWidth: 350, fontSize: 12 }}
                                                placement={'left'}
                                                text={
                                                    <ul style={{ paddingLeft: 20, display: 'inline-flex', flexDirection: 'column', gap: 7 }}>
                                                        {value
                                                            .filter(({ isActive }) => !isActive)
                                                            .map(({ name }) => (
                                                                <li style={{ wordBreak: 'break-all' }} key={name}>
                                                                    {name}
                                                                </li>
                                                            ))}
                                                    </ul>
                                                }
                                                disabled={
                                                    (value &&
                                                        Array.isArray(value) &&
                                                        value.filter(({ isActive }) => !isActive).reduce((acc, { name }) => acc + (name ? name.length : 0), 0) < 70) ||
                                                    false
                                                }
                                            >
                                                <span>
                                                    {value && Array.isArray(value)
                                                        ? value
                                                              .filter(({ isActive }) => !isActive)
                                                              .map(({ name }) => <span key={name}>{name}</span>)
                                                              .reduce((prev, curr) => [prev, ', ', curr], [])
                                                        : 'N/A'}
                                                </span>
                                            </Tooltip>
                                        </>
                                    ) : (
                                        <Tooltip
                                            overlayStyle={{ maxWidth: 350, fontSize: 12 }}
                                            placement={'bottom'}
                                            text={value}
                                            disabled={value && value[0].length < 70}
                                        >
                                            <span>{value.join(', ') || 'N/A'}</span>
                                        </Tooltip>
                                    )
                                ) : (
                                    <span>{isNaN(value) ? value : value?.toLocaleString() || 'N/A'}</span>
                                )}
                            </div>
                        ))}
                </div>
            </div>
        </div>
    );
};

export default ListSubItem;
