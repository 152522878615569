import './style.scss';
import 'reactflow/dist/style.css';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import ReactFlow, { addEdge, Background, Controls, MiniMap, useEdgesState, useNodesState } from 'reactflow';
import nodeTypes from './components/nodes';
import AddConnectorDrawer from './components/addConnectorDrawer';
import { Context } from 'hooks/store';
import { LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED } from 'utils/localStorageConsts';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import FormattedData from './components/formattedData';
import Loader from 'components/loader';
import ConnectorModal from 'components/Modals/ConnectorModal';
import generateDynamicNodesAndEdgesD3 from './components/generateTempData';
import { useNavigate } from 'react-router-dom';
import { uuidv4 } from 'services/valueConvertor';
import OrphanClientsDrawer from './components/orphanClientsDrawer';

const connectionLineStyle = { stroke: '#5A4FE5', strokeWidth: 2 };
const snapGrid = [25, 25];
const defaultViewport = { x: 300, y: 300, zoom: 0.8 };

const initialEdges = [{ id: 'e1-2', source: 'superstream', target: 'addDataPlan', animated: false, style: { stroke: '#615EF6', strokeWidth: 2 }, type: 'smoothstep' }];

const SystemView = () => {
    const [nodes, setNodes, onNodesChange] = useNodesState([]);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const [dataPlanModal, setDataPlanModal] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [, dispatch] = useContext(Context);
    const httpRequest = useHttpRequest();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();
    const [orphanClientsDrawerOpen, setOrphanClientsDrawerOpen] = useState(false);
    const [orphanClients, setOrphanClients] = useState([]);

    const onConnect = useCallback((params) => {
        const newEdge = {
            ...params,
            animated: true,
            style: { stroke: 'var(--primary-color)', strokeWidth: 2 }
        };
        setEdges((eds) => addEdge(newEdge, eds));
    }, []);

    const onMoveStart = () => handleSidebarCollapse();
    //const onNodeDragStart = () => handleSidebarCollapse();

    const handleSidebarCollapse = () => {
        dispatch({ type: 'SET_SIDEBAR_STATE', payload: true });
        localStorage.setItem(LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED, 'true');
    };

    const getGraphData = async () => {
        return await httpRequest('GET', apiEndpoints.GET_GRAPH_DATA, {}, {}, {}, false);
    };

    const handleOrphanClients = (orphanClients) => {
        setOrphanClients(orphanClients);
        setOrphanClientsDrawerOpen(true);
    };

    const retrieveData = async () => {
        const handleClickOnClientNode = (param) => {
            navigate(`/connected-clusters/${param}?tab=clients`);
        };

        setIsLoading(true);
        getGraphData()
            .then((data) => {
                const listOfDataPlanes = [...data?.listOfDataPlanes].sort((a, b) => b?.env_name.localeCompare(a?.env_name));
                const activeClients = [...data?.active_clients].sort((a, b) => a.id - b.id);
                const { nodes, edges } = FormattedData(listOfDataPlanes, activeClients, setDataPlanModal, setIsModalOpen, handleClickOnClientNode, handleOrphanClients);
                setNodes(nodes);
                setEdges(edges.map((edge) => ({ ...edge, id: uuidv4() })));
            })
            .finally(() => {
                setIsLoading(false);
                setIsModalOpen(false);
            });
    };

    useEffect(() => {
        retrieveData();
    }, []);

    const nodeColor = (node) => {
        switch (node.type) {
            case 'dataPlane':
                return 'var(--primary-color)';
            case 'kafka':
                return 'var(--sunglow)';
            case 'superstream':
                return 'var(--greenish-cyan)';
            case 'app':
                return 'var(--badge-success)';
            default:
                return 'var(--placeholder-color)';
        }
    };

    return isLoading ? (
        <Loader background={false} isFullHeight={true} />
    ) : (
        <div className="system-view">
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                nodeTypes={nodeTypes}
                onConnect={onConnect}
                connectionLineStyle={connectionLineStyle}
                snapToGrid={true}
                snapGrid={snapGrid}
                defaultViewport={defaultViewport}
                onMoveStart={onMoveStart}
                //onNodeDragStart={onNodeDragStart}
                nodesConnectable={false}
                deleteKeyCode={null}
                fitView
                fitViewOptions={{ maxZoom: 0.8, duration: 1500 }}
                panOnScroll={true}
                maxZoom={1}
                minZoom={-1}
            >
                <Background variant="dots" gap={50} size={2.5} />
                <Controls showInteractive={false} position={'bottom-right'} />
                <MiniMap nodeColor={nodeColor} nodeStrokeWidth={3} position="top-right" zoomable pannable />
            </ReactFlow>
            <AddConnectorDrawer dataPlanModal={dataPlanModal} setDataPlanModal={setDataPlanModal} />
            <ConnectorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} sysViewUpdate={retrieveData} />
            <OrphanClientsDrawer open={orphanClientsDrawerOpen} onClose={() => setOrphanClientsDrawerOpen(false)} orphanClients={orphanClients} />
        </div>
    );
};

export default SystemView;
