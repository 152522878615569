import './style.scss';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceDot, Legend } from 'recharts';
import { useEffect, useState } from 'react';
import { convertReportValue, convertStorage, convertNumbers } from 'services/genericServices';

const Chart = ({ data, type, isDataAvailable, showLegend = false, startDate }) => {
    const [highlightedArea, setHighlightedArea] = useState(null);
    const [formattedData, setFormattedData] = useState([]);
    const [intersections, setIntersections] = useState([]);

    const isGraphEmpty = (data) => {
        const totalWithoutSuperstream = data.reduce((total, item) => total + item.without_superstream, 0);
        const totalSuperstream = data.reduce((total, item) => total + item.superstream, 0);
        return totalWithoutSuperstream === 0 && totalSuperstream === 0;
    };

    const getIntersections = (data) => {
        return data.filter(item => item.superstream === item.without_superstream);
    };

    const CustomIcon = (props) => {
        const [isHovered, setIsHovered] = useState(false);

        return (
            <g
                transform={`translate(${props.cx - 10},${props.cy - 10})`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                style={{ transition: 'all 0.3s ease-in-out' }}
            >
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1.81055" y="1.80078" width="15" height="15" rx="7.5" fill="white" stroke={isHovered ? '#37D2BC' : '#B9EFE5'} strokeWidth="3" />
                    <path
                        d="M9.88783 6.46647L10.4746 7.64012C10.5547 7.80016 10.7681 7.9602 10.9459 7.98687L12.0084 8.1647C12.6886 8.28028 12.8486 8.7693 12.3596 9.25832L11.5327 10.0897C11.3949 10.2275 11.3149 10.4986 11.3593 10.6943L11.5949 11.7212C11.7817 12.5303 11.3504 12.8459 10.6347 12.4236L9.63887 11.8323C9.4566 11.7256 9.16319 11.7256 8.98092 11.8323L7.98066 12.4192C7.26491 12.8415 6.83369 12.5258 7.0204 11.7167L7.25602 10.6898C7.30048 10.4986 7.22046 10.2275 7.08264 10.0852L6.2602 9.26277C5.77118 8.77375 5.93123 8.28028 6.61141 8.16914L7.67391 7.99132C7.85173 7.9602 8.06512 7.8046 8.14514 7.64456L8.73197 6.47092C9.0476 5.83075 9.57219 5.83075 9.88783 6.46647Z"
                        fill="#6358FA"
                    />
                </svg>
            </g>
        );
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const filteredPayload = payload.filter(entry => entry.dataKey === 'superstream' || entry.dataKey === 'without_superstream');

            return (
                <div style={{ backgroundColor: '#141D2DCC', border: 'none', borderRadius: '10px', padding: '12px', color: '#FFFFFF', fontSize: '10px' }}>
                    <p style={{color: "#fff"}}>{new Date(label).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}</p>
                    <div style={{display: "flex", flexDirection: "column-reverse"}}>
                        {filteredPayload.map((entry, index) => (
                            <p key={index} style={{color: "#fff"}}>
                                {`
                                    ${entry.dataKey === 'superstream' ? 'With Superstream: ' : 'Current status: '}
                                    ${type === 'partitions' ? convertNumbers(entry?.value) : convertStorage(entry?.value, false, false)}    
                                `}
                            </p>
                        ))}
                    </div>
                </div>
            );
        }
        return null;
    }

    useEffect(() => {
        const formatData = data.map((item) => {
            const unixTimestamp = new Date(item.date).getTime();

            return {
                ...item,
                date: unixTimestamp,
                superstream: item?.superstream,
                without_superstream:item?.without_superstream
            };
        }).sort((a, b) => a.date - b.date);

        setFormattedData(formatData);
        setIntersections(getIntersections(formatData));
    }, [data]);

    return formattedData.length > 0 && (
        <ResponsiveContainer minWidth={150} minHeight={200} maxWidth="100%" maxHeight="100%">
            <AreaChart data={formattedData} margin={{ top: 20, right: 16, left: -10, bottom: 0 }}>
                <CartesianGrid strokeDasharray="10 0" vertical={false} strokeOpacity={0.4} stroke="#E8ECFF" />

                {isDataAvailable ? (
                    <XAxis
                        dataKey="date"
                        //ticks={[data[0].date, data[data.length - 1].date]}
                        tickFormatter={(date, index) => {
                            if (index === 0) return new Date(formattedData[0].date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                            if (index === formattedData.length - 1) return new Date(formattedData[formattedData.length - 1].date).toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
                            return '';
                        }}
                        tickLine={true}
                        axisLine={true}
                        tick={{ fontSize: '10px', fill: '#98A2B3' }}
                        tickMargin={10}
                        tickSize={.1}
                        scale="time"
                        type="number"
                        domain={['dataMin', 'dataMax']}
                        interval={0}
                        includeHidden
                    />
                ) : (
                    <XAxis
                        dataKey={0}
                        ticks={null}
                        tickLine={false}
                        axisLine={false}
                        tick={{ fontSize: '10px', fill: '#98A2B3', display: 'none' }}
                        tickMargin={0}
                        includeHidden
                        width={'100px'}
                    />
                )}
                {isGraphEmpty(data) ?
                    <YAxis
                        ticks={[0, 25, 50, 75, 100]}
                        tickLine={false}
                        axisLine={!isDataAvailable}
                        tick={{ fontSize: '10px', fill: '#98A2B3', width: 200 }}
                        tickMargin={5}
                        domain={[0, 'dataMax']}
                        tickFormatter={(value) => convertReportValue(type === 'partitions' ? 'patitionsAxis' : type, value, true)}
                    /> :
                    <YAxis
                        tickLine={false}
                        axisLine={!isDataAvailable}
                        tick={{ fontSize: '10px', fill: '#98A2B3', width: 200 }}
                        tickMargin={5}
                        domain={[0, 'dataMax']}
                        tickFormatter={(value) => convertReportValue(type === 'partitions' ? 'patitionsAxis' : type, value, true)}
                    />
                }
                {isDataAvailable && (
                    <>
                        <Tooltip
                            offset={30}
                            cursor={{ stroke: '#E8ECFF', strokeWidth: 2, strokeDasharray: '5 4' }}
                            content={<CustomTooltip/>}
                            // contentStyle={{
                            //     backgroundColor: '#141D2DCC',
                            //     border: 'none',
                            //     borderRadius: '10px',
                            //     padding: '12px',
                            //     color: '#FFFFFF',
                            //     fontSize: '10px'
                            // }}
                            // labelStyle={{ fontSize: '10px', color: '#fff' }}
                            // labelFormatter={(label) => new Date(label).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
                            // itemStyle={{ fontSize: '10px', color: '#FFFFFF' }}
                            // formatter={(value, name) => [
                            //     `${convertReportValue(type, value)}`, name === 'superstream' ?
                            //         'With Superstream' :
                            //         'Current status'
                            // ]}

                        />
                        {showLegend && (
                            <Legend
                                verticalAlign="bottom"
                                align="center"
                                iconSize={10}
                                wrapperStyle={{ color: '#98A2B3', fontSize: '14px', width: '100%' }}
                                onMouseEnter={(e) => setHighlightedArea(e.dataKey)}
                                onMouseLeave={() => setHighlightedArea(null)}
                                content={() => {
                                    return (
                                        <ul style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                            <li
                                                style={{
                                                    color: '#564AFA',
                                                    cursor: 'pointer',
                                                    listStyle: 'inside',
                                                    marginRight: '10px',
                                                    fontSize: '10px'
                                                }}
                                                onMouseEnter={() => setHighlightedArea('without_superstream')}
                                                onMouseLeave={() => setHighlightedArea(null)}
                                            >
                                                Current status
                                            </li>
                                            <li
                                                style={{
                                                    color: '#37D2BC',
                                                    cursor: 'pointer',
                                                    listStyle: 'inside',
                                                    marginLeft: '10px',
                                                    fontSize: '10px'
                                                }}
                                                onMouseOver={() => setHighlightedArea('superstream')}
                                                onMouseLeave={() => setHighlightedArea(null)}
                                            >
                                                With Superstream
                                            </li>
                                        </ul>
                                    );
                                }}
                            />
                        )}
                        <Area
                            type="monotone"
                            dataKey="superstream"
                            stroke="#37D2BC"
                            fill="rgba(55, 210, 188, 0.078)"
                            strokeWidth={highlightedArea === 'superstream' ? 2.8 : 2.5}
                            dot={{ r: 1.2, strokeWidth: highlightedArea === 'superstream' ? 3.2 : 3, stroke: '#37D2BC' }}
                            activeDot={{ r: 3, strokeWidth: 2, stroke: '#E0FFED' }}
                            opacity={highlightedArea && highlightedArea !== 'superstream' ? 0.1 : 1}
                            animationDuration={1200}
                        />
                        <Area
                            type="monotone"
                            dataKey="without_superstream"
                            stroke="#564AFA"
                            fill="rgba(86, 74, 250, 0.06)"
                            strokeWidth={highlightedArea === 'without_superstream' ? 2.8 : 2.5}
                            dot={{ r: 1.2, strokeWidth: highlightedArea === 'without_superstream' ? 3.2 : 3, stroke: '#564AFA' }}
                            activeDot={{ r: 3, strokeWidth: 2, stroke: '#B1ABFF' }}
                            opacity={highlightedArea && highlightedArea !== 'without_superstream' ? 0.1 : 1}
                            animationDuration={1200}
                        />
                        {intersections.map((point, index) => (
                            <ReferenceDot
                                key={index}
                                x={point.date}
                                y={point.superstream}
                                r={8}
                                shape={<CustomIcon />}
                            />
                        ))}
                    </>
                )}
            </AreaChart>
        </ResponsiveContainer>
    );
};

export default Chart;
