import './style.scss';
import Drawer from 'components/drawer';
import ConnectorDrawerSteps from './stepHandler';
import ConnectorDrawerHeader from './header';
import Button from 'components/button';
import { useState } from 'react';
import StepOne from './steps/stepOne';
import StepTwo from './steps/stepTwo';
import StepThree from './steps/stepThree';
import StepFour from './steps/stepFour';

const AddConnectorDrawer = ({ dataPlanModal, setDataPlanModal }) => {
    const [currentStep, setCurrentStep] = useState(0);

    const handleStep = (action, step) => {
        if (action === 'next') setCurrentStep(currentStep + 1);
        if (action === 'prev') setCurrentStep(currentStep - 1);
        if (action === 'set') setCurrentStep(step);
    };

    return (
        <Drawer
            placement="right"
            onClose={() => {
                setDataPlanModal(false);
            }}
            destroyOnClose={true}
            width="650px"
            open={dataPlanModal}
            className="add-connector-drawer-wrapper"
        >
            <div className="add-connector-drawer">
                <div className="top">
                    <ConnectorDrawerHeader setDataPlanModal={setDataPlanModal} />

                    <ConnectorDrawerSteps handleStep={handleStep} currentStep={currentStep} />

                    <div className="add-connector-drawer-body">
                        {currentStep === 0 && <StepOne />}
                        {currentStep === 1 && <StepTwo />}
                        {currentStep === 2 && <StepThree />}
                        {currentStep === 3 && <StepFour />}
                    </div>
                </div>
                <div className="bottom">
                    <div className="add-connector-drawer-footer">
                        <Button customClassName="secondary" placeholder="Prev" onClick={() => handleStep('prev')} disabled={currentStep === 0} />
                        <Button
                            customClassName="modal-btn modal-btn-primary"
                            placeholder={currentStep === 3 ? 'Finish' : 'Next'}
                            onClick={() => (currentStep === 3 ? setDataPlanModal(false) : handleStep('next'))}
                        />
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default AddConnectorDrawer;
