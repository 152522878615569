import "./style.scss";

const comingSoon = ({ customClass }) => {

    return (
        <div className={"coming-soon " + customClass}>
            Coming soon
        </div>
    );
}

export default comingSoon;
