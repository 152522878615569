import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({ fontSize, color }) => {
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: fontSize || 18,
                color: color || 'var(--primary-color)'
            }}
            spin
        />
    );
    return (
        <div className="spinner-container">
            <Spin indicator={antIcon} />
        </div>
    );
};

export default Spinner;
