import './style.scss';
import { useEffect, useState } from 'react';
import Modal from 'components/modal';
import Input from 'components/input';
import Button from 'components/button';
import { IoMdCheckboxOutline } from 'react-icons/io';

const ConfirmationModal = ({ openModal, setOpenModal, type, title, desc, onSubmit, loading, placeholder, setTaskReasonForStatusChange }) => {
    const [disableSubmitButton, setDisableSubmitButton] = useState(true);

    useEffect(() => {
        openModal === true && setTaskReasonForStatusChange(null);
    }, [openModal]);

    return (
        <Modal isModalOpen={openModal} clickOutside={() => setOpenModal(false)}>
            <div className="todo-list-confirmation-modal">
                <div className="body">
                    <div className="icon">
                        <IoMdCheckboxOutline color="var(--primary-color)" size={20} />
                    </div>
                    <h2>{title}</h2>
                    <p>{desc}</p>
                    <Input
                        placeholder={placeholder}
                        onChange={(e) => {
                            setTaskReasonForStatusChange(e.target.value);
                            setDisableSubmitButton(type === 'fix' ? e?.target?.value !== 'confirm' : e.target.value.length < 5);
                        }}
                    />
                </div>
                <div className="footer">
                    <Button customClassName="modal-btn modal-btn-secondary" placeholder="Close" onClick={() => setOpenModal(false)} />
                    <Button customClassName="modal-btn modal-btn-primary" placeholder="Go" onClick={() => onSubmit()} loading={loading} disabled={disableSubmitButton} />
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmationModal;
