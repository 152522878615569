import { Editor } from '@monaco-editor/react';

const MsEditor = ({ value, setValue, lang }) => {
    return (
        <Editor
            options={{
                minimap: { enabled: false },
                scrollbar: { verticalScrollbarSize: 0, horizontalScrollbarSize: 0 },
                scrollBeyondLastLine: false,
                roundedSelection: false,
                formatOnPaste: true,
                formatOnType: true,
                readOnly: false,
                fontSize: '14px',
                fontFamily: 'Inter',
                selectOnLineNumbers: false,
                lineNumbers: 'off',
                glyphMargin: false,
                folding: false,
                lineDecorationsWidth: 0,
                lineNumbersMinChars: 0,
                renderLineHighlight: 'none'
            }}
            language={lang}
            height="100%"
            width="100%"
            value={value}
            onChange={(value) => {
                setValue(value);
            }}
        />
    );
};

export default MsEditor;
