import { useState, useEffect, useCallback } from 'react';
import { Resizable } from 'react-resizable';

const columnsAreEqual = (cols1, cols2) => {
    if (cols1.length !== cols2.length) return false;
    for (let i = 0; i < cols1.length; i++) {
        if (cols1[i].width !== cols2[i].width) return false;
    }
    return true;
};

const ResizableTitle = (props) => {
    const { onResize, width, ...restProps } = props;
    if (!width || typeof width !== 'number') {
        return <th {...restProps} />;
    }

    return (
        <Resizable
            width={width}
            height={0}
            handle={<span className="react-resizable-handle" onClick={(e) => e.stopPropagation()} />}
            onResize={onResize}
            draggableOpts={{ enableUserSelectHack: false }}
        >
            <th {...restProps} />
        </Resizable>
    );
};

const useResizableColumns = (initialColumns, checkColumns = false) => {
    const [columns, setColumns] = useState(initialColumns);

    useEffect(() => {
        if (!columnsAreEqual(columns, initialColumns)) {
            checkColumns && setColumns(initialColumns);
        }
    }, [initialColumns, checkColumns]);

    const handleResize = useCallback((index) => (e, { size }) => {
        setColumns(currentColumns => {
            const nextColumns = [...currentColumns];
            nextColumns[index] = {
                ...nextColumns[index],
                width: size.width
            };
            return nextColumns;
        });
    }, []);

    const components = {
        header: {
            cell: ResizableTitle
        },
    };

    const resizableColumns = columns.map((col, index) => ({
        ...col,
        onHeaderCell: (column) => ({
            width: column.width,
            onResize: handleResize(index)
        })
    }));

    return { components, resizableColumns };
};

export default useResizableColumns;
