export const parsingDate = (date, withSeconds = false, withTime = true) => {
    if (date) {
        var second = withSeconds ? 'numeric' : undefined;
        var time = withTime ? 'numeric' : undefined;

        var options = { year: 'numeric', month: 'short', day: 'numeric', hour: time, minute: time, second: second };
        return new Date(date).toLocaleDateString([], options);
    } else {
        return '';
    }
};

export const parsingDateWithotTime = (date) => {
    if (date) {
        var options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(date).toLocaleDateString([], options);
    } else return '';
};

export const uuidv4 = () => {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
        (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
    );
}

export const getTotalLengthOfArrays = (obj) => {
    if (typeof obj !== 'object' || obj === null) {
        throw new TypeError('Input should be a non-null object');
    }

    let totalLength = 0;

    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            totalLength += obj[key].length;
        }
    }

    return totalLength;
}