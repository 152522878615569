import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';

const Auth0ProviderWithHistory = ({ children }) => {
    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        const account_id = appState?.account_id;
        navigate(appState?.returnTo || window.location.pathname, {
            replace: true,
            state: { account_id }
        });
    };

    return (
        <Auth0Provider
            authorizationParams={{ redirect_uri: window.location.origin }}
            domain={domain}
            clientId={clientId}
            audience={audience}
            onRedirectCallback={onRedirectCallback}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithHistory;
