import { useState, useEffect } from 'react';

const useTableDynamicPageSize = (offset = 110, rowHeight = 50) => {
    const [pageSize, setPageSize] = useState(15);

    useEffect(() => {
        const handleResize = () => {
            const calculatedHeight = window.innerHeight - offset;
            const numberOfRows = Math.floor(calculatedHeight / rowHeight);
            setPageSize(numberOfRows);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [offset, rowHeight]);

    return pageSize;
};

export default useTableDynamicPageSize;