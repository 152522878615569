import { Handle, Position } from "reactflow";
import { ReactComponent as AppIcon } from "assets/images/graphView/app.svg";

const AppNode = ({data}) => {
    return (
        <div className="custom-node app-node">
            <div className="custom-node-icon" onClick={data?.onClick} style={{cursor: "pointer"}}>
                <AppIcon/>
            </div>
            <div className="app-node-title" onClick={data?.onClick} style={{cursor: "pointer"}}>{data?.label}</div>
            {!data.orphanClients &&
                <Handle
                    type="target"
                    position={Position.Left}
                    id={data.id}
                    style={{ top:"50%", background: "var(--primary-color)" }}
                    isConnectable={true}
                />
            }
        </div>
    );
};

export default AppNode;