import './style.scss';
import { Table as TableComponent } from 'antd';
import { ReactComponent as NoTableData } from 'assets/images/optimization/noTableData.svg';

const Table = ({ ...props }) => {
    const onChange = (pagination, filters, sorter, extra) => {
        return;
    };
    return (
        <div className="optimization-table">
            <TableComponent
                locale={{
                    emptyText: (
                        <div className="no-data">
                            <NoTableData />
                            <p>No data to display</p>
                        </div>
                    )
                }}
                {...props}
                onChange={onChange}
                size="small"
                onRow={(record, _) => {
                    if (props.onCustomRowClick)
                        return {
                            onClick: (event) => {
                                props.onCustomRowClick(record);
                            }
                        };
                }}
            />
        </div>
    );
};

export default Table;
