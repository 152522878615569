import './style.scss';
import Badge from '../badge';
import { Spin } from 'antd';
import Button from '../button';
import { TbHexagonMinus } from 'react-icons/tb';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { FaChevronRight, FaPlay } from 'react-icons/fa';
import { ReactComponent as StorageIcon } from 'assets/images/badgeIcons/storage.svg';
import { ReactComponent as CPUIcon } from 'assets/images/badgeIcons/cpu.svg';
import { ReactComponent as MemoryIcon } from 'assets/images/badgeIcons/memory.svg';
import { ReactComponent as PartitionsIcon } from 'assets/images/badgeIcons/partitions.svg';
import { ReactComponent as PerformanceIcon } from 'assets/images/badgeIcons/performance.svg';
import { ReactComponent as RWIcon } from 'assets/images/badgeIcons/rw.svg';
import Tooltip from '../tooltip/tooltip';
import { forwardRef } from 'react';
import { RiQuestionFill } from 'react-icons/ri';

const ListItem = forwardRef(({ handleOpenDrawer, handleSelect, item, className, triggerConfirmation, loadingItemId = null, status }, ref) => {
    const { title, connection_name, tags } = item;

    const getBadge = (category, index) => {
        const mapping = {
            Storage: {
                icon: <StorageIcon />,
                color: '#005AEE',
                backgroundColor: 'rgba(0, 90, 238, 0.12)'
            },
            Compute: {
                icon: <CPUIcon />,
                color: '#9D4EDD',
                backgroundColor: 'rgba(157, 78, 221, 0.12)'
            },
            memory: {
                icon: <MemoryIcon />,
                color: '#F97B53',
                backgroundColor: 'rgba(249, 123, 83, 0.12)'
            },
            Partitions: {
                icon: <PartitionsIcon />,
                color: '#FF55B0',
                backgroundColor: 'rgba(255, 85, 176, 0.12)'
            },
            Performance: {
                icon: <PerformanceIcon />,
                color: '#362DA4',
                backgroundColor: 'rgba(54, 45, 164, 0.12)'
            },
            Transfer: {
                icon: <RWIcon />,
                color: '#039855',
                backgroundColor: 'rgba(3, 152, 85, 0.12)'
            }
        };

        return (
            <Badge
                key={'badge-' + index}
                icon={mapping[category]?.icon}
                text={category}
                type={category + ' border-rounded'}
                color={'#005AEE'}
                backgroundColor={'rgba(0, 90, 238, 0.12)'}
            />
        );
    };

    return (
        <div className="list-item-root" ref={ref}>
            <Spin spinning={loadingItemId === item?.id} delay={100}>
                <div className={'list-item ' + className} onClick={() => handleOpenDrawer(item)}>
                    <div className="list-item-left">
                        <div className="list-item-left-top">
                            {/*<Checkbox
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) => handleSelect(item, e.target.checked)}
                            />*/}
                            <p className="list-item-title">{title}</p>
                            <div className="todo-list-status-wrapper"></div>
                        </div>
                        <div className="list-item-left-bottom">
                            <div className="list-item-badges">
                                <div className="left">{tags?.map((category, index) => getBadge(category, index))}</div>
                                <div className="right">
                                    {item?.task_type === 'remove_inactive_topic' && item?.auto_create_topics !== undefined && (
                                        <Badge
                                            text={
                                                <div style={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    {item.auto_create_topics ? 'auto.topic.creation is enabled' : 'auto.topic.creation is disabled'}
                                                </div>
                                            }
                                            type={item?.auto_create_topics ? 'success' : 'warning'}
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="list-item-right">
                        <div className="list-item-actions">
                            {!['in_progress', 'completed'].includes(status) && (
                                <>
                                    <Tooltip text={'Mark as ignored'} placement={'top'}>
                                        <Button
                                            shape="circle"
                                            placeholder={<TbHexagonMinus color={'var(--text-color)'} />}
                                            disabled={item?.status === 'completed' || item?.status === 'in_progress'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                triggerConfirmation('markAsIgnored', item);
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip text={'Mark as fixed'} placement={'top'}>
                                        <Button
                                            shape="circle"
                                            placeholder={<IoMdCheckboxOutline color={'var(--text-color)'} />}
                                            disabled={item?.status === 'completed' || item?.status === 'in_progress'}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                triggerConfirmation('markAsCompleted', item);
                                            }}
                                        />
                                    </Tooltip>
                                    <Tooltip
                                        text={
                                            item?.fixable === false
                                                ? item?.task_type === 'reduce_payload'
                                                    ? 'We couldn’t find any supported producers for that topic. Please use our library to enable it.'
                                                    : item?.fixable_reason
                                                : 'Fix'
                                        }
                                        placement={'top'}
                                    >
                                        <Button
                                            shape="circle"
                                            placeholder={<FaPlay color={'var(--ready-text-color)'} />}
                                            disabled={item?.status === 'completed' || item?.status === 'in_progress' || item?.fixable === false}
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                triggerConfirmation('fix', item);
                                            }}
                                        />
                                    </Tooltip>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </Spin>
        </div>
    );
});

export default ListItem;
