export const config = {
    token: {},
    cssVar: true,
    components: {
        Select: {
            colorPrimary: "#5A4FE5",
            controlHeight: 40,
            fontFamily: "Inter",
            algorithm: true,
        },
        Input: {
            colorPrimary: "#5A4FE5",
            controlHeight: 40,
            fontFamily: "Inter",
            algorithm: true,
        },
        Button: {
            colorPrimary: "#5A4FE5",
            fontFamily: "Inter",
            algorithm: true,
        },
        Checkbox: {
            colorPrimary: "#5A4FE5",
            fontFamily: "Inter",
            algorithm: true,
        }
    },
};
