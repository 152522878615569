import "./style.scss";
import { useContext } from "react";
import { Context } from "hooks/store";
import { ReactComponent as SuperStreamLogo } from "assets/images/superstreamLogo.svg";
import { ReactComponent as SuperStreamWhiteLogo } from "assets/images/superstreamWhiteLogo.svg";
import { ReactComponent as SuperStreamFavLogo } from "assets/images/superstreamFav2.svg";
import { useNavigate } from "react-router-dom";

const Logo = ({isFav = false, customClass, width}) => {
    const navigate = useNavigate();
    const [state] = useContext(Context);
    const style = {width: width};

    let logo;
    logo = state?.darkMode ? <SuperStreamWhiteLogo style={style} /> : <SuperStreamLogo style={style} />;

    if (isFav) logo = <SuperStreamFavLogo style={style} />;

    return <div className={"ms-logo " + customClass} onClick={() => navigate("/")}>{logo}</div>;
}

export default Logo;
