import { Handle, Position } from "reactflow";
import { ReactComponent as KafkaIcon } from "assets/images/kafkaIcon.svg";
import Tooltip from "components/tooltip/tooltip";

const KafkaNode = ({data}) => {

    return (
        <div className="custom-node kafka-node">
            <div className="custom-node-icon">
                <KafkaIcon/>
            </div>
            <div className="kafka-node-content">
                <div className="kafka-node-content-title">
                    <Tooltip text={data?.label} disabled={data?.label?.length < 18}>
                        <div className="eclipse-text" style={{maxWidth: 100}}>
                            {data.label}
                        </div>
                    </Tooltip>
                </div>
            </div>
            <Handle
                type="target"
                position={Position.Left}
                id={data.id}
                style={{ top: "50%", background: "var(--primary-color)" }}
                isConnectable={true}
            />
            {data.connections > 0 &&
                <Handle
                    type="source"
                    position={Position.Right}
                    id={data.id}
                    style={{ top: "50%", background: "var(--primary-color)" }}
                    isConnectable={true}
                />
            }
        </div>
    );
};

export default KafkaNode;