import "./style.scss";

const Badge = ({ type, children, eclipse = false }) => {

    const eclipse_class = eclipse ? "eclipse" : "";

    return (
        <div className={"client-badge " + type + " " + eclipse_class}>
            {children}
        </div>
    );
}
export default Badge;