import "./style.scss"
import React from "react";
import Button from "components/button"

const SocialLogin = ({ icon: Icon, iconStyle, title, onClick }) => {

    return (
        <Button
            customClassName="btn-social"
            onClick={onClick}
            placeholder={
                <div className="btn-social-outer">
                    <div className="btn-social-inner">
                        <div className="btn-social-icon">
                            <Icon style={iconStyle} />
                        </div>
                        <div className="btn-social-title">{title}</div>
                    </div>
                </div>
            }
        />
    );
};

export default SocialLogin;
