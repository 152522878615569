import { message } from 'antd';
import yaml from 'js-yaml';
import { ReactComponent as KafkaIconLight } from 'assets/images/kafkaImages/kafkaLight.svg';
import { ReactComponent as KafkaIconDark } from 'assets/images/kafkaImages/kafkaDark.svg';
import { ReactComponent as ConfluentIconLight } from 'assets/images/kafkaImages/confluentLight.svg';
import { ReactComponent as ConfluentIconDark } from 'assets/images/kafkaImages/confluentDark.svg';
import { ReactComponent as MSKIconLight } from 'assets/images/kafkaImages/mskLight.svg';
import { ReactComponent as MSKIconDark } from 'assets/images/kafkaImages/mskDark.svg';
import { ReactComponent as RedpandaIconLight } from 'assets/images/kafkaImages/redpandaLight.svg';
import { ReactComponent as RedpandaIconDark } from 'assets/images/kafkaImages/redpandaDark.svg';
import { ReactComponent as AivenIconLight } from 'assets/images/kafkaImages/aivenLight.svg';
import { ReactComponent as AivenIconDark } from 'assets/images/kafkaImages/aivenDark.svg';
import { parsingDate } from './valueConvertor';
import { BiLogoGoLang, BiLogoPython } from 'react-icons/bi';
import { SiDotnet } from 'react-icons/si';
import { FaJava } from 'react-icons/fa';
import React from 'react';

export const showMessages = (type, content) => {
    switch (type) {
        case 'success':
            message.success({
                key: 'memphisSuccessMessage',
                content: content,
                duration: 5,
                style: { cursor: 'pointer' },
                onClick: () => message.destroy('memphisSuccessMessage')
            });
            break;
        case 'error':
            message.error({
                key: 'memphisErrorMessage',
                content: content,
                duration: 5,
                style: { cursor: 'pointer' },
                onClick: () => message.destroy('memphisErrorMessage')
            });
            break;
        case 'warning':
            message.warning({
                key: 'memphisWarningMessage',
                content: content,
                duration: 5,
                style: { cursor: 'pointer' },
                onClick: () => message.destroy('memphisWarningMessage')
            });
            break;
        default:
            break;
    }
};

export const isDate = (dateString) => {
    if (typeof dateString !== 'string' || dateString.trim() === '') {
        return false;
    }
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
        return false;
    } else {
        const inputDateParts = dateString.split('T')[0].split('-');
        const parsedDateParts = [
            date.getUTCFullYear().toString().padStart(4, '0'),
            (date.getUTCMonth() + 1).toString().padStart(2, '0'),
            date.getUTCDate().toString().padStart(2, '0')
        ];

        return inputDateParts[0] === parsedDateParts[0] && inputDateParts[1] === parsedDateParts[1] && inputDateParts[2] === parsedDateParts[2];
    }
};

export const convertReportValue = (name, value, axis = false) => {
    if (name === 'compute') {
        return `${value}%`;
    } else if (name === 'storage') {
        return convertStorage(value, axis);
    } else if (name === 'partitions') {
        return value?.toLocaleString();
    } else if (name === 'topics') {
        return convertNumbers(value, axis);
    } else if (name === 'write') {
        return convertStorage(value, axis);
    } else if (name === 'read') {
        return convertStorage(value, axis);
    } else if (name === 'reliability') {
        return `${value}/100`;
    } else if (name === 'performance') {
        return `${value}%`;
    } else if (name === 'patitionsAxis') {
        return convertNumbers(value);
    }
};

export const convertNumbers = (value) => {
    if (value < 1000) {
        return value.toFixed(0).toLocaleString();
    } else if (value < 1000000) {
        return `${(value / 1000).toFixed(0).toLocaleString()} K`;
    } else if (value < 1000000000) {
        return `${(value / 1000000).toFixed(0).toLocaleString()} M`;
    } else {
        return `${(value / 1000000000).toFixed(0).toLocaleString()} B`;
    }
};

export const convertStorage = (valueInBytes, axis = false, returnObject = false) => {
    const valueInKB = valueInBytes / 1024;
    const valueInMB = valueInKB / 1024;
    const valueInGB = valueInMB / 1024;
    const valueInTB = valueInGB / 1024;

    const formatResult = (value, unit) => {
        const formattedValue = Number(value.toFixed(axis && value > 1 ? 0 : 2)).toLocaleString();
        const result = { value: formattedValue, unit };
        return returnObject ? result : `${formattedValue} ${unit}`;
    };

    if (valueInKB < 1024) {
        return formatResult(valueInKB, 'KB');
    } else if (valueInMB < 1024) {
        return formatResult(valueInMB, 'MB');
    } else if (valueInGB < 1024) {
        return formatResult(valueInGB, 'GB');
    } else {
        return formatResult(valueInTB, 'TB');
    }
};


export const downloadYamlFile = (configuration, fileName) => {
    const yamlData = yaml.dump(configuration);
    const blob = new Blob([yamlData], { type: 'text/yaml' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
};

export const getIcon = (streamingVendor, darkMode) => {
    switch (streamingVendor) {
        case 'apache_kafka':
            return darkMode ? <KafkaIconDark /> : <KafkaIconLight />;
        case 'confluent_cloud_kafka':
            return darkMode ? <ConfluentIconDark /> : <ConfluentIconLight />;
        case 'confluent_kafka':
            return darkMode ? <ConfluentIconDark /> : <ConfluentIconLight />;
        case 'msk':
            return darkMode ? <MSKIconDark /> : <MSKIconLight />;
        case 'msk_serverless':
            return darkMode ? <MSKIconDark /> : <MSKIconLight />;
        case 'redpanda':
            return darkMode ? <RedpandaIconDark /> : <RedpandaIconLight />;
        case 'aiven':
            return darkMode ? <AivenIconDark /> : <AivenIconLight />;
        default:
            return darkMode ? <KafkaIconDark /> : <KafkaIconLight />;
    }
};

export const calculateETA = (seconds = 0) => {
    if (seconds === 0) return 'soon';
    let res = '';
    const hours = Math.floor(seconds / 3600);
    if (hours > 0) res += hours + hours === 1 ? ' hour' : ` ${hours?.toLocaleString()} hours`;
    seconds -= hours * 3600;
    const minutes = Math.floor(seconds / 60);
    if (minutes > 0) res += minutes + minutes === 1 ? '1 minute' : ` ${minutes} minutes`;
    return res;
};

export const calculatePassedTime = (date) => {
    const currentDate = new Date();
    const lastReportDate = new Date(date);
    const diff = currentDate - lastReportDate;
    const diffInMinutes = Math.floor(diff / 60000);
    if (diffInMinutes < 60) {
        return `${diffInMinutes} minutes ago`;
    } else {
        const diffInHours = Math.floor(diffInMinutes / 60);
        if (diffInHours < 24) {
            return `${diffInHours} hours ago`;
        } else {
            return `${Math.floor(diffInHours / 24)} days ago`;
        }
    }
};

export const getIconByLang = (lang) => {
    const mapping = {
        go: <BiLogoGoLang style={{ height: 35, width: 35 }} />,
        python: <BiLogoPython style={{ height: 20, width: 20 }} />,
        'C#': <SiDotnet style={{ height: 27, width: 27 }} />,
        java: <FaJava style={{ height: 22, width: 22 }} />
    };

    const iconComponent = lang ? mapping[lang] : lang;

    return <div className="table-language">{iconComponent} </div>;
};
