import Heading from "components/heading";
import React, { useState } from "react";
import Input from "components/input";
import Button from "components/button";
import { Form } from "antd";
import FormServerMessage from "components/formServerMessage";
import { signInWithCustomToken, confirmPasswordReset } from "firebase/auth";
import {auth} from "services/firebase";
import firebaseMessages from "utils/firebaseMessages";
import { SESSION_STORAGE_FIREBASE_OOB_CODE, SESSION_STORAGE_TOKEN } from "utils/localStorageConsts";
import { useNavigate } from "react-router-dom";
import CustomButtonWrapper from "components/buttonsWrapper/CustomButtonWrapper";
import { FaArrowLeftLong } from "react-icons/fa6";
import Alert from "../components/alert";
import { ReactComponent as PasswordIcon } from "assets/images/password.svg";
import { validatePassword } from "./validatePassword";

const SetNewPasswordForm = ({ heading }) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [signInTokenLoading, setSignInTokenLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        const { password } = values;

        try {
            setLoading(true);
            await confirmPasswordReset(auth, sessionStorage.getItem(SESSION_STORAGE_FIREBASE_OOB_CODE), password).then(async () => {
                setResetSuccess(true);
            });
        } catch (error) {
            setServerMessage(firebaseMessages(error.code))
        } finally {
            setLoading(false);
        }
    };

    const signInWithToken = async () => {
        setSignInTokenLoading(true);
        await signInWithCustomToken(auth, sessionStorage.getItem(SESSION_STORAGE_TOKEN)).finally( () => setSignInTokenLoading(false));
        navigate("/");
    };

    const compareToFirstPassword = (_, value) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
        return Promise.resolve();
    };

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const allFieldsNotEmpty = allFields.every(field => field.value);
        setIsFormValid(allFieldsNotEmpty && errors.filter(({ errors }) => errors.length).length === 0);
    };

    return (
        <div className="ms-form-inner">
            {!resetSuccess ?
                <div className="forget-password-form">
                <Heading
                    fontSize={24}
                    fontWeight={600}
                >
                    {heading}
                </Heading>

                <div className="form">
                    <Form
                        name="forget-passowrd"
                        onFinish={onFinish}
                        autoComplete="off"
                        form={form}
                        onFieldsChange={onFieldsChange}
                    >
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    validator: validatePassword
                                },
                            ]}
                        >
                            <Input
                                type="password"
                                label="Password"
                                placeholder="**********"
                                customClass="rounded"
                            />
                        </Form.Item>

                        <Form.Item
                            name="confirm"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                {
                                    validator: compareToFirstPassword,
                                },
                            ]}
                        >
                            <Input
                                type="password"
                                label="Confirm Password"
                                placeholder="**********"
                                customClass="rounded"
                            />
                        </Form.Item>

                        <FormServerMessage text={serverMessage} />

                        <Form.Item style={{ marginBottom: 7 }}>
                            <Button
                                customClassName="btn-primary"
                                placeholder="Continue"
                                loading={loading}
                                htmlType="submit"
                                disabled={!isFormValid}
                            />
                        </Form.Item>
                        <Form.Item style={{marginBottom: 0}}>
                            <Button
                                onClick={() => navigate("/signin")}
                                customClassName="ms-button"
                                placeholder={
                                    <CustomButtonWrapper
                                        icon={<FaArrowLeftLong color="var(--text-color)" />}
                                        text="Go Back"
                                        withArrow={false}
                                    />
                                }
                            />
                        </Form.Item>
                    </Form>
                </div>
            </div> :
                <Alert
                    type="success"
                    title="Success"
                    icon={<PasswordIcon/>}
                    subtitle="Your password has been successfully changed!"
                    buttonLabel="Go to dashboard"
                    onClick={ () => signInWithToken()}
                    loading={signInTokenLoading}
                />
            }
        </div>
    );
}

export default SetNewPasswordForm;
