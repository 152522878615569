import { MdOutlineContentCopy } from 'react-icons/md';
import { FaCheck } from 'react-icons/fa6';

import Button from 'components/button';
import { useState } from 'react';

const CopyCode = ({ code, style , hideCode = false}) => {
    const [copied, setCopied] = useState(false);

    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(code)
            .then(() => {
                setCopied(true);
                setTimeout(() => {
                    setCopied(false);
                }, 1000);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    };

    return (
        <div className="code-copy" style={style}>
            <div className="code-copy-content">{ !hideCode && code }</div>
            <div className="code-copy-icon">
                <Button
                    onClick={copyToClipboard}
                    typeOfButton="text"
                    style={{ display: 'contents' }}
                    placeholder={!copied ? <MdOutlineContentCopy color="var(--text-color-secondary)" /> : <FaCheck color="#10B85D" />}
                />
            </div>
        </div>
    );
};

export default CopyCode;
