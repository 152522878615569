import SuperStreamNode from "./superstream";
import DataPlaneNode from "./dataplan";
import KafkaNode from "./kafka";
import AppNode from "./app";
import AddDataPlaneNode from "./addDataplan";
import AddConnectorNode from "./addConnector";

const nodeTypes = {
    superstream: SuperStreamNode,
    dataPlane: DataPlaneNode,
    kafka: KafkaNode,
    app: AppNode,
    addDataPlan: AddDataPlaneNode,
    addConnector: AddConnectorNode,

};

export default nodeTypes;