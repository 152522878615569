import React from 'react';
import './style.scss';

const Heading = ({ fontSize = 32, color = 'var(--text-color)', lineHeight = 'normal', fontWeight, customClass, children, desc }) => {
    return (
        <div
            className={'ms-heading ' + customClass}
            style={{
                fontSize: fontSize,
                color: color,
                fontWeight: fontWeight,
                lineHeight: lineHeight
            }}
        >
            {children}
            {desc && <span>{desc}</span>}
        </div>
    );
};

export default Heading;
