import './style.scss';

import { IoCloseOutline } from 'react-icons/io5';
import { notification } from 'antd';

import { ReactComponent as SuccessIcon } from 'assets/images/successIcon.svg';
import { ReactComponent as ErrorIcon } from 'assets/images/errorIcon.svg';
import { ReactComponent as WarnIcon } from 'assets/images/warnIcon.svg';
import { ReactComponent as InfoNotificationIcon } from 'assets/images/infoNotificationIcon.svg';

const Notification = (type, message, duration = 15) => {
    const defaultAntdField = {
        className: 'notification-wrapper',
        closeIcon: <IoCloseOutline color="#667085" />,
        message: 'System Message'
    };
    switch (type) {
        case 'info':
            notification.info({
                ...defaultAntdField,
                icon: <InfoNotificationIcon alt="info" />,
                description: message,
                duration: duration
            });
            break;
        case 'warning':
            notification.warning({
                ...defaultAntdField,

                icon: <WarnIcon alt="warn" />,
                description: message,
                duration: duration
            });
            break;
        case 'error':
            notification.error({
                ...defaultAntdField,
                icon: <ErrorIcon alt="error" />,
                description: message,
                duration: duration
            });
            break;
        case 'success':
            notification.success({
                ...defaultAntdField,
                icon: <SuccessIcon alt="success" />,
                description: message,
                duration: duration
            });
            break;
        default:
            break;
    }
};

export default Notification;
