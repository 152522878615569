import CopyCode from '../copyCode';

const StepFour = ({}) => {
    return (
        <>
            <div className="add-connector-drawer-subheader"></div>
            <div className="add-connector-drawer-editor">
                <div className="content-wrapper">
                    <div className="content">
                        <div className="content-title">
                            1. Go to the <em>custom_values.yaml</em> directory and run:
                        </div>
                        <div className="content-desc-wrapper">
                            <div className="content-desc">Without preconfigured Kafka connections:</div>
                            <CopyCode code="helm repo add superstream https://k8s.superstream.ai/ --force-update && helm install superstream superstream/superstream -f custom_values.yaml --create-namespace --namespace superstream --wait" />
                            <div className="content-desc">With preconfigured Kafka connections:</div>
                            <CopyCode code="helm repo add superstream https://k8s.superstream.ai/ --force-update && helm install superstream superstream/superstream -f custom_values.yaml -f config.yaml --create-namespace --namespace superstream --wait" />
                        </div>
                        <div className="content-desc"></div>
                    </div>
                    <div className="content">
                        <div className="content-title">2. Deployment verification:</div>
                        <div className="content-desc-wrapper">
                            <CopyCode code="helm list" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StepFour;
