import './style.scss';
import { ReactComponent as EmptyTasksIcon } from 'assets/images/emptyTasks.svg';

const EmptyTasksPlaceholder = (status) => {
    return (
        <div className="empty-tasks-placeholder">
            <div className="img">
                <EmptyTasksIcon />
            </div>
            <h3 className={status.status === 'available' && 'loading'}>
                {status.status === 'completed'
                    ? 'There are no completed tasks at the moment'
                    : status.status === 'in_progress'
                    ? 'There are no active tasks at the moment'
                    : 'Seeking new optimizations...'}
            </h3>
        </div>
    );
};

export default EmptyTasksPlaceholder;
