import { IoIosArrowDown, IoIosArrowForward } from 'react-icons/io';
import Logo from 'components/logo';
import { Divider } from 'antd';
import Popover from 'components/popover';
import UserItem from '../userItem';
import React, { useContext, useEffect, useState } from 'react';
import { Badge } from 'antd';
import ComingSoon from 'components/comingSoon';
import { Context } from 'hooks/store';
import { AuthService, auth } from 'services/firebase';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as SystemViewIcon } from 'assets/images/sidebar/systemView.svg';
import { ReactComponent as KafkaIcon } from 'assets/images/sidebar/kafka.svg';
import { FaKey, FaUser } from 'react-icons/fa';
import { MdToken } from 'react-icons/md';
import { ReactComponent as AuditIcon } from 'assets/images/sidebar/auditing.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/logout.svg';
import { ReactComponent as SupportIcon } from 'assets/images/sidebar/supportIcon.svg';
import { ReactComponent as DashboardIcon } from 'assets/images/sidebar/dashboard.svg';
import { ReactComponent as SettingsIcon } from 'assets/images/sidebar/settings.svg';
import { ReactComponent as OptimizationsIcon } from 'assets/images/sidebar/optimizations.svg';
import {
    LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED,
    SESSION_STORAGE_ACCOUNT_DATA,
    SESSION_STORAGE_ACCOUNT_ID,
    SESSION_STORAGE_ACTIVATION_TOKEN,
    SESSION_STORAGE_ERROR_MESSAGE,
    SESSION_STORAGE_JWT,
    SESSION_STORAGE_LOGIN_PROVIDER,
    SESSION_STORAGE_USER_UNDER_ASSESSMENT
} from 'utils/localStorageConsts';
import DarkModeSwitch from 'components/darkModeSwitch';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import CopyCode from 'pages/systemView/components/addConnectorDrawer/copyCode';
import { BsFillChatSquareTextFill } from 'react-icons/bs';
import { FaBook } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';
import { signOut } from 'firebase/auth';

const Sidebar = ({}) => {
    const [state, dispatch] = useContext(Context);
    const [popoverShown, setPopoverShown] = useState(false);
    const [supportPopoverShown, setSupportPopoverShown] = useState(false);
    const navigate = useNavigate();
    const [expandedItems, setExpandedItems] = useState({});
    const location = useLocation();
    const currentPath = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;
    const isSidebarCollapse = localStorage.getItem(LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED) === 'true';
    const data = sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA);
    const accounts = JSON.parse(data)?.accounts;
    const httpRequest = useHttpRequest();
    const currentUser = state?.currentUser;

    const { isAuthenticated, logout, getIdTokenClaims } = useAuth0();

    const AccountData = state?.isSidebarCollapsed ? (
        <div className="account-data-container-collapsed">
            <div className="account-data-item-data" onClick={() => handleSidebarState(!isSidebarCollapse)}>
                <FaUser />
            </div>
        </div>
    ) : (
        <div className="account-data-container">
            <div className="account-data-item">
                <MdToken fontSize={15} />
                <span className="account-data-item-data">
                    <label>Account ID</label>
                    <span>
                        <CopyCode code={sessionStorage?.getItem(SESSION_STORAGE_ACCOUNT_ID)} />
                    </span>
                </span>
            </div>
            {sessionStorage?.getItem(SESSION_STORAGE_ACTIVATION_TOKEN) && (
                <div className="account-data-item">
                    <FaUser fontSize={12} />
                    <span className="account-data-item-data">
                        <label>Token</label>
                        <CopyCode code={sessionStorage?.getItem(SESSION_STORAGE_ACTIVATION_TOKEN)} />
                    </span>
                </div>
            )}
        </div>
    );

    useEffect(() => {
        if (isSidebarCollapse) dispatch({ type: 'SET_SIDEBAR_STATE', payload: isSidebarCollapse });
        document.documentElement.style.setProperty('--main-container-sidebar-width', isSidebarCollapse ? '90px' : '280px');
    }, [state?.isSidebarCollapsed]);

    const handleSidebarState = (state) => {
        document.documentElement.style.setProperty('--main-container-sidebar-width', state ? '90px' : '280px');
        localStorage.setItem(LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED, state.toString());
        dispatch({ type: 'SET_SIDEBAR_STATE', payload: state });
    };

    const handleLogout = async (error = '') => {
        dispatch({ type: 'SET_LOADING', payload: true });
        if (sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) === 'SSO' && isAuthenticated) {
            try {
                AuthService.clearLocalStorage(error === '');
                error !== '' && sessionStorage.setItem(SESSION_STORAGE_ERROR_MESSAGE, error);
                await logout();
                setTimeout(() => {
                    dispatch({ type: 'SET_LOADING', payload: false });
                }, 1000);
            } catch (error) {
                console.error('Logout failed', error);
            }
        } else
            try {
                await signOut(auth);
                setTimeout(() => {
                    AuthService.logout(error, false);
                    dispatch({ type: 'SET_LOADING', payload: false });
                }, 1000);
            } catch (error) {
                console.error('Sign out failed', error);
            }
    };

    const handleSignIn = async (accountId) => {
        let sso_token = null;
        if (sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) === 'SSO') {
            sso_token = await getIdTokenClaims();
            sso_token = token.__raw;
        }
        const token = await currentUser.getIdToken(true);
        await httpRequest('POST', apiEndpoints.SIGN_IN, { firebase_id_token: token, sso_id_token: sso_token, account_id: accountId })
            .then(async (res) => {
                if (res?.data && res.data?.jwt) {
                    sessionStorage.setItem(SESSION_STORAGE_JWT, res.data?.jwt);
                    sessionStorage.setItem(SESSION_STORAGE_ACTIVATION_TOKEN, res.data?.activation_token);
                    sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_ID, res.data?.account_id);
                    sessionStorage.setItem(SESSION_STORAGE_USER_UNDER_ASSESSMENT, res.data?.under_assessment?.toString());
                    dispatch({ type: 'SET_AUTHENTICATION', payload: true });
                    window.location.reload();
                }
            })
            .catch((error) => {
                handleLogout('Server error, please contact with support!');
            });
    };

    const menuItems = [
        {
            icon: <DashboardIcon />,
            label: 'Dashboard',
            route: ''
        },
        {
            icon: <SystemViewIcon />,
            label: 'System View',
            route: '/system-view'
        },
        {
            icon: <KafkaIcon />,
            label: 'Connected clusters',
            route: '/connected-clusters'
        },
        {
            icon: state?.newJobIndicator ? (
                <Badge dot={true}>
                    <AuditIcon />
                </Badge>
            ) : (
                <AuditIcon />
            ),
            label: 'Tasks',
            route: '/tasks'
        },
        {
            icon: <SettingsIcon />,
            label: 'Settings',
            route: '/settings',
            children: [
                {
                    label: 'Keys',
                    route: '/keys'
                },
                {
                    label: 'Auditing',
                    route: '/auditing'
                },
                {
                    label: 'Configuration',
                    route: '/configuration',
                    isComingSoon: true,
                    disabled: true
                },
                {
                    label: 'Users',
                    route: '/users',
                    isComingSoon: true,
                    disabled: true
                }
            ]
        }

        /*{
            icon: <SchemasIcon/>,
            label: "Schemas",
            route: "/schemas",
            isComingSoon: true,
            disabled: true,
        },
        {
            icon: <FunctionsIcon/>,
            label: "Functions",
            route: "/functions",
            isComingSoon: true,
            disabled: true,
        },
        {
            icon: <UsersIcon/>,
            label: "Users",
            route: "/users",
            isComingSoon: true,
            disabled: true,
        },*/
    ];

    const toggleMenuItem = (index, hasChildren, disabled, route) => {
        if (disabled) return;

        if (hasChildren) {
            if (isSidebarCollapse) return handleSidebarState(!isSidebarCollapse);

            setExpandedItems((prev) => ({
                ...prev,
                [index]: !prev[index]
            }));
        } else {
            navigate(route);
        }
    };

    function renderSidebarMenuItem() {
        return menuItems.map(({ icon, label, route, isComingSoon, children, disabled, className }, index) => {
            const activeClass = (currentPath === route || location.pathname === route) && 'sidebar-menu-item-active';
            const disabledClass = disabled && 'sidebar-menu-item-disabled';
            const isExpanded = expandedItems[index];

            return (
                <div
                    className={'sidebar-menu-item ' + activeClass + ' ' + disabledClass + ' ' + (isExpanded && 'sidebar-menu-item-expanded')}
                    key={'sideBarItem_' + index}
                    onClick={() => toggleMenuItem(index, !!children, disabled, route)}
                >
                    <div className="sidebar-menu-item-inner">
                        <div className="sidebar-menu-item-inner-left">
                            <div className={'sidebar-menu-item-icon ' + className}>{icon}</div>
                            <div className="sidebar-menu-item-label">{label}</div>
                        </div>
                        <div className="sidebar-menu-item-inner-right">{isComingSoon && <ComingSoon />}</div>
                        {children && !isSidebarCollapse && (
                            <div className="sidebar-menu-item-arrow">
                                <IoIosArrowDown color="#979797" />
                            </div>
                        )}
                    </div>
                    {children && isExpanded && !isSidebarCollapse && (
                        <ul>
                            {children.map((item, childIndex) => {
                                const disabledClassChild = item?.disabled && 'disabled';

                                return (
                                    <li
                                        key={`childItem_${childIndex}`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            navigate(item?.route);
                                        }}
                                        className={disabledClassChild}
                                    >
                                        <a href="" onClick={(e) => e.preventDefault()}>
                                            {item.label}
                                            {item?.isComingSoon && <ComingSoon />}
                                        </a>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                </div>
            );
        });
    }

    const overlayStylesUser = {
        borderRadius: '8px',
        width: '241px',
        padding: '8px',
        marginTop: state?.isSidebarCollapsed ? '5rem' : '8px'
    };

    const contentPopover = (
        <>
            <div style={{ maxHeight: 200, overflowY: 'auto' }}>
                {accounts &&
                    accounts.map((user, userIndex) => {
                        return (
                            <div
                                className={
                                    'user-select popover-item ' + (user.id === parseInt(sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_ID)) && 'popover-item-active')
                                }
                                key={userIndex}
                                onClick={() => handleSignIn(user.id)}
                            >
                                <UserItem username={user.name} email={user.id} />
                            </div>
                        );
                    })}
            </div>
            {/*<div className="user-select-popover-item popover-item">
                <FiPlus color="#5A4FE5"/>
                Add a New Account
            </div>*/}
            <Divider />
            <div className="user-select-popover-item popover-item" onClick={() => handleLogout()}>
                <LogoutIcon />
                Logout
            </div>
        </>
    );

    return (
        <div className={'main-layout-sidebar ' + (state?.isSidebarCollapsed ? 'collapse' : 'expand')}>
            <div className="top">
                <div className={'main-layout-sidebar-toggle ' + (state?.isSidebarCollapsed ? 'close' : 'open')} onClick={() => handleSidebarState(!isSidebarCollapse)}>
                    <IoIosArrowForward color="var(--sidebar-toggle-arrow-color)" />
                </div>

                <Logo isFav={state?.isSidebarCollapsed} width={state?.isSidebarCollapsed ? 50 : 170} />

                <Divider />

                <Popover
                    overlayInnerStyle={overlayStylesUser}
                    placement={state?.isSidebarCollapsed ? 'right' : 'bottom'}
                    content={contentPopover}
                    trigger="click"
                    onOpenChange={() => setPopoverShown(!popoverShown)}
                    open={popoverShown}
                >
                    <div className={'user-select-wrapper ' + (popoverShown && 'user-select-wrapper-active')}>
                        <UserItem
                            icon={state?.currentUser?.photoURL || state?.currentUser?.picture}
                            username={state?.currentUser?.displayName || state?.currentUser?.name}
                            email={state?.currentUser?.email || state?.currentUser?.preferred_username}
                        />
                        <div className="user-select-arrow">
                            <IoIosArrowDown color="#979797" />
                        </div>
                    </div>
                </Popover>
                <div className="sidebar-menu">{renderSidebarMenuItem()}</div>
            </div>
            <div className="bottom">
                <Popover
                    overlayInnerStyle={overlayStylesUser}
                    placement="right"
                    content={
                        <>
                            <div
                                className="sidebar-support-popover-item popover-item"
                                onClick={() => {
                                    setSupportPopoverShown(false);
                                    window.open('https://docs.superstream.ai', '_blank');
                                }}
                            >
                                <FaBook className="icons-sidebar" /> Documentation
                            </div>
                            <div
                                className="sidebar-support-popover-item popover-item"
                                onClick={() => {
                                    setSupportPopoverShown(false);
                                    window.open('https://share.hsforms.com/1pnaVjMUcRT61Q5lQmxMLRQcqtej', '_blank');
                                }}
                            >
                                <BsFillChatSquareTextFill className="icons-sidebar" />
                                Open a feature request
                            </div>
                        </>
                    }
                    trigger="click"
                    onOpenChange={() => setSupportPopoverShown(!supportPopoverShown)}
                    open={supportPopoverShown}
                >
                    <div className="sidebar-menu-item" style={{ marginBottom: 8 }}>
                        <div className="sidebar-menu-item-inner">
                            <div className="sidebar-menu-item-inner-left">
                                <div className="sidebar-menu-item-icon">
                                    <SupportIcon style={{ width: '1em', height: '1em' }} />
                                </div>
                                <div className="sidebar-menu-item-label">Support</div>
                            </div>
                            <div className="sidebar-menu-item-inner-right"></div>
                        </div>
                    </div>
                </Popover>
                {AccountData}
                {/*<DarkModeSwitch />*/}
            </div>
        </div>
    );
};

export default Sidebar;
