import "./style.scss";
import AuthLayout from "layouts/authLayout";
import ForgetPasswordForm from "./Forms/ForgetPasswordForm";

const ForgetPassword = () => {

    return (
        <AuthLayout>
            <ForgetPasswordForm/>
        </AuthLayout>
    )
}

export default ForgetPassword;
