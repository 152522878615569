import "./style.scss";
import AuthLayout from "layouts/authLayout";
import SetNewPasswordForm from "./Forms/SetNewPasswordForm";

const ForgetPassword = () => {

    return (
        <AuthLayout>
            <SetNewPasswordForm heading="Set New Password" />
        </AuthLayout>
    )
}

export default ForgetPassword;
