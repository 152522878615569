import React from 'react';
import "./style.scss"

const Divider = () => {

    return (
        <div className="ms-divider">
            <div className="ms-divider-line"></div>
            <div className="ms-divider-text">or</div>
            <div className="ms-divider-line"></div>
        </div>
    );
};

export default Divider;
