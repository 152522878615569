import { Navigate } from 'react-router-dom';
import { SESSION_STORAGE_FIREBASE_OOB_CODE, SESSION_STORAGE_TOKEN } from "utils/localStorageConsts";

const AuthHandler = ({}) => {

    const currentUrl = new URL(window.location.href);
    const continueUrlPath = currentUrl.searchParams.get('continueUrl');
    if (continueUrlPath) {
        const oobCode = currentUrl.searchParams.get('oobCode');
        sessionStorage.setItem(SESSION_STORAGE_FIREBASE_OOB_CODE, oobCode);
        const continueUrl = new URL(continueUrlPath);
        const page = continueUrl.searchParams.get('page');
        const token = continueUrl.searchParams.get('access_token');
        sessionStorage.setItem(SESSION_STORAGE_TOKEN, token);

        return <Navigate to={"/" + page} replace/>;
    }

    return <Navigate to="/signin" replace/>;

}

export default AuthHandler;