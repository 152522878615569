import { LuInfo } from 'react-icons/lu';
import { Checkbox, Collapse } from 'antd';
import { IoIosArrowDown } from 'react-icons/io';
import CopyCode from '../copyCode';
import { SESSION_STORAGE_ACCOUNT_ID, SESSION_STORAGE_ACTIVATION_TOKEN } from '../../../../../utils/localStorageConsts';

const StepOne = ({}) => {
    const { Panel } = Collapse;

    const items = [
        {
            key: '1',
            label: (
                <>
                    <Checkbox onClick={(e) => e.stopPropagation()} /> Kubernetes Cluster
                </>
            ),
            items: (
                <p>
                    You need an up-and-running Kubernetes cluster. If you don't have one, you can create a cluster on platforms like Google Kubernetes Engine (GKE),
                    Amazon EKS, Azure AKS, or Minikube for local development.
                </p>
            )
        },
        {
            key: '2',
            label: (
                <>
                    <Checkbox onClick={(e) => e.stopPropagation()} /> Kubectl installed
                </>
            ),
            items: <p>The Kubernetes command-line tool, kubectl, allows you to run commands against Kubernetes clusters. Install kubectl if you haven't already.</p>
        },
        {
            key: '3',
            label: (
                <>
                    <Checkbox onClick={(e) => e.stopPropagation()} /> Helm installed
                </>
            ),
            items: (
                <p>Helm is a package manager for Kubernetes that facilitates the deployment and management of applications. Install Helm if it's not already set up.</p>
            )
        },
        {
            key: '4',
            label: (
                <>
                    <Checkbox onClick={(e) => e.stopPropagation()} />
                    Credentials
                </>
            ),
            items: (
                <p>
                    <b>Account ID</b>
                    <span style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <CopyCode style={{ marginLeft: 0, width: '100%' }} code={sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_ID)} />
                    </span>
                    <b>Activation Token</b>
                    <span style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <CopyCode style={{ marginLeft: 0, width: '100%' }} code={sessionStorage.getItem(SESSION_STORAGE_ACTIVATION_TOKEN)} />
                    </span>
                    are required for the deployment. Make sure you have these credentials.
                </p>
            )
        }
    ];

    return (
        <>
            <div className="add-connector-drawer-body-info">
                <LuInfo color="#979797" />
                Before you begin, ensure you have the following prerequisites installed and configured:
            </div>

            <Collapse
                defaultActiveKey={['1']}
                expandIconPosition="end"
                expandIcon={({ isActive }) =>
                    isActive ? (
                        <IoIosArrowDown color="var(--text-color)" style={{ rotate: '180deg' }} />
                    ) : (
                        <IoIosArrowDown color="var(--text-color)" style={{ rotate: '0deg' }} />
                    )
                }
                ghost
                collapsible={'icon'}
            >
                <>
                    {items.map((item) => (
                        <Panel key={item.key} header={item.label} collapsible={item.items ? 'header' : 'disabled'} showArrow={!!item.items}>
                            {item.items}
                        </Panel>
                    ))}
                </>
            </Collapse>
        </>
    );
};

export default StepOne;
