import './style.scss';
import Drawer from 'components/drawer';
import { getIconByLang } from 'services/genericServices';
import { Divider } from 'antd';

const OrphanClientsDrawer = ({ open, onClose, orphanClients }) => {
    return (
        <Drawer open={open} onClose={onClose} styles={{ header: { display: 'none' } }}>
            <div className="orphan-clients-drawer">
                <div className="top">
                    <h2>“Orphan” clients</h2>
                    <p>Clients that are connected to a Kafka cluster that is not visible or connected to Superstream.</p>
                </div>
                <div className="middle">
                    <div className="clients-list">
                        {orphanClients?.map((client, index) => (
                            <div key={index} className="orphans-client">
                                <div className="left">
                                    <p className="title">Client #{client?.id}</p>
                                    <div className="meta-wrapper">
                                        <div className="meta">
                                            <div className="meta-key">Language:</div>
                                            <div className="meta-value">{getIconByLang(client?.language)}</div>
                                        </div>
                                        {client?.tags?.length > 0 && (
                                            <>
                                                <Divider type="vertical" />
                                                <div className="meta">
                                                    <div className="meta-key">Tags:</div>
                                                    <div className="meta-value">{client?.tags?.join(', ')}</div>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </Drawer>
    );
};

export default OrphanClientsDrawer;
