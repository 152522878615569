import axios from 'axios';
import { SESSION_STORAGE_ERROR_MESSAGE, SESSION_STORAGE_JWT, SESSION_STORAGE_LOGIN_PROVIDER } from 'utils/localStorageConsts';
import { apiEndpoints } from './apiEndpoints';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import { AuthService, auth } from 'services/firebase';
import EmailLink from 'components/emailLink';
import Notification from 'components/notifications';
import { useAuth0 } from '@auth0/auth0-react';
import { signOut } from 'firebase/auth';

async function httpRequestFunc(method, endPointUrl, data = {}, headers = {}, queryParams = {}) {
    const token = sessionStorage.getItem(SESSION_STORAGE_JWT);
    headers['Authorization'] = 'Bearer ' + token;

    const HTTP = axios.create({
        withCredentials: true
    });
    if (method !== 'GET' && method !== 'POST' && method !== 'PUT' && method !== 'DELETE')
        throw {
            status: 400,
            message: `Invalid HTTP method`,
            data: { method, endPointUrl, data }
        };
    const url = `${process.env.REACT_APP_BASE_API}${endPointUrl}`;
    const res = await HTTP({
        method,
        url,
        data,
        headers,
        params: queryParams,
        responseType: endPointUrl.includes(apiEndpoints.DOWNLOAD_REPORT) ? 'blob' : 'json'
    });
    return endPointUrl.includes(apiEndpoints.SIGN_IN) ? res : res?.data;
}

export function useHttpRequest() {
    const navigate = useNavigate();

    const { logout, isAuthenticated } = useAuth0();

    const handleLogout = async (error = '') => {
        if (sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) === 'SSO' && isAuthenticated) {
            try {
                AuthService.clearLocalStorage(error === '');
                error !== '' && sessionStorage.setItem(SESSION_STORAGE_ERROR_MESSAGE, error);
                await logout();
            } catch (error) {
                console.error('Logout failed', error);
            }
        } else
            try {
                await signOut(auth);
                setTimeout(() => {
                    AuthService.logout(error);
                }, 1000);
            } catch (error) {
                console.error('Sign out failed', error);
            }
    };

    return useCallback(
        async (method, endPointUrl, data = {}, headers = {}, queryParams = {}) => {
            try {
                return await httpRequestFunc(method, endPointUrl, data, headers, queryParams);
            } catch (error) {
                if (axios.isAxiosError(error) && error.response) {
                    if (error.response.status === parseInt(process.env.REACT_APP_AUTHENTICATION_ERROR_STATUS_CODE)) {
                        !endPointUrl.includes(apiEndpoints.SIGN_IN) && handleLogout('Your session has expired.  Please sign in again.');
                    }
                    if (error?.response) {
                        if (error.response.data instanceof Blob) {
                            const reader = new FileReader();
                            reader.onload = async () => {
                                try {
                                    const errorData = JSON.parse(reader.result);

                                    if (errorData.message) {
                                        if (error.response.status === parseInt(process.env.REACT_APP_SHOWABLE_ERROR_STATUS_CODE)) {
                                            Notification('warning', errorData.message, 15);
                                        } else if (error.response.status === 500) {
                                            Notification(
                                                'error',
                                                <>
                                                    We are experiencing some issues. Please contact us at <EmailLink email="support@superstream.ai" /> for assistance.
                                                </>,
                                                15
                                            );
                                        } else {
                                            Notification(
                                                'error',
                                                <>
                                                    We are experiencing some issues. Please contact us at <EmailLink email="support@superstream.ai" /> for assistance.
                                                </>,
                                                15
                                            );
                                        }
                                    }
                                } catch (e) {
                                    console.error('Failed to parse the error message from the blob:', e);
                                }
                            };
                            reader.onerror = () => {
                                console.error('Failed to read the error blob');
                            };
                            reader.readAsText(error.response.data);
                        } else if (error.response.data.message !== undefined) {
                            if (error.response.status === parseInt(process.env.REACT_APP_SHOWABLE_ERROR_STATUS_CODE)) {
                                Notification('warning', error.response.data.message, 15);
                            }
                            if (error.response.status === 500) {
                                Notification(
                                    'error',
                                    <>
                                        We are experiencing some issues. Please contact us at <EmailLink email="support@superstream.ai" /> for assistance.
                                    </>,
                                    15
                                );
                            }
                        }
                    } else {
                        Notification(
                            'error',
                            <>
                                We are experiencing some issues. Please contact us at <EmailLink email="support@superstream.ai" /> for assistance.
                            </>,
                            15
                        );
                    }
                }
                throw error.response;
            }
        },
        [navigate]
    );
}
