import "./style.scss";
import { BsExclamationCircle } from "react-icons/bs";

const FormServerMessage = ({type, text}) => {

    if (!text) return

    return (
        <div className="form-result-output">
            <div className="form-result-output-icon">
                <BsExclamationCircle color="#FF3B30" fontSize="24px" />
            </div>
            <div className="form-result-output-text">
                {text}
            </div>
        </div>
    )
}

export default FormServerMessage;