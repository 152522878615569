import './style.scss';
import { useContext, useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Badge, Select, Tabs } from 'antd';
import { Context } from 'hooks/store';
import { IoIosArrowDown } from 'react-icons/io';
import { FaArrowLeft } from 'react-icons/fa';
import pathDomains from 'router';
import ClusterSummary from './components/clusterSummary';
import Clients from './components/clients';
import TodoList from 'components/todoList';
import RefreshButton from 'components/refreshButton';
import Auditing from 'pages/auditing';
import Configuration from './components/configuration';
import { getIcon } from 'services/genericServices';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';

const tabs = ['summary', 'to-do', 'audit', 'clients', 'configuration'];

const ClusterOverview = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const refreshRef = useRef();
    const httpRequest = useHttpRequest();
    const [state, dispatch] = useContext(Context);
    const [selectedConnectionID, setSelectedConnectionID] = useState(null);
    const [selectedConnectionName, setSelectedConnectionName] = useState(null);
    const [selectedConnectionType, setSelectedConnectionType] = useState(null);
    const [activeKey, setActiveKey] = useState('1');
    const [isLoading, setIsLoading] = useState(false);
    const [summaryData, setSummaryData] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const pathTokens = decodeURI(location.pathname)?.split('/');
        const connectionId = pathTokens[pathTokens?.length - 1];
        setSelectedConnectionID(connectionId);
        const connection = state?.connections?.find((connection) => connection?.id === Number(connectionId));
        setSelectedConnectionName(connection?.name);
        setSelectedConnectionType(connection?.type);
    }, [state?.connections, location.pathname]);

    useEffect(() => {
        const getTabState = searchParams.get('tab');
        if (getTabState) {
            setActiveKey((tabs.indexOf(getTabState) + 1).toString());
        } else {
            setSearchParams({ tab: 'summary' });
        }
    }, [searchParams, setSearchParams]);

    useEffect(() => {
        selectedConnectionID && getConnectionSummary();
    }, [selectedConnectionID]);

    const onChange = (key) => {
        const currentTab = tabs[key - 1];
        setSearchParams({ tab: currentTab });
        setActiveKey(key);
    };

    const items = [
        {
            key: '1',
            label: 'Summary',
            children: (
                <div className="display-container">
                    <ClusterSummary switchToTodo={() => onChange('2')} connection={selectedConnectionID} ref={refreshRef} doneRefresh={() => setIsLoading(false)} />
                </div>
            )
        },
        {
            key: '2',
            label: summaryData?.available_tasks > 0 ? <>To-do ({summaryData?.available_tasks})</> : 'To-do',
            children: (
                <div className="display-container todo-list">
                    <TodoList
                        height="calc( 100vh - 230px )"
                        showTitle={false}
                        connection_id={selectedConnectionID}
                        ref={refreshRef}
                        doneRefresh={() => setIsLoading(false)}
                    />
                </div>
            )
        },
        {
            key: '3',
            label: 'Audit',
            children: <Auditing connection={selectedConnectionID} ref={refreshRef} doneRefresh={() => setIsLoading(false)} />
        },
        {
            key: '4',
            label: 'Clients',
            children: (
                <div className="display-container">
                    <Clients connection={selectedConnectionID} ref={refreshRef} doneRefresh={() => setIsLoading(false)} />
                </div>
            )
        },
        {
            key: '5',
            label: 'Configuration',
            children: <Configuration connection={selectedConnectionID} ref={refreshRef} doneRefresh={() => setIsLoading(false)} />
        }
    ];

    const getConnectionSummary = async () => {
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_CONNECTIONS_BY_ID, {}, {}, { connection_id: selectedConnectionID });
            setSummaryData(data?.connection);
        } catch (error) {}
    };

    return (
        <div className="management-layout-container">
            <div className="cluster-overview">
                <div className="cluster-overview-top">
                    <div className="cluster-overview-top-left">
                        <span className="cluster-name">
                            <div className="back-title">
                                <FaArrowLeft alt="Back" onClick={() => navigate(pathDomains.connectedClusters)} className="back-icon" color="var(--text-color)" />
                                <h1>{selectedConnectionName}</h1>
                            </div>
                            {summaryData?.report_status === 'initial' && (
                                <span className="initial-badge">
                                    <label className="initial-badge-tag">Initial analysis</label>
                                    <label>Results may change</label>
                                </span>
                            )}
                        </span>
                    </div>
                    {state?.connections?.length > 0 && (
                        <div className="cluster-overview-top-right">
                            <RefreshButton
                                onClick={() => {
                                    setIsLoading(true);
                                    refreshRef.current.handleRefresh();
                                }}
                                isLoading={isLoading}
                            />
                            <Select
                                suffixIcon={<IoIosArrowDown />}
                                id={'connection'}
                                placeholder={
                                    <span className="filter-icons">
                                        {getIcon(selectedConnectionType, state?.darkMode)}
                                        <p>{selectedConnectionName}</p>
                                    </span>
                                }
                                options={state?.connections?.map((connection) => ({
                                    label: connection?.name,
                                    value: connection?.id
                                }))}
                                onChange={(_, record) => {
                                    const currentTab = searchParams.get('tab');
                                    setSelectedConnectionID(record?.value);
                                    navigate(`${pathDomains.connectedClusters}/${record?.value}?tab=${currentTab}`, { replace: true });
                                }}
                                value={selectedConnectionID}
                                labelRender={() => (
                                    <span className="filter-icons">
                                        {getIcon(selectedConnectionType, state?.darkMode)}
                                        <p>{selectedConnectionName}</p>
                                    </span>
                                )}
                                style={{ width: '220px', height: '40px' }}
                            />
                        </div>
                    )}
                </div>
            </div>
            <div className="cluster-overview-body">
                <Tabs defaultActiveKey="1" items={items} onChange={onChange} activeKey={activeKey} />
            </div>
        </div>
    );
};

export default ClusterOverview;
