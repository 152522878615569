import { Handle, Position } from "reactflow";
import React from "react";
import { ReactComponent as FavIcon } from "assets/images/superstreamFav2.svg";

const SuperStreamNode = ({data}) => {
    return (
        <div className="custom-node superstream-node">
            <div className="custom-node-icon superstream-node-icon">
                <FavIcon/>
            </div>
            <div className="superstream-node-title">
                Superstream
            </div>
            <Handle
                type="source"
                position={Position.Right}
                id={data.id}
                style={{ right: -1.5, background: "var(--primary-color)" }}
                isConnectable={true}
            />
        </div>
    );
};

export default SuperStreamNode;