import "./style.scss";
import { ReactComponent as SuccessIcon } from "assets/images/successTickRounded.svg";
import { ReactComponent as WarningIcon } from "assets/images/warning.svg";
import Heading from "components/heading";
import Button from "components/button";

const Alert = ({type, icon, title, subtitle, buttonLabel, onClick, loading = false}) => {

    return (
        <div className="alert-component ms-form-inner">
            <div className="alert-component-title">
                <Heading fontSize={24} fontWeight={600}>
                    {title}
                </Heading>
            </div>
            <div className="alert-component-icon">
                {icon ?
                    icon :
                    (
                        type === "success" ? <SuccessIcon/> :
                            type === "warning" ? <WarningIcon/> :
                                null
                    )
                }
            </div>
            <div className="alert-component-icon">
                <Heading fontSize={15} fontWeight={400}>
                    {subtitle}
                </Heading>
            </div>
            <div className="alert-component-button">
                <Button
                    customClassName="btn-primary"
                    onClick={onClick}
                    placeholder={buttonLabel}
                    loading={loading}
                />
            </div>
        </div>
    );

}

export default Alert;