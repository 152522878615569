import './style.scss';
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import animationData from 'assets/lotties/loading.json';

const Loader = ({background = true, auto = true, darkMode = true, isFullHeight = false}) => {
    const [showLottie, setShowLottie] = useState(false);

    useEffect(() => {
        const timer = setTimeout(() => {
            setShowLottie(true);
        }, 10);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div style={{overflow: "hidden", boxSizing: "border-box"}}>
            <div className={"loader-container " + (darkMode ? "dark" : "light")} style={isFullHeight ? { height: "100vh", position: "relative"} : {}}>
                {background && <div className="gif-wrapper"></div>}
                <div className={auto ? 'super-stream-gif' : undefined}>
                    {showLottie && <Lottie animationData={animationData} loop={true}/>}
                </div>
            </div>
        </div>
    );
};

export default Loader;
