import "./style.scss";
import AuthLayout from "layouts/authLayout";
import SocialLogin from "components/socialLogin";
import Divider from "components/divider";
import { GoogleAuthProvider, signInWithRedirect } from "firebase/auth";
import { auth } from "services/firebase";
import { ReactComponent as Google } from "assets/images/google.svg";
import SetNewPasswordProfileForm from "./Forms/SetNewPasswordProfileForm";

const SetupNewProfile = () => {
    const providerGoogle = new GoogleAuthProvider();
    providerGoogle.setCustomParameters({
        prompt: "select_account"
    });

    return (
        <AuthLayout>
            <div className="ms-form-inner">
                <div className="social-login">
                    <SocialLogin
                        icon={Google}
                        title="Continue with Google"
                        onClick={() => signInWithRedirect(auth, providerGoogle)}
                    />
                </div>
                <Divider/>
                <SetNewPasswordProfileForm heading="Set new password" />
            </div>
        </AuthLayout>
)
}

export default SetupNewProfile;
