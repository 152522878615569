import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { AuthService, auth } from 'services/firebase';
import { Context } from 'hooks/store';
import { apiEndpoints } from 'services/apiEndpoints';
import {
    LOCAL_STORAGE_DARK_MODE,
    SESSION_STORAGE_ACCOUNT_DATA,
    SESSION_STORAGE_ACCOUNT_ID,
    SESSION_STORAGE_ACTIVATION_TOKEN,
    SESSION_STORAGE_ERROR_MESSAGE,
    SESSION_STORAGE_JWT,
    SESSION_STORAGE_LOGIN_PROVIDER,
    SESSION_STORAGE_USER,
    SESSION_STORAGE_USER_UNDER_ASSESSMENT
} from 'utils/localStorageConsts';
import Loader from '../components/loader';
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { useHttpRequest } from 'services/http';
import router from '../router';
import { useAuth0 } from '@auth0/auth0-react';

const Layout = () => {
    const httpRequest = useHttpRequest();
    const navigate = useNavigate();
    const [state, dispatch] = useContext(Context);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const currentPath = location.pathname.endsWith('/') ? location.pathname.slice(0, -1) : location.pathname;
    const darkMode = false;
    const ssoLoginExecutedRef = useRef(false);

    const { user, isAuthenticated, getIdTokenClaims, isLoading, logout } = useAuth0();

    // const darkMode = localStorage.getItem(LOCAL_STORAGE_DARK_MODE) === 'dark';

    useEffect(() => {
        if (darkMode) {
            dispatch({ type: 'SET_DARK_MODE', payload: darkMode });
        }
        handleAddDarkModeToBody(darkMode);
    }, [state?.darkMode]);

    useEffect(() => {
        if (sessionStorage.getItem(SESSION_STORAGE_JWT) && sessionStorage.getItem(SESSION_STORAGE_USER)) {
            setLoading(false);
            dispatch({ type: 'SET_AUTHENTICATION', payload: true });
            dispatch({ type: 'SET_USER_DATA', payload: sessionStorage.getItem(SESSION_STORAGE_USER) });
        }

        const unsubscribe = onAuthStateChanged(auth, (user) => {
            dispatch({ type: 'SET_USER_DATA', payload: user });
            sessionStorage.setItem(SESSION_STORAGE_USER, JSON.stringify(user));

            if (!user) {
                dispatch({ type: 'SET_AUTHENTICATION', payload: false });
            }

            if (user && sessionStorage.getItem(SESSION_STORAGE_JWT)) {
                dispatch({ type: 'SET_AUTHENTICATION', payload: true });
            }

            if (user && !sessionStorage.getItem(SESSION_STORAGE_JWT) && sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA)) {
                navigate(router.selectAccount, { replace: true });
            }

            if (user && !sessionStorage.getItem(SESSION_STORAGE_JWT) && !sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA)) {
                async function fetchFromAPI() {
                    await fetchData(user);
                }

                fetchFromAPI().catch();
            } else {
                setLoading(false);
            }
        });
        return () => sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) !== 'SSO' && unsubscribe();
    }, []);

    useEffect(() => {
        const sso_login = () => {
            const account_id = location.state?.account_id;
            dispatch({ type: 'SET_USER_DATA', payload: user });
            sessionStorage.setItem(SESSION_STORAGE_USER, JSON.stringify(user));

            if (!user) {
                dispatch({ type: 'SET_AUTHENTICATION', payload: false });
            }

            if (user && isAuthenticated && sessionStorage.getItem(SESSION_STORAGE_JWT)) {
                dispatch({ type: 'SET_AUTHENTICATION', payload: true });
            }

            if (user && !sessionStorage.getItem(SESSION_STORAGE_JWT) && sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA)) {
                navigate(router.selectAccount, { replace: true });
            }

            if (user && !sessionStorage.getItem(SESSION_STORAGE_JWT) && !sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA)) {
                async function fetchFromAPI() {
                    await ssoFetchData(user, account_id);
                }

                fetchFromAPI().catch();
            } else {
                setLoading(false);
            }
        };

        if (isAuthenticated && user && !ssoLoginExecutedRef.current) {
            ssoLoginExecutedRef.current = true;
            sso_login();
        }
    }, [isAuthenticated, user, navigate, getIdTokenClaims, dispatch]);

    useEffect(() => {
        if (state?.isAuthenticated) {
            if (['/signin'].includes(currentPath)) {
                navigate('/');
            }
        }
    }, [currentPath]);

    const handleLogout = async (error = '') => {
        if (sessionStorage.getItem(SESSION_STORAGE_LOGIN_PROVIDER) === 'SSO' && isAuthenticated) {
            try {
                AuthService.clearLocalStorage(error === '');
                error !== '' && sessionStorage.setItem(SESSION_STORAGE_ERROR_MESSAGE, error);
                await logout();
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            } catch (error) {
                console.error('Logout failed', error);
            }
        } else
            try {
                await signOut(auth);
                setTimeout(() => {
                    AuthService.logout(error);
                    setLoading(false);
                }, 1000);
            } catch (error) {
                console.error('Sign out failed', error);
            }
    };

    const fetchData = async (currentUser) => {
        setLoading(true);
        const token = await currentUser.getIdToken(true);
        await httpRequest('POST', apiEndpoints.SIGN_IN, { firebase_id_token: token, userMeta: currentUser?.providerData })
            .then(async (res) => {
                if (res?.data && res.data?.jwt) {
                    sessionStorage.setItem(SESSION_STORAGE_JWT, res.data?.jwt);
                    sessionStorage.setItem(SESSION_STORAGE_ACTIVATION_TOKEN, res.data?.activation_token);
                    sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_ID, res.data?.account_id);
                    sessionStorage.setItem(SESSION_STORAGE_USER_UNDER_ASSESSMENT, res.data?.under_assessment?.toString());

                    dispatch({ type: 'SET_AUTHENTICATION', payload: true });
                    setLoading(false);
                    navigate(router.dashboard);
                }
                if (res?.status === 201) {
                    setLoading(false);
                    sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_DATA, JSON.stringify(res.data));
                    navigate(router.selectAccount, { replace: true });
                }
            })
            .catch(async (error) => {
                if (error?.status === 401) {
                    handleLogout('Unfortunately, we were unable to locate your account in our system!');
                } else if (error.status === 498) {
                    handleLogout(error.data.message);
                } else {
                    handleLogout('Server error, please contact with support!');
                }
            });
    };

    const ssoFetchData = async (currentUser, account_id) => {
        setLoading(true);
        const token = await getIdTokenClaims();
        const idToken = token?.__raw;
        try {
            const res = await httpRequest('POST', apiEndpoints.SIGN_IN, { sso_id_token: idToken, account_id: account_id });
            if (res?.data && res.data?.jwt) {
                sessionStorage.setItem(SESSION_STORAGE_JWT, res.data?.jwt);
                sessionStorage.setItem(SESSION_STORAGE_ACTIVATION_TOKEN, res.data?.activation_token);
                sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_ID, res.data?.account_id);
                sessionStorage.setItem(SESSION_STORAGE_USER_UNDER_ASSESSMENT, res.data?.under_assessment?.toString());

                dispatch({ type: 'SET_AUTHENTICATION', payload: true });
                setLoading(false);
                navigate(router.dashboard);
            }
            if (res?.status === 201) {
                setLoading(false);
                sessionStorage.setItem(SESSION_STORAGE_ACCOUNT_DATA, JSON.stringify(res.data));
                navigate(router.selectAccount, { replace: true });
            }
        } catch (error) {
            if (error?.status === 401) {
                handleLogout('Unfortunately, we were unable to locate your account in our system!');
            } else if (error?.status === 498) {
                handleLogout(error.data.message);
            } else {
                handleLogout('Server error, please contact with support!');
            }
        }
    };

    function handleAddDarkModeToBody(isDarkMode) {
        if (isDarkMode) {
            document.documentElement.classList.add('dark-mode');
        } else {
            document.documentElement.classList.remove('dark-mode');
        }
    }

    return loading || isLoading || state?.isAppLoading ? <Loader darkMode={state?.darkMode || darkMode} /> : <Outlet />;
};

export default Layout;
