import { Handle, Position } from "reactflow";
import { ReactComponent as DataPlanIcon } from "assets/images/graphView/dataplanv2.svg";
import Tooltip from "components/tooltip/tooltip";

const DataPlaneNode = ({data}) => {

    return (
        <div className="custom-node data-plane-node">
            <div className="custom-node-icon">
                <DataPlanIcon/>
            </div>
            <div className="data-plane-node-title">
                <Tooltip text={data?.label} disabled={data?.label?.length < 15}>
                    <div className="eclipse-text" style={{maxWidth: 100}}>
                        {data.label}
                    </div>
                </Tooltip>
            </div>
            <Handle
                type="target"
                position={Position.Left}
                style={{top: "50%", background: '#5A4FE5' }}
                isConnectable={true}
            />
            <Handle
                type="source"
                position={Position.Right}
                id={data.id}
                style={{ top: "49%", background: '#5A4FE5' }}
                isConnectable={true}
            />
        </div>
    );
};

export default DataPlaneNode;