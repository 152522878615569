export const apiEndpoints = {
    //Connections
    CREATE_CONNECTION: '/connections/createConnection',
    GET_ALL_CONNECTIONS: '/connections/getAllConnections',
    UPDATE_CONNECTION: '/connections/updateConnection',
    DELETE_CONNECTION: '/connections/deleteConnection',
    GET_CONNECTIONS_BY_ID: '/connections/getById',
    GET_AGGREGATED_SUMMARY: '/connections/getAggregatedSummary',
    GET_CONNECTION_SETTINGS: '/connections/getConnectionSettings',
    SET_CONNECTION_SETTINGS: '/connections/setConnectionSettings',

    //Environments
    GET_ALL_ENVIRONMENTS: '/environments/getAll',
    GET_GRAPH_DATA: '/environments/getGraphData',

    //AUTH
    SIGN_IN: '/auth/signin',
    RESET_PASSWORD: '/auth/reset-password',
    CHECK_EMAIL: '/auth/checkEmail',

    //Auditing
    GET_AUDIT_LOGS: '/audit/getAll',

    //Tasks
    TASKS_CREATE_GROUP: '/tasks/createTasksGroup',
    TASKS_START_GROUP: '/tasks/startGroup',
    TASKS_CANCEL_GROUP: '/tasks/cancelGroup',
    TASKS_START: '/tasks/startTask',
    TASKS_CANCEL: '/tasks/cancel',

    TASKS_GET_ALL_GROUPS: '/tasks/getAllGroups',
    TASKS_GET_BY_GROUP: '/tasks/getTasksByGroup',
    TASKS_GET_BY_ID: '/tasks/getTaskById',
    TASKS_FIX: '/tasks/fix',
    TASKS_IGNORE: '/tasks/ignore',
    TASKS_MARK_AS_COMPLETE: '/tasks/markAsCompleted',

    //Clients
    CLIENTS_TOGGLE_REDUCTION: '/clients/toggleClientReduction',
    CLIENTS_TOGGLE_COMPRESSION: '/clients/switchCompression',
    CLIENTS_GET_ALL: '/clients/getAll',

    //Keys
    GET_ALL_KEYS: '/keys/getAll',
    CREATE_KEY: '/keys/createKey',
    UPDATE_KEY: '/keys/updateKey',
    DELETE_KEY: '/keys/deleteKey'
};
