import "./style.scss"
import React from "react"
import Logo from "components/logo";
import DarkModeSwitch from "components/darkModeSwitch";

const AuthLayout = ({ children }) => {

    return (
        <div className="ms-layout-auth">
            <div className="ms-layout-auth-bg">
                <div className="ms-layout-auth-bg1"></div>
                <div className="ms-layout-auth-bg2"></div>
                <div className="ms-layout-auth-bg3"></div>
            </div>
            <div className="ms-layout-auth-mode-switch">
                {/*<DarkModeSwitch/>*/}
            </div>
            <div className="ms-layout-auth-inner">
                <div className="ms-layout-auth-inner-logo">
                    <Logo />
                </div>
                <div className="ms-layout-auth-inner-form">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default AuthLayout;
