import { ReactComponent as CheckIcon } from 'assets/images/graphView/check.svg';

const ConnectorDrawerSteps = ({ handleStep, currentStep }) => {
    const items = ['Prerequisites', 'Metadata', 'Kafka', 'Deploy'];

    return (
        <div className="add-connector-drawer-steps">
            {items.map((item, index) => {
                return (
                    <div
                        key={'steps-' + index}
                        className={
                            'add-connector-drawer-steps-item ' +
                            (currentStep === index && ' current ') +
                            (currentStep === index && index !== items.length - 1 && ' current inactive-helper ') +
                            (currentStep > index && ' done active-helper ') +
                            (index < currentStep - 1 && ' done-helper ')
                        }
                        onClick={() => handleStep('set', index)}
                    >
                        <div className="left">{currentStep <= index ? <span>{index + 1}</span> : <CheckIcon />}</div>
                        <div className="right">{item}</div>
                        {index !== items.length - 1 && <div className="add-connector-drawer-steps-item-arrow"></div>}
                    </div>
                );
            })}
        </div>
    );
};

export default ConnectorDrawerSteps;
