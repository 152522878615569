import './style.scss';
import React from 'react';
import { Modal as AntModal } from 'antd';

const Modal = ({ isModalOpen, clickOutside, children, forceRender, width }) => {
    return (
        <AntModal width={width} forceRender={forceRender} open={isModalOpen} footer={null} centered destroyOnClose={true} onCancel={() => clickOutside()} zIndex={9999}>
            {children}
        </AntModal>
    );
};

export default Modal;
