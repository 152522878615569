import Button from 'components/button';
import { FaGithub } from 'react-icons/fa';
import { IoCloseOutline } from 'react-icons/io5';
import { ReactComponent as DataIcon } from 'assets/images/graphView/dataIcon.svg';

const ConnectorDrawerHeader = ({ setDataPlanModal }) => {
    const handleExternalLink = ({}) => {
        window.open('https://github.com/memphisdev/superstream-dp-deployment', '_blank');
    };

    return (
        <div className="add-connector-drawer-header">
            <div className="left">
                <DataIcon />
            </div>
            <div className="right">
                <div className="right-wrapper">
                    <div className="right-title">Deploy engine</div>
                    <div className="right-btn">
                        <Button
                            onClick={handleExternalLink}
                            typeOfButton="text"
                            placeholder={
                                <>
                                    <FaGithub />
                                </>
                            }
                        />
                    </div>
                </div>
                <div className="right-subtitle">Engines serve as our gateways for communicating with your Kafka.</div>
            </div>
            <div className="close-icon">
                <Button typeOfButton="text" placeholder={<IoCloseOutline color="#667085" />} onClick={() => setDataPlanModal(false)} />
            </div>
        </div>
    );
};

export default ConnectorDrawerHeader;
