import { Navigate, Outlet } from 'react-router-dom';
import { Context } from 'hooks/store';
import { useContext } from 'react';
import { SESSION_STORAGE_ACCOUNT_DATA } from "./localStorageConsts";

const PrivateRoute = () => {
    const [state] = useContext(Context);

    return state?.isAuthenticated || sessionStorage.getItem(SESSION_STORAGE_ACCOUNT_DATA) ? <Outlet /> : <Navigate to="/signin" replace />;
};

export default PrivateRoute;
