import './style.scss';
import { useEffect, useContext, useState, useImperativeHandle, forwardRef, useRef } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import Loader from 'components/loader';
import { Context } from 'hooks/store';
import { apiEndpoints } from 'services/apiEndpoints';
import { parsingDate, uuidv4 } from 'services/valueConvertor';
import { calculateETA, calculatePassedTime, downloadYamlFile } from 'services/genericServices';
import { useHttpRequest } from 'services/http';
import { BiError } from 'react-icons/bi';
import { GoQuestion } from 'react-icons/go';
import { ReactComponent as ExcpectedSaving } from 'assets/images/excpectedSaving.svg';
import { ReactComponent as AlreadySaved } from 'assets/images/alreadySaved.svg';
import { ReactComponent as DownloadIcon } from 'assets/images/download.svg';
import AddConnectorPlaceholder from 'components/addConnectorPlaceholder';
import { Progress } from 'antd';
import { ReactComponent as Reliability } from 'assets/images/optimization/reliability.svg';
import OptimizationCharts from 'components/optimizationCharts';
import Button from 'components/button';
import KeyModal from 'components/Modals/KeyModal';
import TooltipComponent from 'components/tooltip/tooltip';
import ButtonsWrapper from 'components/buttonsWrapper';
import CustomButtonWrapper from 'components/buttonsWrapper/CustomButtonWrapper';

const CLUSTER_ID_NOT_FOUND = -1;

const ClusterSummary = forwardRef(({ switchToTodo, connection, doneRefresh }, ref) => {
    const httpRequest = useHttpRequest();
    const navigate = useNavigate();
    const location = useLocation();
    const updateKeyRef = useRef(null);
    const [state, dispatch] = useContext(Context);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [selectedConnectionID, setSelectedConnectionID] = useState(null);
    const [summaryData, setSummaryData] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [graphData, setGraphData] = useState(null);

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            getConnectionSummary(selectedConnectionID).catch();
        }
    }));

    useEffect(() => {
        const pathTokens = decodeURI(location.pathname)?.split('/');
        const connectionId = pathTokens[pathTokens?.length - 1];
        setSelectedConnectionID(connectionId);
    }, [connection, location.pathname]);

    useEffect(() => {
        if (selectedConnectionID === CLUSTER_ID_NOT_FOUND) navigate('/optimizations');
        else if (selectedConnectionID) {
            Promise.all([
                getConnectionSummary(selectedConnectionID).catch(),
                getGraphData(selectedConnectionID).catch()
            ])
        }
    }, [selectedConnectionID]);

    const getGraphData = async (connection_id) => {
        setIsPageLoading(true);
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_AGGREGATED_SUMMARY, {}, {}, {connection_id: connection_id});
            setGraphData(data?.summary);
        } catch (error) {
            setIsPageLoading(false);
        } finally {
            setIsPageLoading(false);
        }
    };

    const getConnectionSummary = async (connection_id) => {
        setIsPageLoading(true);
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_CONNECTIONS_BY_ID, {}, {}, { connection_id: connection_id });
            setSummaryData(data?.connection);
        } catch (error) {
            if (error?.status === 406) navigate('/optimizations');
        } finally {
            setIsPageLoading(false);
            doneRefresh();
        }
    };

    const information = [
        {
            label: 'Connection name',
            name: 'name'
        },
        {
            label: 'Creation date',
            name: 'updated_at',
            type: 'date'
        },
        {
            label: (
                <span>
                    Billing key
                    <GoQuestion
                        className="more-info"
                        onClick={() =>
                            window.open(
                                'https://docs.superstream.ai/getting-started/step-3-connect-your-kafka-cluster-s#optional-add-a-vendor-api-key-for-deeper-insights',
                                '_blank'
                            )
                        }
                    />
                </span>
            ),
            name: 'key'
        },

        {
            label: 'Engine',
            name: 'data_plane'
        },
        {
            label: 'Last sync',
            name: 'last_report_time',
            type: 'date'
        },
        {
            label: 'Next sync',
            name: 'eta_next_report_time',
            type: 'eta'
        }
    ];

    const getKeyName = (key) => {
        return state?.keysList?.find((item) => item?.id === key)?.name || 'Add a new key';
    };

    const getInfo = (data) => {
        switch (data?.type) {
            case 'date':
                return parsingDate(summaryData?.[data?.name], true);
            case 'eta':
                return summaryData?.[data?.name] === 0 ? 'soon' : calculateETA(summaryData?.[data?.name]);
            default:
                return summaryData?.[data?.name];
        }
    };

    const reliabilityScore = (
        <div className="reliability-score">
            <Reliability />
            <label>Reliability score: {summaryData?.optimizations?.reliability?.total_approximate_reduction || 0}/100</label>
        </div>
    );

    const getMethod = () => {
        switch (summaryData?.settings?.security_protocol) {
            case 'SASL_SSL':
                return `sasl:
                    enabled: true
                    mechanism: ${summaryData?.settings?.sasl_mechanism}
                    protocol: SASL_SSL
                    username: ${summaryData?.settings?.sasl_username}
                    password: ********
                    tls:
                        enabled: ${summaryData?.settings?.tls_enabled !== 'custom' ? false : true}
                        ${summaryData?.settings?.tls_enabled === 'custom' ? `insecureSkipVerify: ${summaryData?.settings?.insecure_skip_verify === true}` : ``}
                        ${
                            summaryData?.settings?.tls_enabled === 'custom' && summaryData?.settings?.insecure_skip_verify
                                ? `ca: ${summaryData?.settings?.ssl_ca_pem}
                                cert: ${summaryData?.settings?.ssl_certificate_pem}
                                key: ${summaryData?.settings?.ssl_key_pem}`
                                : ``
                        }`;
            case 'SSL':
                return `ssl:
                    enabled: true
                    protocol: SSL
                    ${summaryData?.settings?.ssl_ca_pem && `ca: ${summaryData?.settings?.ssl_ca_pem}`}
                    ${summaryData?.settings?.ssl_certificate_pem && `cert: ${summaryData?.settings?.ssl_certificate_pem}`}
                    ${summaryData?.settings?.ssl_key_pem && `key: ${summaryData?.settings?.ssl_key_pem}`}
                    insecureSkipVerify: ${summaryData?.settings?.insecure_skip_verify === true}`;
            default:
                return `noAuthentication:
                    enabled: true`;
        }
    };

    const handleDownload = () => {
        const yamlContent = `superstreamEngine:
    configMap:
      enable: true
      name: connection.conf
      fileData:
          fileName: connection.conf
          fileContent: | 
            connections:
            - name: ${summaryData?.name}
              type: ${summaryData?.type}
              hosts: 
              - ${summaryData?.settings?.bootstrap_servers}
              authentication:
                method:
                  ${getMethod()}`;
        downloadYamlFile(yamlContent, 'config.yaml');
    };

    return (
        <div className="cluster-summary">
            {isPageLoading && <Loader background={false} />}
            {!isPageLoading && state?.connections?.length === 0 && <AddConnectorPlaceholder />}
            {!isPageLoading && state?.connections?.length > 0 && (
                <div className="cluster-summary-view">
                    <div>
                        <span className="section-title">
                            <p>Information</p>
                            <span className="right">
                                {reliabilityScore}
                                <ButtonsWrapper>
                                    <Button
                                        onClick={() => handleDownload()}
                                        customClassName="primary"
                                        style={{ padding: '16px 20px' }}
                                        placeholder={<CustomButtonWrapper textColor="var(--white-color)" icon={<DownloadIcon />} text="config.yaml" withArrow={false} />}
                                    />
                                </ButtonsWrapper>
                            </span>
                        </span>
                        <div className="information-container">
                            {information?.map((item, index) => (
                                <div className="information-item" key={index}>
                                    <label>{item?.label}</label>
                                    <span>
                                        {item?.name === 'key' ? (
                                            getKeyName(summaryData?.[item?.name]) === 'Add a new key' ? (
                                                <Button
                                                    style={{ padding: '12px 20px'}}
                                                    customClassName="primary"
                                                    onClick={() => setIsModalOpen(true)}
                                                    placeholder={
                                                        getKeyName(summaryData?.[item?.name])
                                                    }
                                                />
                                            ) : (
                                                <p className="key">{getKeyName(summaryData?.[item?.name])}</p>
                                            )
                                        ) : item?.name === 'eta_next_report_time' && summaryData?.report_status === 'running' ? (
                                            <Progress
                                                percent={summaryData?.progress_bar}
                                                status="active"
                                                format={() => (
                                                    <label className="calculating-text">{`Analyzing the cluster for the first time... (${summaryData?.progress_bar}%)`}</label>
                                                )}
                                                style={{ width: '280px', display: 'flex', flexDirection: 'column-reverse', lineHeight: '12px' }}
                                                strokeWidth={6}
                                                strokeColor="var(--primary-color)"
                                            />
                                        ) : (
                                            <p>{getInfo(item)}</p>
                                        )}
                                        {item?.name === 'name' && !summaryData?.valid && (
                                            <TooltipComponent text={summaryData?.invalid_reason}>
                                                <BiError color="var(--failed-color)" />
                                            </TooltipComponent>
                                        )}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <span className="section-title">
                            <span>
                                <p>
                                    Potential savings
                                    {summaryData?.last_report_time && <label style={{marginLeft: 3}}>Synced {calculatePassedTime(summaryData?.last_report_time)}</label>}
                                </p>
                            </span>
                            <span className="section-title-right">
                                <Button
                                    style={{padding: '14px 20px'}}
                                    onClick={() => switchToTodo()}
                                    customClassName="primary"
                                    placeholder="Fix now"
                                    disabled={summaryData?.available_tasks === 0}
                                />
                            </span>
                        </span>

                        <div className="saving-banner">
                            <span className="saving-banner-item">
                                <span className="saving-banner-item-icon purple">
                                    <ExcpectedSaving />
                                </span>
                                <span>
                                    {/* <p>Your total expected savings are: {`$${summaryData?.cost_savings?.yearly_expected?.toLocaleString()}/year`}</p> */}
                                    <p>Potential annual savings: *will be available soon*</p>
                                </span>
                            </span>
                            <span className="saving-banner-item">
                                <span className="saving-banner-item-icon green">
                                    <AlreadySaved />
                                </span>
                                <span>
                                    <p>So far you saved: *will be available soon*</p>
                                    {/* {summaryData?.cost_savings?.saved >= 0 ? (
                                        <p>Your current savings: ${summaryData?.cost_savings?.saved?.toLocaleString()}</p>
                                    ) : (
                                        <p className="loading">Your current savings: are being calculated...</p>
                                    )} */}
                                </span>
                            </span>
                        </div>
                    </div>
                    <OptimizationCharts
                        data={graphData?.graph_data}
                    />
                </div>
            )}
            <KeyModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} currentCluster={summaryData?.name} keyToUpdate={updateKeyRef.current} />
        </div>
    );
});
export default ClusterSummary;
