import "./style.scss"
import React from 'react';

const ButtonsWrapper = ({customClass = '', customStyle, children}) => {
    return (
        <div className={"button-wrapper " + customClass} style={customStyle}>
            <div className="button-wrapper-inner">
                {children}
            </div>
        </div>
    );
};

export default ButtonsWrapper;
