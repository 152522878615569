import './style.scss';
import React, { useEffect, useState, useContext } from 'react';
import { Context } from 'hooks/store';
import Loader from 'components/loader';
import Button from 'components/button';
import Table from 'components/table';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import { getIcon } from 'services/genericServices';
import { LuPlus } from 'react-icons/lu';
import { ReactComponent as EditIcon } from 'assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/deleteIcon.svg';
import KeyModal from 'components/Modals/KeyModal';
import Spinner from 'components/spinner';
import useTableDynamicPageSize from 'hooks/useTableDynamicPageSize';
import useResizableColumns from 'components/table/useResizableColumns';
import DeleteItemsModal from 'components/deleteItemsModal';
import Modal from '../../components/modal';

const Keys = () => {
    const httpRequest = useHttpRequest();
    const [state, dispatch] = useContext(Context);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [keyToDelete, setKeyToDelete] = useState(null);
    const pageSize = useTableDynamicPageSize();
    const updateKeyRef = React.useRef({});
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    const columns = [
        {
            title: 'Key Name',
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name?.localeCompare(b.name),
            width: 1
        },
        {
            title: 'Vendor',
            dataIndex: 'vendor',
            key: 'vendor',
            render: (vendor) => {
                const vendor_format = vendor.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());

                return (
                    <span className="filter-icons">
                        {getIcon(vendor)} {vendor_format.replace('Msk', 'MSK')}
                    </span>
                );
            },
            sorter: (a, b) => a.vendor?.localeCompare(b.vendor),
            width: 1
        },
        {
            title: 'Type',
            dataIndex: 'type',
            render: (type) => {
                const type_format = type.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase());
                return type_format.replace('Api', 'API');
            },
            key: 'type',
            width: 1
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
            width: 1,
            render: (_, record) => (
                <keys-options is="x3s">
                    <update
                        is="x3s"
                        onClick={() => {
                            updateKeyRef.current = record;
                            setIsModalOpen(true);
                        }}
                    >
                        <EditIcon height="15px" />
                    </update>
                    <delete
                        is="x3s"
                        onClick={() => {
                            setKeyToDelete(record?.id);
                            setDeleteModalOpen(true);
                        }}
                    >
                        <DeleteIcon height="15px" />
                        {keyToDelete === record?.id && isLoading && <Spinner fontSize={12} color={'var(--failed-color)'} />}
                    </delete>
                </keys-options>
            )
        }
    ];

    useEffect(() => {
        setIsPageLoading(true);
        getAllKeys();
    }, []);

    const getAllKeys = async () => {
        setIsLoading(true);
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_ALL_KEYS, {}, {}, {});
            dispatch({ type: 'SET_KEYS_LIST', payload: data?.keys });
        } catch (error) {
        } finally {
            setIsLoading(false);
            setIsPageLoading(false);
        }
    };

    const deleteKeys = async (keyToDelete) => {
        setIsLoading(true);
        try {
            await httpRequest('DELETE', apiEndpoints.DELETE_KEY, { id: keyToDelete });
            dispatch({ type: 'SET_KEYS_LIST', payload: state?.keysList?.filter((key) => key?.id !== keyToDelete) });
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
        } finally {
            setDeleteModalOpen(false);
        }
    };

    const { components, resizableColumns } = useResizableColumns(columns);

    return isPageLoading ? (
        <Loader background={false} isFullHeight={true} />
    ) : (
        <div className="keys management-layout-container">
            <div className="keys-top">
                <span className="keys-top-left">
                    <h1>Keys</h1>
                    <p>Keys enable Superstream to connect to additional services and enhance your insights</p>
                </span>
                <span className="keys-top-right">
                    <Button
                        onClick={() => {
                            updateKeyRef.current = null;
                            setIsModalOpen(true);
                        }}
                        typeOfButton="text"
                        placeholder={
                            <div className="add-connector-placeholder-bottom-button">
                                <LuPlus color="var(--color-white)" fontSize={18} />
                                <p>Add a new key</p>
                            </div>
                        }
                    />
                </span>
            </div>
            {!isPageLoading && (
                <div className="keys-body">
                    <Table
                        components={components}
                        columns={resizableColumns}
                        dataSource={state?.keysList}
                        pagination={state?.keysList?.length > pageSize ? { pageSize: pageSize, showSizeChanger: false } : false}
                        bordered={false}
                        showSorterTooltip={false}
                        rowHoverBgColor="var(--table-row-even) !important"
                    />
                </div>
            )}
            <KeyModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} keyToUpdate={updateKeyRef.current} />
            <Modal isModalOpen={deleteModalOpen} setIsModalOpen={setDeleteModalOpen} clickOutside={() => setDeleteModalOpen(false)}>
                <DeleteItemsModal
                    title="Delete Key"
                    desc="Are you sure you want to delete this key?"
                    handleDeleteSelected={() => deleteKeys(keyToDelete)}
                    buttontxt="Delete"
                    textToConfirm="delete"
                    loader={isLoading}
                />
            </Modal>
        </div>
    );
};

export default Keys;
