import React from 'react';

const EmailLink = ({ email }) => {
    const handleEmailClick = () => {
        window.location.href = `mailto:${email}`;
    };

    return (
        <span onClick={handleEmailClick} style={{ textDecoration: 'underline', cursor: 'pointer', color: 'var(--purple)' }}>
            {email}
        </span>
    );
};

export default EmailLink;
