import React, { useState } from 'react';
import { Form } from 'antd';
import Heading from 'components/heading';
import Button from 'components/button';
import Input from 'components/input';
import { apiEndpoints } from 'services/apiEndpoints';
import FormServerMessage from 'components/formServerMessage';
import firebaseMessages from 'utils/firebaseMessages';
import { FaArrowLeftLong } from 'react-icons/fa6';
import CustomButtonWrapper from 'components/buttonsWrapper/CustomButtonWrapper';
import { useNavigate } from 'react-router-dom';
import Alert from '../components/alert';
import { useHttpRequest } from 'services/http';

const ForgetPasswordForm = () => {
    const httpRequest = useHttpRequest();
    const navigate = useNavigate();
    const [isSent, setIsSent] = useState(false);
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [serverMessage, setServerMessage] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);

    const onFinish = async (values) => {
        const { email } = values;

        try {
            setLoading(true);
            await httpRequest('POST', apiEndpoints.RESET_PASSWORD, { email });
            setIsSent(true);
        } catch (error) {
            if (error.status === 406) setServerMessage(error?.data?.message);
        } finally {
            setLoading(false);
        }
    };

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const allFieldsNotEmpty = allFields.every((field) => field.value);
        setIsFormValid(allFieldsNotEmpty && errors.filter(({ errors }) => errors.length).length === 0);
    };

    return (
        <div className="ms-form-inner">
            <div className="forget-password-form">
                {!isSent ? (
                    <>
                        <Heading fontSize={24} fontWeight={600}>
                            Password Recovery
                        </Heading>

                        <Heading fontSize={15} fontWeight={400}>
                            Enter the email address associated with your account and we’ll send you a link to reset your password.
                        </Heading>

                        <Form
                            name="forget-passowrd"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                            className="form"
                            style={{ gap: 5 }}
                            onFieldsChange={onFieldsChange}
                        >
                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your email!'
                                    },
                                    {
                                        type: 'email',
                                        message: 'Please enter a valid email address!'
                                    }
                                ]}
                            >
                                <Input type="text" placeholder="Enter email address" label="*Email" customClass="rounded" />
                            </Form.Item>

                            <FormServerMessage text={serverMessage} />

                            <Form.Item style={{ marginBottom: 7 }}>
                                <Button customClassName="btn-primary" htmlType="submit" loading={loading} placeholder="Continue" disabled={!isFormValid} />
                            </Form.Item>

                            <Form.Item style={{ marginBottom: '0' }}>
                                <Button
                                    onClick={() => navigate('/signin')}
                                    customClassName="ms-button"
                                    placeholder={<CustomButtonWrapper icon={<FaArrowLeftLong color="var(--text-color)" />} text="Go Back" withArrow={false} />}
                                />
                            </Form.Item>
                        </Form>
                    </>
                ) : (
                    <Alert
                        type="success"
                        title="Success"
                        subtitle="Your reset password request successfully sent!"
                        buttonLabel="Go to signin"
                        onClick={() => {
                            navigate('/signin');
                        }}
                    />
                )}
            </div>
        </div>
    );
};

export default ForgetPasswordForm;
