import './style.scss';
import React from 'react';
import { Drawer } from 'antd';

const MSDrawer = ({ title, placement, size, width, height, className, onClose, destroyOnClose, open, closeIcon, children, mask, styles }) => {
    return (
        <Drawer
            title={title}
            placement={placement}
            size={size}
            width={width || 600}
            height={height}
            className={className}
            onClose={onClose}
            destroyOnClose={destroyOnClose}
            open={open}
            closeIcon={closeIcon}
            mask={mask}
            styles={styles}
        >
            {children}
        </Drawer>
    );
};

export default MSDrawer;
