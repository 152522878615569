import Button from 'components/button';

const ConnectorModalFooter = ({ setIsModalOpen, onClick, onBack, currentModalState, edit, isUpdated, isLoading }) => {
    return (
        <div className="footer">
            <span className="footer-btns">
                <Button customClassName="modal-btn modal-btn-secondary" placeholder="Close" onClick={() => setIsModalOpen(false)} />
                <Button
                    customClassName="modal-btn modal-btn-primary"
                    placeholder={currentModalState === 'failed' ? 'Back' : currentModalState === 'connected' ? 'Add another cluster' : edit ? 'Modify' : 'Connect'}
                    onClick={() => {
                        currentModalState === 'connected' || currentModalState === 'failed' ? onBack() : onClick();
                    }}
                    disabled={currentModalState === 'connecting' || (edit && !isUpdated)}
                    loading={isLoading}
                />
            </span>
            {isLoading && <label>It might take a few seconds. Please wait before closing the modal.</label>}
        </div>
    );
};

export default ConnectorModalFooter;
