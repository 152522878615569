import './style.scss';

import React, { useState } from 'react';

import { Divider } from 'antd';
import { BiDotsHorizontal } from 'react-icons/bi';
import { ReactComponent as EditIcon } from 'assets/images/editIcon.svg';
import { ReactComponent as DeleteIcon } from 'assets/images/deleteIcon.svg';
import { ReactComponent as ConnectionIcon } from 'assets/images/connectionIcon.svg';
import { ReactComponent as DataPlaneIcon } from 'assets/images/dataPlaneIcon.svg';
import { ReactComponent as ServerIcon } from 'assets/images/serverIcon.svg';
import { ReactComponent as KafkaIcon } from 'assets/images/kafkaIcon.svg';
import { ReactComponent as SecurityIcon } from 'assets/images/securityIcon.svg';
import { ReactComponent as Healthy } from 'assets/images/badgeIcons/healthy.svg';
import { apiEndpoints } from 'services/apiEndpoints';
import OverflowTip from 'components/tooltip/overflowtip';
import Popover from 'components/popover';
import Spinner from 'components/spinner';
import ConnectorModal from 'components/Modals/ConnectorModal';
import { useHttpRequest } from 'services/http';
import Badge from 'components/badge';
import { LuInfo } from 'react-icons/lu';
import TooltipComponent from 'components/tooltip/tooltip';

const overlayStylesConnectionOptions = {
    borderRadius: '8px',
    width: '180px'
};

const ConnectionItem = ({ connection, removeConnection }) => {
    const httpRequest = useHttpRequest();
    const [connectionActionOpen, setConnectionActionOpen] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const deleteConnection = async () => {
        setLoading(true);
        try {
            await httpRequest('DELETE', apiEndpoints.DELETE_CONNECTION, { id: connection?.id, data_plane: connection?.data_plane });
            removeConnection(connection?.id);
            setConnectionActionOpen(false);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const connectionOptions = (
        <connection-options is="x3s">
            <edit
                is="x3s"
                onClick={() => {
                    setIsModalOpen(true);
                    setConnectionActionOpen(false);
                }}
            >
                <EditIcon height="15px" />
                Modify
            </edit>
            <Divider />
            <delete is="x3s" onClick={() => deleteConnection()}>
                <span>
                    <div style={{minWidth: 20, display: "flex", alignItems: "center"}}>
                        {loading ? <Spinner fontSize={16} color={"red"} loading={loading} /> : <DeleteIcon height="15px" />}
                    </div>
                    Delete
                </span>
            </delete>
        </connection-options>
    );

    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Popover
                overlayInnerStyle={overlayStylesConnectionOptions}
                placement="bottom"
                content={connectionOptions}
                trigger="click"
                onOpenChange={() => setConnectionActionOpen(!connectionActionOpen)}
                open={connectionActionOpen}
            >
                <>
                    <BiDotsHorizontal className={`dots-icon ${connectionActionOpen ? 'dots-icon-active' : undefined}`} />
                </>
            </Popover>

            <ConnectorModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} connection={connection} />
        </div>
    );
};

export default ConnectionItem;
