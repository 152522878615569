import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider } from 'antd';
import ListItem from '../../listItem';
import { useAnimate, usePresence } from 'framer-motion';
import { Context } from 'hooks/store';
import { FaArrowRight } from 'react-icons/fa';

const TodoListItemContainer = ({ todo, fetchByGroupTasks, loadingItemId, status, triggerConfirmation, displayCluser }) => {
    const [state, dispach] = useContext(Context);
    const [isPresent, safeToRemove] = usePresence();
    const [scope, animate] = useAnimate();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isPresent) {
            const exitAnimation = async () => {
                await animate(scope.current, { opacity: 0, height: 0 });

                safeToRemove();
            };

            void exitAnimation();
        }
    }, [isPresent]);

    const getEngineName = (connection) => {
        return state?.connections?.find((item) => item?.id === connection)?.data_plane;
    };

    return (
        <>
            {displayCluser && (
                <div className="cluster-name-divider">
                    Cluster name:
                    <p>
                        {` ${todo?.connection_name} (${getEngineName(todo?.connection_id)})`}{' '}
                        <FaArrowRight onClick={() => navigate(`/connected-clusters/${todo?.connection_id}?tab=summary`)} />
                    </p>
                </div>
            )}
            <Divider />
            <ListItem
                ref={scope}
                key={todo.id}
                handleOpenDrawer={fetchByGroupTasks}
                item={todo}
                triggerConfirmation={triggerConfirmation}
                loadingItemId={loadingItemId}
                status={status}
            />
        </>
    );
};

export default TodoListItemContainer;
