import './style.scss';

import { useEffect, useState, useContext } from 'react';
import { Form, Result } from 'antd';
import { ReactComponent as AddConnectorIcon } from 'assets/images/addConnector.svg';
import { ReactComponent as WarningResultIcon } from 'assets/images/warningResultIcon.svg';
import { ReactComponent as SuccessResultIcon } from 'assets/images/successResultIcon.svg';
import ConnectorModalHeader from './connectorModalComponents/modalHeader';
import ConnectorModalForm from './connectorModalComponents/form';
import ConnectorModalFooter from './connectorModalComponents/modalFooter';
import { apiEndpoints } from 'services/apiEndpoints';
import { Context } from 'hooks/store';
import Spinner from 'components/spinner';
import Modal from 'components/modal';
import { useHttpRequest } from 'services/http';

const Index = ({ isModalOpen, setIsModalOpen, connection, sysViewUpdate }) => {
    const httpRequest = useHttpRequest();
    const [state, dispatch] = useContext(Context);
    const [form] = Form.useForm();
    const [currentModalState, setCurrentModalState] = useState('create');
    const [isUpdated, setIsUpdated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errMessage, setErrMessage] = useState('');

    useEffect(() => {
        if (isModalOpen) {
            setCurrentModalState('create');
            connection && form.setFieldsValue({ ...connection, ...connection.settings });
        } else form.resetFields();
    }, [isModalOpen]);

    const checkIfUpdated = (connection, updateConnection) => {
        let updated = false;
        for (const key in updateConnection) {
            if (updateConnection[key] !== connection[key]) {
                updated = true;
                break;
            }
        }
        setIsUpdated(updated);
    };

    const getUpdatedObject = (connection, updateConnection) => {
        for (const key in updateConnection) {
            if (updateConnection[key] === connection[key]) {
                delete updateConnection[key];
            }
        }
        const { name, type, key, data_plane, cluster_id, ...settings } = updateConnection;
        return {
            ...(name && { name }),
            ...(type && { type }),
            ...((data_plane && { data_plane }) || data_plane),
            ...(key && { key }),
            ...(cluster_id && { cluster_id }),
            ...(Object.keys(settings).length > 0 && { settings })
        };
    };

    const getAllConnections = async () => {
        try {
            const data = await httpRequest('GET', apiEndpoints.GET_ALL_CONNECTIONS);
            dispatch({ type: 'SET_CONNECTIONS', payload: data?.connections || [] });
        } catch (error) {
        } finally {
            dispatch({ type: 'SET_LOADING_CONNECTIONS', payload: false });
        }
    };

    const onFinish = async (values) => {
        if (connection) {
            const updatedFields = getUpdatedObject({ ...connection, ...connection.settings }, values);
            Object.keys(updatedFields).length > 0 && updateConnection({ id: connection?.id, ...updatedFields });
        } else {
            const { name, type, data_plane, key, cluster_id, ...settings } = values;
            createConnection({ name, type, data_plane, key, cluster_id, settings });
        }
    };

    const createConnection = async (formFields) => {
        setIsLoading(true);
        try {
            await httpRequest('POST', apiEndpoints.CREATE_CONNECTION, formFields);
            setCurrentModalState('connected');
            getAllConnections();
            setIsLoading(false);
            setErrMessage('');
            sysViewUpdate && sysViewUpdate();
        } catch (error) {
            if (error.status === 499) {
                setErrMessage(error.data.message);
            }
            setCurrentModalState('failed');
            setIsLoading(false);
        }
    };

    const updateConnection = async (formFields) => {
        if (!formFields.hasOwnProperty('data_plane')) formFields['data_plane'] = connection?.data_plane;
        setIsLoading(true);
        try {
            await httpRequest('PUT', apiEndpoints.UPDATE_CONNECTION, formFields);
            setCurrentModalState('connected');
            getAllConnections();
            setIsModalOpen(false);
            setIsLoading(false);
            setErrMessage('');
        } catch (error) {
            if (error.status === 499) {
                setErrMessage(error.data.message);
            }
            setCurrentModalState('failed');
            setIsLoading(false);
        }
    };

    const connectorState = {
        create: {
            icon: <AddConnectorIcon />,
            title: connection ? 'Modify cluster' : 'Add a new cluster'
        },
        connecting: {
            icon: <AddConnectorIcon />,
            title: 'Validating connectivity',
            subtitle: 'Connecting to the kafka cluster and validating the connection'
        },
        failed: {
            icon: <AddConnectorIcon />,
            title: 'Connection failed',
            subtitle: 'Failed to connect to the kafka cluster'
        },
        connected: {
            icon: <AddConnectorIcon />,
            title: 'Connection established',
            subtitle: 'Connected to the kafka cluster successfully'
        }
    };

    return (
        <Modal isModalOpen={isModalOpen} clickOutside={() => setIsModalOpen(false)}>
            <div className="ms-modal connect-cluster-modal">
                <div className="ms-modal-header">
                    <ConnectorModalHeader goBack={() => setCurrentModalState('create')} {...connectorState[currentModalState]} />
                </div>
                <div className="ms-modal-body">
                    {currentModalState === 'create' && (
                        <ConnectorModalForm
                            onFinish={onFinish}
                            form={form}
                            updated={(value) => connection && checkIfUpdated(connection, value)}
                            updateMode={connection ? true : false}
                        />
                    )}
                    {currentModalState === 'connecting' && (
                        <div className="connector-modal-loading">
                            <div className="connector-modal-loading-spinner">
                                <Spinner fontSize={60} />
                            </div>
                            <h2>Connecting</h2>
                        </div>
                    )}
                    {currentModalState === 'failed' && (
                        <Result rootClassName="result-section" icon={<WarningResultIcon />} title={<h2>Failed connecting</h2>} subTitle={errMessage} />
                    )}
                    {currentModalState === 'connected' && <Result rootClassName="result-section" icon={<SuccessResultIcon />} title={<h2>Successfully connected</h2>} />}
                </div>
                <div className="ms-modal-footer">
                    <ConnectorModalFooter
                        setIsModalOpen={setIsModalOpen}
                        onClick={() => form.submit()}
                        currentModalState={currentModalState}
                        onBack={() => {
                            setCurrentModalState('create');
                            setErrMessage('');
                        }}
                        edit={connection ? true : false}
                        isUpdated={isUpdated}
                        isLoading={isLoading}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default Index;
