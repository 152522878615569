import './style.scss';
import React, { useEffect, useState } from 'react';
import Modal from 'components/modal';
import { parsingDate } from 'services/valueConvertor';
import Spinner from 'components/spinner';

const ClientLogs = ({ open, clickOutside, clientId }) => {
    const [logs, setLogs] = useState(null);

    useEffect(() => {
        //Call API to get logs and set it to hook logs
    }, [clientId]);

    return (
        <Modal isModalOpen={open} clickOutside={clickOutside} width="800px">
            <div className="client-logs-modal">
                <div className="client-logs-modal-header">Clients error logs</div>
                <div className="client-logs-modal-content">
                    <div className="client-log title">
                        <div>Timestamp</div>
                        <div>Message</div>
                    </div>
                    <div className="client-logs-modal-content-body">
                        {!logs ? (
                            <Spinner />
                        ) : (
                            logs?.map((log, index) => (
                                <div key={index} className="client-log">
                                    <div>{parsingDate(log?.timestamp, true, true)}</div>
                                    <div>{log?.message}</div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};
export default ClientLogs;
