import './style.scss';
import React from 'react';
import { IoIosCheckmark, IoIosInformationCircleOutline } from 'react-icons/io';
import { Progress } from 'antd';
import TooltipComponent from 'components/tooltip/tooltip';
import { calculateETA } from 'services/genericServices';

const StatusBadge = ({ type, sample_progress, eta }) => {
    return (
        <div className={'status-badge-report ' + type}>
            {type === 'running' && (
                <Progress
                    format={() => <label>{`Analyzing the cluster for the first time... (${sample_progress}%)`}</label>}
                    percent={sample_progress}
                    status="active"
                    strokeWidth={6}
                    strokeColor="var(--primary-color)"
                    trailColor="#e1dffa"
                    style={{ display: 'flex', flexDirection: 'column-reverse', lineHeight: '12px' }}
                />
            )}
            {(type === 'ready' || type === 'initial') && (
                <span>
                    {type === 'ready' ? (
                        <IoIosCheckmark fontSize="20px" color="var(--ready-text-color)" />
                    ) : (
                        <TooltipComponent text="Initial analysis">
                            <IoIosInformationCircleOutline fontSize="14px" color="var(--badge-warning)" />
                        </TooltipComponent>
                    )}
                    <label>{calculateETA(eta)}</label>
                </span>
            )}
        </div>
    );
};

export default StatusBadge;
