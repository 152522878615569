import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    GoogleAuthProvider,
    signInWithEmailAndPassword,
    signInWithRedirect,
    createUserWithEmailAndPassword,
    setPersistence,
    browserSessionPersistence,
    browserLocalPersistence,
    signInWithPopup
} from 'firebase/auth';
import { auth } from 'services/firebase';
import Heading from 'components/heading';
import SocialLogin from 'components/socialLogin';
import Divider from 'components/divider';
import Input from 'components/input';
import Button from 'components/button';
import { ReactComponent as GoogleIcon } from 'assets/images/google.svg';
import { RxLockClosed } from 'react-icons/rx';
import { Checkbox, Form } from 'antd';
import FormServerMessage from 'components/formServerMessage';
import { useLocation } from 'react-router-dom';
import { SESSION_STORAGE_ERROR_MESSAGE, SESSION_STORAGE_LOGIN_PROVIDER } from 'utils/localStorageConsts';
import { apiEndpoints } from 'services/apiEndpoints';
import axios from 'axios';
import errorMessages from 'utils/firebaseMessages';
import { useAuth0 } from '@auth0/auth0-react';

const SignInForm = () => {
    const navigate = useNavigate();
    const providerGoogle = new GoogleAuthProvider();
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(sessionStorage.getItem(SESSION_STORAGE_ERROR_MESSAGE) || '');
    const [isFormValid, setIsFormValid] = useState(false);
    const [form] = Form.useForm();
    const location = useLocation();
    const [step, setStep] = useState(1);
    const [currentForm, setCurrentForm] = useState('initial');
    const [rememberMe, setRememberMe] = useState(false);

    providerGoogle.setCustomParameters({});

    const { loginWithRedirect } = useAuth0();

    const formDetails = {
        initial: {
            title: 'Hi there!',
            socialLoginTitle: 'Continue with Google',
            buttonText: 'Continue'
        },
        signIn: {
            title: 'Welcome back!',
            socialLoginTitle: 'Sign in with Google',
            buttonText: 'Sign In'
        },
        signUp: {
            title: 'Setup your account!',
            socialLoginTitle: 'Sign up with Google',
            buttonText: 'Sign Up'
        }
    };

    const getQueryParam = (paramName) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        return urlSearchParams.get(paramName);
    };

    useEffect(() => {
        const errorMessage = getQueryParam('errorMessage');
        if (errorMessage) {
            setErrorMessage(errorMessage);
        }
    }, [location]);

    const onFinish = async (values) => {
        const { email, password } = values;
        setLoading(true);

        if (rememberMe) {
            await setPersistence(auth, browserLocalPersistence);
        } else {
            await setPersistence(auth, browserSessionPersistence);
        }

        if (step === 1) {
            axios
                .post(process.env.REACT_APP_BASE_API + apiEndpoints.CHECK_EMAIL, { email })
                .then((res) => {
                    if (res.status === 200) setCurrentForm('signIn');
                    if (res.status === 201) setCurrentForm('signUp');
                    setStep(2);
                    setErrorMessage('');
                })
                .catch((error) => {
                    setErrorMessage(error?.response?.data?.message);
                })
                .finally(() => {
                    setLoading(false);
                });
        } else if (step === 2) {
            try {
                if (currentForm === 'signIn') {
                    await signInWithEmailAndPassword(auth, email, password);
                } else if (currentForm === 'signUp') {
                    await createUserWithEmailAndPassword(auth, email, password);
                }
                setErrorMessage('');
                navigate('/');
            } catch (error) {
                setErrorMessage(errorMessages(error.code));
            } finally {
                setLoading(false);
            }
        }
    };

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const allFieldsNotEmpty = allFields.every((field) => field.value);
        setIsFormValid(allFieldsNotEmpty && errors.filter(({ errors }) => errors.length).length === 0);
    };

    return (
        <div className="ms-form-inner">
            <div className="header">
                <Heading fontWeight={600} children={formDetails[currentForm]?.title} desc={step === 3 && formDetails[currentForm]?.ssoDescription}></Heading>
            </div>
            <div className="social-login">
                <SocialLogin
                    icon={GoogleIcon}
                    title={formDetails[currentForm]?.socialLoginTitle}
                    onClick={() => {
                        sessionStorage.setItem(SESSION_STORAGE_LOGIN_PROVIDER, 'Google');

                        signInWithPopup(auth, providerGoogle);
                    }}
                />
                <SocialLogin
                    icon={RxLockClosed}
                    iconStyle={{ width: '18px', height: '18px' }}
                    title={'Continue with SSO'}
                    onClick={() => {
                        sessionStorage.setItem(SESSION_STORAGE_LOGIN_PROVIDER, 'SSO');
                        loginWithRedirect();
                    }}
                />
            </div>
            <Divider />
            <Form className="form" name="sign-in" onFinish={onFinish} autoComplete="off" form={form} onFieldsChange={onFieldsChange}>
                <div className="email input">
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email!'
                            },
                            {
                                type: 'email',
                                message: 'Please enter a valid email address!'
                            }
                        ]}
                    >
                        <Input type={'email'} label="Email" placeholder="Your email" customClass="rounded" disabled={step === 2} />
                    </Form.Item>
                </div>

                {step === 2 && (
                    <>
                        <div className="password">
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please input your password!'
                                    }
                                ]}
                            >
                                <Input label="Password" type={'password'} placeholder="*********" customClass="rounded" />
                            </Form.Item>
                        </div>

                        <div className="forget-password-wrapper">
                            <div className="ms-checkbox single-line">
                                <Checkbox checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)}>
                                    Remember me
                                </Checkbox>
                            </div>
                            {currentForm === 'signIn' && (
                                <div className="forget-password">
                                    <a
                                        href="#"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            navigate('/forget-password');
                                        }}
                                    >
                                        Forgot password?
                                    </a>
                                </div>
                            )}
                        </div>
                    </>
                )}

                <div className="buttons-container">
                    <Button
                        htmlType="submit"
                        customClassName="btn-primary"
                        loading={loading}
                        placeholder={formDetails[currentForm]?.buttonText}
                        disabled={!isFormValid}
                    />
                </div>

                <FormServerMessage text={errorMessage} />
            </Form>
        </div>
    );
};

export default SignInForm;
