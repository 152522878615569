import './style.scss';
import React, { forwardRef } from 'react';
import { Button as AntButton } from 'antd';

const Button = forwardRef(({ style, customClassName, typeOfButton, shape, onClick, placeholder, disabled, loading, htmlType, icon, size }, ref) => {
    return (
        <AntButton
            ref={ref}
            className={customClassName}
            style={style}
            type={typeOfButton}
            shape={shape}
            onClick={onClick}
            disabled={disabled}
            loading={loading}
            htmlType={htmlType}
            icon={icon}
            size={size}
        >
            {placeholder}
        </AntButton>
    );
});

export default Button;
