//local storage
export const LOCAL_STORAGE_DARK_MODE = 'dark_mode';
export const LOCAL_STORAGE_JWT = 'jwt';
export const LOCAL_STORAGE_IS_SIDEBAR_COLLAPSED = 'is_sidebar_collapsed';
export const LOCAL_STORAGE_OPTIMIZATION_SIDEBAR_MENU = 'optimization_sidebar_menu';

//session storage
export const SESSION_STORAGE_USER = 'current_user';
export const SESSION_STORAGE_TOKEN = 'access_token';
export const SESSION_STORAGE_FIREBASE_OOB_CODE = 'firebase_oobCode';
export const SESSION_STORAGE_JWT = 'jwt';
export const SESSION_STORAGE_ACTIVATION_TOKEN = 'activation_token';
export const SESSION_STORAGE_ACCOUNT_ID = 'account_id';
export const SESSION_STORAGE_ACCOUNT_DATA = 'account_data';
export const SESSION_STORAGE_USER_UNDER_ASSESSMENT = 'user_under_assessment';

export const SESSION_STORAGE_LOGIN_PROVIDER = 'login_provider';
export const SESSION_STORAGE_ERROR_MESSAGE = 'error_message';
