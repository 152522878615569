import '../style.scss';
import React, { useState, useContext, useEffect } from 'react';
import { Context } from 'hooks/store';
import Modal from 'components/modal';
import Button from 'components/button';
import ConnectorModalHeader from 'components/Modals/ConnectorModal/connectorModalComponents/modalHeader';
import { Checkbox } from 'antd';
import { getIcon } from 'services/genericServices';

const ApplyClustersModal = ({ icon, isModalOpen, closeModal, applyConfiguration, loading, connection }) => {
    const [state, dispatch] = useContext(Context);
    const [connectionsList, setConnections] = useState([]);

    useEffect(() => {
        setConnections([Number(connection)]);
    }, [connection]);

    const updateConnectionList = (id) => {
        if (connectionsList?.includes(id)) {
            setConnections(connectionsList?.filter((item) => item !== id));
        } else {
            setConnections([...connectionsList, id]);
        }
    };

    return (
        <Modal isModalOpen={isModalOpen} clickOutside={() => closeModal()}>
            <div className="apply-clusters-modal">
                <div className="ms-modal-header">
                    <ConnectorModalHeader title="Apply to other clusters" icon={<div className="icon-config">{icon}</div>} />
                </div>
                <div className="ms-modal-body">
                    {state?.connections?.map((item) => (
                        <div className="cluster-item" key={item?.id}>
                            <Checkbox
                                id={item?.id}
                                onChange={() => updateConnectionList(item?.id)}
                                checked={connectionsList?.includes(item?.id)}
                                disabled={item?.id === Number(connection)}
                            />
                            <div>{getIcon(item?.type)}</div>
                            <div>{item?.name}</div>
                        </div>
                    ))}
                </div>

                <div className="ms-modal-footer">
                    <Button customClassName="modal-btn modal-btn-secondary" placeholder="Cancel" onClick={() => closeModal()} />
                    <Button customClassName="modal-btn modal-btn-primary" placeholder="Confirm" onClick={() => applyConfiguration(connectionsList)} loading={loading} />
                </div>
            </div>
        </Modal>
    );
};

export default ApplyClustersModal;
