import "./style.scss";
import React from "react";
import AuthLayout from "layouts/authLayout";
import SignInForm from "./Forms/SignInForm";

const Login = () => {

    return (
        <AuthLayout>
            <SignInForm />
        </AuthLayout>
    )
}

export default Login
