import './CustomButtonWrapper.scss';
import React from 'react';
import { IoIosArrowDown } from "react-icons/io";

const CustomButtonWrapper = ({ icon, text, textColor, withArrow = true, badge }) => {

    return (
        <div className="custom-button-buttonsWrapper">
            <div className="left dark-icon">
                {icon}
                <div className="left-text" style={{color: textColor}}>
                    {text}
                    {withArrow &&
                        <IoIosArrowDown color="#979797" />
                    }
                </div>
            </div>
            {badge &&
                <div className="right">
                    <div className="right-badge">
                        {badge}
                    </div>
                </div>
            }
        </div>
    );
};

export default CustomButtonWrapper;
