import "./style.scss";
import { ReactComponent as AvatarIcon } from "assets/images/tmp/avatar2.svg";

const UserItem = ({ icon, username, email }) => {
    return (
        <div className="user-select">
            <div className="user-select-left">
                {icon ? <img src={icon} height={32} width={32} alt="profile-picture" referrerPolicy="no-referrer" /> : <AvatarIcon/>}
            </div>
            <div className="user-select-right">
                <div className="user-select-title">
                    {username}
                </div>
                <div className="user-select-subtitle">
                    {email}
                </div>
            </div>
        </div>
    );
}

export default UserItem;