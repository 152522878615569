import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from 'react';
import Badge from '../badge';
import ListSubItem from '../listItem/subTask';
import Drawer from '../drawer';
import './style.scss';
import { Spin } from 'antd';
import Tooltip from '../tooltip/tooltip';
import Button from '../button';
import { TbHexagonMinus } from 'react-icons/tb';
import { IoMdCheckboxOutline } from 'react-icons/io';
import { AnimatePresence, motion } from 'framer-motion';
import ConfirmationModal from './components/confirmationModal';
import { FaPlay } from 'react-icons/fa';
import { uuidv4 } from 'services/valueConvertor';
import { apiEndpoints } from 'services/apiEndpoints';
import { useHttpRequest } from 'services/http';
import { convertStorage } from 'services/genericServices';
import EmptyTasksPlaceholder from './components/emptyTasksPlaceholder';
import TodoListItemContainer from './components/todo-list';
import { SESSION_STORAGE_USER_UNDER_ASSESSMENT } from 'utils/localStorageConsts';
import { ReactComponent as LimitedIcon } from 'assets/images/optimization/limited.svg';
import RefreshButton from 'components/refreshButton';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

const modalContent = {
    markAsIgnored: {
        title: 'Ignore',
        desc: 'We would love to understand why you chose to ignore this task. It helps us to be more accurate for your business logic.',
        placeholder: 'I ignored this one because...'
    },
    markAsCompleted: {
        title: 'Mark as fixed',
        desc: 'We would love to understand why you chose to fix it yourself instead of using Superstream.',
        placeholder: 'I fixed it myself because...'
    },
    fix: {
        title: 'Fix now',
        desc: 'You are about to fix this task. Are you sure you want to proceed?',
        placeholder: 'Type "confirm"'
    }
};

const TodoList = forwardRef(({ maxHeight = '100%', height, perPage = 10, connection_id, doneRefresh, showTitle = true, isTasksPage }, ref) => {
    const httpRequest = useHttpRequest();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [todos, setTodos] = useState([]);
    const [status, setStatus] = useState('available');
    const [isFetching, setIsFetching] = useState(true);
    const [selectedItems, setSelectedItems] = useState([]);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [modalType, setModalType] = useState('markAsIgnored');
    const navigate = useNavigate();
    const [pendingTask, setPendingTask] = useState({});
    const [modalSubmitLoading, setModalSubmitLoading] = useState(false);
    const [taskReasonForStatusChange, setTaskReasonForStatusChange] = useState('');
    const [tasksDetails, setTasksDetails] = useState({});
    const [tasks, setTasks] = useState([]);
    const [initialTasks, setInitialTasks] = useState([]);
    const itemsPerScroll = 50;
    const [drawerWidth, setDrawerWidth] = useState(600);
    const [connectionId, setConnectionId] = useState(connection_id);
    const [isSubTask, setIsSubTask] = useState(false);
    const [loadingItemId, setLoadingItemId] = useState(null);
    const [countOfTasks, setCountOfTasks] = useState({
        available: 0,
        in_progress: 0,
        completed: 0
    });
    const isUserUnderAssessment = sessionStorage.getItem(SESSION_STORAGE_USER_UNDER_ASSESSMENT) === 'true';
    const topRef = useRef(null);
    const [topHeight, setTopHeight] = useState(0);

    useEffect(() => {
        if (topRef.current) {
            setTopHeight(topRef.current.offsetHeight + 50);
        }

        const handleResize = () => {
            if (topRef.current) {
                setTopHeight(topRef.current.offsetHeight + 50);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [tasks]);

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            fetchAggregatedTasks();
        }
    }));

    useEffect(() => {
        setIsFetching(true);
        fetchAggregatedTasks().catch();
    }, [status]);

    const getTypeOfBadge = (state) => {
        return state === status ? 'primary' : 'secondary';
    };

    /*const handleSelect = (item, checked) => {
        if (checked) {
            setSelectedItems([...selectedItems, item]);
        } else {
            setSelectedItems(selectedItems.filter((selectedItem) => selectedItem.id !== item.id));
        }
    };*/

    const fetchAggregatedTasks = async () => {
        setIsFetching(true);
        try {
            const queryParams = { connection_id, status };
            const response = await httpRequest('GET', apiEndpoints.TASKS_GET_ALL_GROUPS, {}, {}, queryParams);
            const groups = response?.groups
                ?.map((item) => ({
                    ...item,
                    id: uuidv4()
                }))
                .sort((a, b) => (showTitle || isTasksPage ? (a?.connection_id - b?.connection_id > 0 ? 1 : -1) : a?.title.localeCompare(b?.title)));
            setCountOfTasks({
                available: response?.available,
                in_progress: response?.in_progress,
                completed: response?.completed
            });
            setTodos(groups);
        } catch (error) {
        } finally {
            setIsFetching(false);
            setSelectedItems([]);
            setTaskReasonForStatusChange('');
            connection_id && doneRefresh();
        }
    };

    const markTask = async (details, taskType) => {
        try {
            let endpoint;
            switch (taskType) {
                case 'markAsIgnored':
                    endpoint = apiEndpoints.TASKS_IGNORE;
                    break;
                case 'fix':
                    endpoint = apiEndpoints.TASKS_FIX;
                    break;
                case 'markAsCompleted':
                    endpoint = apiEndpoints.TASKS_MARK_AS_COMPLETE;
                    break;
                default:
                    throw new Error('Invalid task type');
            }
            await httpRequest('POST', endpoint, details);
        } catch (error) {
            console.error(`Failed to mark task(s) as ${taskType}:`, error);
        }
    };

    const triggerConfirmation = (type, item) => {
        setIsSubTask(false);
        setModalType(type);
        setOpenConfirmModal(true);
        setPendingTask(item);
    };

    const handleConfirmationModal = () => {
        setTaskReasonForStatusChange('');
        setModalSubmitLoading(true);
        setLoadingItemId(pendingTask?.id);

        const params = isSubTask
            ? {
                  connection_id: pendingTask?.connection_id,
                  task_id: pendingTask?.id,
                  type: pendingTask?.type
              }
            : {
                  connection_id: pendingTask?.connection_id,
                  reason: taskReasonForStatusChange,
                  type: pendingTask?.task_type
              };

        markTask(params, modalType).finally(() => {
            if (isSubTask) {
                setTasks((prevState) => prevState?.filter((task) => task.id !== pendingTask.id));
            } else {
                pendingTask?.id && setTodos(todos?.filter((todo) => todo.id !== pendingTask.id));
            }
            setModalSubmitLoading(false);
            setOpenConfirmModal(false);
            setLoadingItemId(null);
        });
    };

    const triggerConfirmationForSubTask = (type, item) => {
        setIsSubTask(true);
        setModalType(type);
        setOpenConfirmModal(true);
        setPendingTask(item);
    };

    const fetchByGroupTasks = async (item) => {
        setConnectionId(item?.connection_id);
        setLoadingItemId(item?.id);
        const queryParams = { connection_id: item?.connection_id, task_type: item?.task_type, task_status: status };
        try {
            const response = await httpRequest('GET', apiEndpoints.TASKS_GET_BY_GROUP, {}, {}, queryParams);
            let tasks = response?.tasks?.tasks;

            const metadata = tasks[0]?.metadata;
            const metadataCount = metadata ? Object.keys(metadata).length : 0;
            setDrawerWidth(metadataCount > 1 ? 700 : 600);

            const sortedTasks =
                tasks?.sort((a, b) => {
                    const partitionsDifference = (b?.metadata?.partitions?.value ?? 0) - (a?.metadata?.partitions?.value ?? 0);
                    if (partitionsDifference !== 0) {
                        return partitionsDifference;
                    }
                    return (b?.metadata?.potential_saving_in_mb?.value ?? 0) - (a?.metadata?.potential_saving_in_mb?.value ?? 0);
                }) || [];

            if (response?.tasks) {
                tasks = sortedTasks;
            }

            setTasksDetails(response?.tasks);
            setInitialTasks(tasks);
            const lazyLoadTasks = tasks.slice(0, itemsPerScroll);
            setTasks(lazyLoadTasks);

            setOpenDrawer(true);
        } catch (error) {
        } finally {
            setLoadingItemId(null);
        }
    };

    const handleScroll = (event) => {
        if (tasks.length === initialTasks.length) return;

        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight >= scrollHeight - 200) {
            const nextLoadedItems = initialTasks.slice(0, tasks.length + itemsPerScroll);
            setTasks(nextLoadedItems);
        }
    };

    return (
        <div className="todo-list">
            {showTitle && <div className="todo-list-title">To-Do List</div>}
            <div className="card">
                <div className="card-top">
                    <div className="left">
                        {!isFetching && (
                            <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                                <Badge
                                    text={'Available' + (countOfTasks.available > 0 ? ` (${countOfTasks.available})` : '')}
                                    type={getTypeOfBadge('available')}
                                    onClick={() => {
                                        if (status === 'available') return;
                                        setIsFetching(true);
                                        setStatus('available');
                                    }}
                                />
                                <Badge
                                    text={'In progress' + (countOfTasks.in_progress > 0 ? ` (${countOfTasks.in_progress})` : '')}
                                    type={getTypeOfBadge('in_progress')}
                                    onClick={() => {
                                        if (status === 'in_progress') return;
                                        setIsFetching(true);
                                        setStatus('in_progress');
                                    }}
                                />
                                <Badge
                                    text={'Completed' + (countOfTasks.completed > 0 ? ` (${countOfTasks.completed})` : '')}
                                    type={getTypeOfBadge('completed')}
                                    onClick={() => {
                                        if (status === 'completed') return;
                                        setIsFetching(true);
                                        setStatus('completed');
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className="right">
                        {/*<div className="progress">
                        <p><b>{progress.done}</b>/{progress.total}</p>
                        <Progress
                            percent={progress.in_progress_percentage}
                            size="small"
                            strokeColor="var(--primary-color)"
                            showInfo={false}
                        />
                        <p>{progress.in_progress_percentage}% Completed</p>
                    </div>*/}
                        {selectedItems.length > 0 && (
                            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                {!['in_progress', 'completed'].includes(status) && (
                                    <>
                                        <Tooltip text={'Mark as ignored'} placement={'top'}>
                                            <Button shape="circle" placeholder={<TbHexagonMinus color={'var(--text-color)'} />} onClick={() => markTask({}, 'ignore')} />
                                        </Tooltip>
                                        <Tooltip text={'Mark as fixed'} placement={'top'}>
                                            <Button
                                                shape="circle"
                                                placeholder={<IoMdCheckboxOutline color={'var(--text-color)'} />}
                                                onClick={() => markTask({}, 'fix')}
                                            />
                                        </Tooltip>
                                        <Tooltip text={'Fix'} placement={'top'}>
                                            <Button shape="circle" placeholder={<FaPlay color={'var(--primary-color)'} />} onClick={() => markTask({}, 'complete')} />
                                        </Tooltip>
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <div className="card-body" style={{ height: height }}>
                    <Spin spinning={isFetching} delay={300}>
                        <AnimatePresence initial={false}>
                            {todos?.length === 0 && !isFetching && <EmptyTasksPlaceholder status={status} />}
                            {todos?.map((todo, index) => {
                                return (
                                    <TodoListItemContainer
                                        displayCluser={(showTitle || isTasksPage) && (index === 0 || todo?.connection_id !== todos[index - 1]?.connection_id)}
                                        key={todo.id}
                                        todo={todo}
                                        fetchByGroupTasks={fetchByGroupTasks}
                                        loadingItemId={loadingItemId}
                                        status={status}
                                        triggerConfirmation={triggerConfirmation}
                                    />
                                );
                            })}
                        </AnimatePresence>
                    </Spin>
                </div>
            </div>

            <ConfirmationModal
                openModal={openConfirmModal}
                setOpenModal={setOpenConfirmModal}
                type={modalType}
                title={modalContent[modalType].title}
                desc={modalContent[modalType].desc}
                placeholder={modalContent[modalType].placeholder}
                onSubmit={handleConfirmationModal}
                loading={modalSubmitLoading}
                setTaskReasonForStatusChange={setTaskReasonForStatusChange}
            />

            <Drawer
                open={openDrawer}
                destroyOnClose={true}
                onClose={() => setOpenDrawer(false)}
                className="todo-list-drawer-wrapper"
                styles={{ header: { display: 'none' } }}
                width={drawerWidth}
            >
                <div className="todo-list-drawer-top" ref={topRef}>
                    <>
                        <div className="title-wrapper">
                            <div className="title">{tasksDetails?.title}</div>
                            <div style={{ alignSelf: 'flex-start' }}>
                                <Button
                                    onClick={() => navigate(`/connected-clusters/${connectionId}?tab=configuration`)}
                                    placeholder={
                                        <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                                            <HiOutlineCog6Tooth />
                                            <div>Configuration</div>
                                        </div>
                                    }
                                    customClassName={'rounded'}
                                />
                            </div>
                        </div>
                        {selectedItems.length > 0 && (
                            <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                                <Tooltip text={'Mark as ignored'} placement={'top'}>
                                    <Button shape="circle" placeholder={<TbHexagonMinus color={'var(--text-color)'} />} onClick={() => markTask({}, 'ignore')} />
                                </Tooltip>
                                <Tooltip text={'Mark as fixed'} placement={'top'}>
                                    <Button shape="circle" placeholder={<IoMdCheckboxOutline color={'var(--text-color)'} />} onClick={() => markTask({}, 'fix')} />
                                </Tooltip>
                                <Tooltip text={'Fix'} placement={'top'}>
                                    <Button shape="circle" placeholder={<FaPlay color={'var(--primary-color)'} />} onClick={() => markTask({}, 'complete')} />
                                </Tooltip>
                            </div>
                        )}
                    </>

                    {status === 'available' &&
                        (tasksDetails?.total_partitions_reduction > 0 ||
                            tasksDetails?.total_write_reduction_bytes > 0 ||
                            tasksDetails?.total_storage_reduction_bytes > 0 ||
                            tasksDetails?.total_read_reduction_bytes > 0) && (
                            <div className="saving-section">
                                <p className="savings">
                                    {tasks?.length > 0 && tasks[0]?.type === 'remove_inactive_topic' ? 'You are going to save:' : 'You could have saved so far:'}
                                </p>
                                <div className="badges">
                                    {tasksDetails?.total_partitions_reduction > 0 && (
                                        <Badge type="cost" text={`${tasksDetails?.total_partitions_reduction?.toLocaleString()} Partitions`} />
                                    )}
                                    {tasksDetails?.total_storage_reduction_bytes > 0 && (
                                        <Badge type="cost" text={`${convertStorage(tasksDetails?.total_storage_reduction_bytes)} of Storage`} />
                                    )}
                                    {tasksDetails?.total_read_reduction_bytes > 0 && (
                                        <Badge type="cost" text={`${convertStorage(tasksDetails?.total_read_reduction_bytes)} of Reading`} />
                                    )}
                                    {tasksDetails?.total_write_reduction_bytes > 0 && (
                                        <Badge type="cost" text={`${convertStorage(tasksDetails?.total_write_reduction_bytes)} of Writing`} />
                                    )}
                                </div>
                            </div>
                        )}

                    <div className="content">
                        {tasksDetails?.description}

                        <br />
                        {tasks?.the_fix && (
                            <>
                                <h3
                                    style={{
                                        marginBottom: 6,
                                        color: 'var(--text-color)',
                                        fontWeight: 500
                                    }}
                                >
                                    Fixes
                                </h3>
                                {tasksDetails?.the_fix}
                            </>
                        )}
                        {tasksDetails?.tha_fix && (
                            <>
                                <h3
                                    style={{
                                        marginBottom: 6,
                                        color: 'var(--text-color)',
                                        fontWeight: 500
                                    }}
                                >
                                    How Superstream will fix the issue
                                </h3>
                                <div style={{ whiteSpace: 'pre-wrap' }}>{tasksDetails?.tha_fix}</div>
                            </>
                        )}
                        {tasksDetails?.risk && (
                            <>
                                <h3
                                    style={{
                                        marginBottom: 6,
                                        color: 'var(--text-color)',
                                        fontWeight: 500
                                    }}
                                >
                                    Risk management
                                </h3>
                                <div style={{ whiteSpace: 'pre-wrap' }}>{tasksDetails?.risk}</div>
                            </>
                        )}
                    </div>
                </div>
                <div className="todo-list-drawer-items" onScroll={handleScroll} style={{ maxHeight: `calc(100vh - ${topHeight}px)` }}>
                    <AnimatePresence initial={false}>
                        {tasks?.map((item, index) => (
                            <motion.div key={item.id} animate={{ opacity: 1, x: 0 }} transition={{ duration: 0.5 }} layout>
                                <ListSubItem
                                    loadingItemId={loadingItemId}
                                    item={item}
                                    triggerConfirmationForSubTask={triggerConfirmationForSubTask}
                                    fixable={tasksDetails?.fixable}
                                />
                            </motion.div>
                        ))}
                        {isUserUnderAssessment && (
                            <div className="todo-list-poc-wrapper">
                                <ListSubItem
                                    loadingItemId={loadingItemId}
                                    item={tasks?.[0]}
                                    triggerConfirmationForSubTask={triggerConfirmationForSubTask}
                                    fixable={tasks?.[0]?.fixable}
                                />
                                <div className="todo-list-poc">
                                    <LimitedIcon style={{ width: 25, height: 25 }} />
                                    <div className="todo-list-poc-title">The rest of the view is currently locked</div>
                                </div>
                            </div>
                        )}
                    </AnimatePresence>
                </div>
            </Drawer>
        </div>
    );
});

export default TodoList;
