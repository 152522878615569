import './style.scss';
import { FiPlus } from 'react-icons/fi';
import Heading from 'components/heading';
import Button from 'components/button';

const AddConnectorPlaceholder = ({ img, text, btnText, onClick }) => {
    return (
        <div className="add-connector-placeholder">
            <div className="add-connector-placeholder-img">{img}</div>
            <div className="add-connector-placeholder-middle">
                <Heading fontWeight="600" fontSize="24px" color="var(--placeholder-color)">
                    {text}
                </Heading>
            </div>
            <div className="add-connector-placeholder-bottom">
                <Button
                    onClick={onClick}
                    typeOfButton="text"
                    placeholder={
                        <div className="add-connector-placeholder-bottom-button">
                            <FiPlus color="var(--text-color)" fontSize={16} />
                            {btnText}
                        </div>
                    }
                />
            </div>
        </div>
    );
};

export default AddConnectorPlaceholder;
