export const validatePassword = (_, value) => {
    if (!value) {
        return Promise.reject('Please enter your password!');
    }
    if (value.length < 8) {
        return Promise.reject('Password must be at least 8 characters long!');
    }
    if (!/[A-Z]/.test(value)) {
        return Promise.reject('Password must contain at least one uppercase letter!');
    }
    if (!/[a-z]/.test(value)) {
        return Promise.reject('Password must contain at least one lowercase letter!');
    }
    if (!/\d/.test(value)) {
        return Promise.reject('Password must contain at least one number!');
    }
    if (!/[!?<>@#$%]/.test(value)) {
        return Promise.reject('Password must contain at least one special character!');
    }
    return Promise.resolve();
};