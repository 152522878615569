import Dagre from '@dagrejs/dagre';

const formattedData = (listOfDataPlanes, activeClients, setDataPlanModal, setIsModalOpen, handleClickOnClientNode, handleOrphanClients) => {
    let nodes = [];
    let edges = [];

    nodes.push({ id: 'superstream', type: 'superstream', data: { label: 'Superstream' } });
    nodes.push({
        id: 'addDataPlan',
        type: 'addDataPlan',
        data: {
            label: '+ New Engine',
            onClick: (e) => {
                e.stopPropagation();
                setDataPlanModal(true);
            }
        }
    });
    edges.push({
        source: 'superstream',
        target: 'addDataPlan',
        style: { stroke: '#5A4FE5', strokeWidth: 2 },
        animated: true
    });

    const clientMap = new Map();
    activeClients.forEach((client) => {
        const clients = clientMap.get(client.connection_id) || [];
        clients.push(client);
        clientMap.set(client.connection_id, clients);
    });

    listOfDataPlanes.map((dataPlan, dataPlanIndex) => {
        const connections = dataPlan?.connections;
        if (dataPlanIndex === 0) {
            nodes.push({
                id: 'addDataPlan',
                type: 'addDataPlan',
                data: {
                    label: '+ New Engine',
                    onClick: (e) => {
                        e.stopPropagation();
                        setDataPlanModal(true);
                    }
                }
            });
            edges.push({
                source: 'superstream',
                target: 'addDataPlan',
                style: { stroke: '#5A4FE5', strokeWidth: 2 },
                animated: true
            });
        }
        nodes.push({
            id: 'dataPlane-' + dataPlan.id,
            type: 'dataPlane',
            target: 'addConnector-0',
            data: {
                label: dataPlan.env_name,
                connections: connections?.length
            }
        });
        edges.push({
            source: 'superstream',
            target: 'dataPlane-' + dataPlan.id,
            style: { stroke: '#5A4FE5', strokeWidth: 2 },
            animated: false
        });

        if (connections?.length === 0) {
            nodes.push({
                id: 'addConnector-0',
                type: 'addConnector',
                data: {
                    label: '+ Connect a new cluster',
                    onClick: (e) => {
                        e.stopPropagation();
                        setIsModalOpen(true);
                    }
                }
            });
            edges.push({
                source: 'dataPlane-' + dataPlan.id,
                target: 'addConnector-0',
                style: { stroke: '#5A4FE5', strokeWidth: 2 },
                animated: true
            });
        }

        connections?.forEach((connection, connectionIndex) => {
            const clients = clientMap.get(connection.id) || [];
            if (connectionIndex === 0) {
                nodes.push({
                    id: 'addConnector-' + connection.id,
                    type: 'addConnector',
                    data: {
                        label: '+ Connect a new cluster',
                        onClick: (e) => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                        }
                    }
                });
                edges.push({
                    source: 'dataPlane-' + dataPlan.id,
                    target: 'addConnector-' + connection.id,
                    style: { stroke: '#5A4FE5', strokeWidth: 2 },
                    animated: true
                });
            }

            nodes.push({
                id: 'kafka-' + connection.id,
                type: 'kafka',
                data: {
                    label: connection.name,
                    connections: clients?.length
                }
            });
            edges.push({
                source: 'dataPlane-' + dataPlan.id,
                target: 'kafka-' + connection.id,
                style: { stroke: '#5A4FE5', strokeWidth: 2 },
                animated: false
            });

            if (clients?.length > 0) {
                nodes.push({
                    id: 'total-clients-' + connection.id,
                    type: 'app',
                    data: {
                        label: clients?.length + ' clients',
                        orphanClients: false,
                        onClick: (e) => {
                            e.stopPropagation();
                            handleClickOnClientNode(connection.id);
                        }
                    }
                });
                edges.push({
                    source: 'kafka-' + connection.id,
                    target: 'total-clients-' + connection.id,
                    style: { stroke: '#5A4FE5', strokeWidth: 2 },
                    animated: false
                });
            }
        });
    });
    const orphanClients = activeClients?.filter((client) => !client.connection_id);
    if (orphanClients?.length > 0) {
        nodes.push({
            id: 'total-orphan-clients',
            type: 'app',
            data: {
                label: orphanClients?.length + ' clients',
                orphanClients: true,
                onClick: (e) => {
                    e.stopPropagation();
                    handleOrphanClients(orphanClients);
                }
            }
        });
    }

    const nodesMap = new Map(nodes.map((node) => [node.id, { ...node, children: [] }]));
    edges.forEach((edge) => {
        nodesMap.get(edge.source).children.push(nodesMap.get(edge.target));
    });

    const g = new Dagre.graphlib.Graph().setDefaultEdgeLabel(() => ({}));

    const getLayoutedElements = () => {
        g.setGraph({
            rankdir: 'LR',
            nodesep: 200,
            edgesep: 100,
            ranksep: 350,
            ranker: 'tight-tree',
            align: 'DR'
        });

        edges.forEach((edge) => g.setEdge(edge.source, edge.target));
        nodes.forEach((node) => g.setNode(node.id, node));

        Dagre.layout(g);

        return {
            nodes: nodes.map((node) => {
                const { x, y } = g.node(node.id);

                return { ...node, position: { x, y } };
            }),
            edges
        };
    };

    return { nodes: getLayoutedElements().nodes, edges: getLayoutedElements().edges };
};

export default formattedData;
