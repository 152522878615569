import './style.scss';
import Table from 'components/table';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useHttpRequest } from 'services/http';
import { apiEndpoints } from 'services/apiEndpoints';
import { BiLogoGoLang, BiLogoPython, BiError } from 'react-icons/bi';
import { FaJava } from 'react-icons/fa';
import { SiDotnet } from 'react-icons/si';
import { Switch } from 'antd';
import Modal from 'components/modal';
import DeleteItemsModal from 'components/deleteItemsModal';
import Badge from './components/badge';
import Drawer from 'components/drawer';
import { ReactComponent as ClientsIcon } from 'assets/images/sidebar/client.svg';
import { RxArrowTopRight } from 'react-icons/rx';
import Button from 'components/button';
import Loader from 'components/loader';
import ClientLogs from './components/clientLogs';
import TooltipComponent from 'components/tooltip/tooltip';
import useTableDynamicPageSize from 'hooks/useTableDynamicPageSize';
import useResizableColumns from 'components/table/useResizableColumns';
import { getIconByLang } from 'services/genericServices';

const Clients = forwardRef(({ connection, doneRefresh }, ref) => {
    const httpRequest = useHttpRequest();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [initialData, setInitialData] = useState([]);
    const [loadingReductionSwitch, setLoadingReductionSwitch] = useState({});
    const [loadingCompressionSwitch, setLoadingCompressionSwitch] = useState({});
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [selectedModal, setSelectedModal] = useState('reduction');
    const [selectedFilters, setSelectedFilters] = useState({
        listOfDataPlanes: [],
        listOfClusters: [],
        type: []
    });
    const [selectedConnectionID, setSelectedConnectionID] = useState(null);
    const [filterState, setFilterState] = useState({});
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedClient, setSelectedClient] = useState({});
    const [selectedClientReduction, setSelectedClientReduction] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    const [logModalOpen, flipLogModal] = useState(false);
    const itemsPerScroll = 100;
    const [loadedItems, setLoadedItems] = useState([]);

    useImperativeHandle(ref, () => ({
        handleRefresh() {
            fetchData().catch();
        }
    }));

    const modalContent = {
        reduction: {
            title: 'Payload reduction',
            desc: 'Payload reduction will efficiently transform JSON payloads into binary format, decreasing their size by as much as 50%.'
        }
        // compression: {
        //     title: 'Payload compression',
        //     desc: 'Payload compression will efficiently transform JSON payloads into binary format, decreasing their size by as much as 75%.'
        // }
    };

    useEffect(() => {
        const pathTokens = decodeURI(location.pathname)?.split('/');
        const connectionId = pathTokens[pathTokens?.length - 1];
        setSelectedConnectionID(connectionId);
    }, [connection, location.pathname]);

    useEffect(() => {
        setLoadedItems(data?.slice(0, loadedItems.length + itemsPerScroll));
    }, [data]);

    useEffect(() => {
        fetchData().catch();
    }, [selectedConnectionID]);

    useEffect(() => {
        handleSelectedFilters(false);
    }, [searchParams, initialData]);

    useEffect(() => {
        const engines = searchParams.getAll('engine');
        const clusters = searchParams.getAll('connection');
        const types = searchParams.getAll('type');

        const params = {
            listOfDataPlanes: engines.length > 0 ? engines : [],
            listOfClusters: clusters.length > 0 ? clusters : [],
            type: types.length > 0 ? types : []
        };

        setSelectedFilters(params);
        setFilterState(params);
    }, [searchParams]);

    const fetchData = async () => {
        if (!selectedConnectionID) return;
        setLoading(true);
        try {
            const response = await httpRequest('GET', apiEndpoints.CLIENTS_GET_ALL, {}, {}, selectedConnectionID ? { id: selectedConnectionID } : {});
            setInitialData(response);
            setData(response);
            //const filterData = await httpRequest('GET', apiEndpoints.GET_GRAPH_DATA);
            //setInitialData(response);
            //setFilterData(filterData);
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
            doneRefresh();
        }
    };

    const onReductionChanged = async (item, toggle) => {
        setLoadingReductionSwitch((prev) => ({ ...prev, [item?.id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.CLIENTS_TOGGLE_REDUCTION, {
                all_clients: false,
                enable_reduction: toggle,
                client_ids: [item?.id]
            }).then(async () => {
                const updatedClients = data.map((client) => {
                    if (client.id === item?.id) {
                        return { ...client, reduction_enabled: !client.reduction_enabled };
                    }
                    return client;
                });
                setData(updatedClients);
            });
        } catch (error) {
            await fetchData();
            setLoadingReductionSwitch((prev) => ({ ...prev, [item?.id]: false }));
        } finally {
            setLoadingReductionSwitch((prev) => ({ ...prev, [item?.id]: false }));
            setConfirmationModalOpen(false);
            setDrawerOpen(false);
        }
    };

    const onCompressionChanged = async (item, toggle) => {
        setLoadingCompressionSwitch((prev) => ({ ...prev, [item?.id]: true }));
        try {
            await httpRequest('POST', apiEndpoints.CLIENTS_TOGGLE_REDUCTION, {
                all_clients: false,
                enable_reduction: toggle,
                client_ids: [item?.id]
            }).then(async () => {
                const updatedClients = data.map((client) => {
                    if (client.id === item?.id) {
                        return { ...client, compression_enabled: !client.compression_enabled };
                    }
                    return client;
                });
                setData(updatedClients);
            });
        } catch (error) {
            await fetchData();
            setLoadingCompressionSwitch((prev) => ({ ...prev, [item?.id]: false }));
        } finally {
            setLoadingCompressionSwitch((prev) => ({ ...prev, [item?.id]: false }));
            setConfirmationModalOpen(false);
        }
    };

    function countKeys(obj) {
        return Object.keys(obj || {}).length;
    }

    const columns = [
        {
            title: 'ID',
            key: 'id',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', gap: 8, alignItems: 'center' }}>
                        {getIconByLang(record?.language)}
                        <div>{record?.id}</div>
                        {record?.errors && (
                            <TooltipComponent
                                text={
                                    <div className="client-errors-tooltip" onClick={(e) => e.stopPropagation()}>
                                        <p>This client contains errors.</p>
                                        <Button
                                            placeholder="Display activity logs"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                flipLogModal(true);
                                            }}
                                        />
                                    </div>
                                }
                            >
                                <BiError color="var(--failed-color)" />
                            </TooltipComponent>
                        )}
                    </div>
                );
            },
            sorter: (a, b) => a.id - b.id,
            width: 50
        },
        {
            title: 'Type',
            key: 'type',
            render: (text, record) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center', gap: 8 }}>
                        {record?.is_consumer && <Badge type="consumer">Consumer</Badge>}
                        {record?.is_producer && <Badge type="producer">Producer</Badge>}
                        {!record?.is_consumer && !record?.is_producer && <Badge type="admin">Admin</Badge>}
                    </div>
                );
            },
            sorter: (a, b) => {
                const getTypeValue = (record) => {
                    if (record.is_consumer) return 'consumer';
                    if (record.is_producer) return 'producer';
                    return 'none';
                };

                const aValue = getTypeValue(a);
                const bValue = getTypeValue(b);
                return aValue.localeCompare(bValue);
            },
            width: 100
        },
        {
            title: 'Topics',
            key: 'topics',
            render: (text, record) => {
                const producerTopicCount = record?.producer_topics_partitions ? countKeys(record.producer_topics_partitions) : 0;
                const consumerTopicCount = record?.consumer_group_topics_partitions ? countKeys(record.consumer_group_topics_partitions) : 0;

                const producerTopics = record?.producer_topics_partitions
                    ? Object.keys(record.producer_topics_partitions).map((item, index) => (
                          <div key={`producer-topic-${record.id}-${index}`} className="text-ellipse">
                              {item}
                          </div>
                      ))
                    : [];

                const consumerTopics = record?.consumer_group_topics_partitions
                    ? Object.keys(record.consumer_group_topics_partitions).map((item, index) => (
                          <div key={`consumer-topic-${record.id}-${index}`} className="text-ellipse">
                              {item}
                          </div>
                      ))
                    : [];

                if (producerTopicCount > 1 || consumerTopicCount > 1) {
                    return (
                        <Button
                            customClassName="client-topics-button"
                            placeholder={
                                <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                                    {`${producerTopicCount + consumerTopicCount} connected topics`} <RxArrowTopRight />
                                </div>
                            }
                            onClick={() => openDrawer(record)}
                        />
                    );
                } else if (producerTopicCount > 0 || consumerTopicCount > 0) {
                    return (
                        <div style={{ display: 'flex', gap: 4 }}>
                            <span>{producerTopics}</span>
                            <span>{consumerTopics}</span>
                        </div>
                    );
                } else {
                    return '-';
                }
            },
            width: 200,
            sorter: (a, b) => {
                const aTopics = countKeys(a.producer_topics_partitions) + countKeys(a.consumer_group_topics_partitions);
                const bTopics = countKeys(b.producer_topics_partitions) + countKeys(b.consumer_group_topics_partitions);
                return aTopics - bTopics;
            },
            defaultSortOrder: 'descend'
        },
        {
            title: 'Tags',
            key: 'tags',
            render: (text, record) => getFormattedTags(record),
            width: 200
        }
    ];

    const getFormattedTags = (record) => {
        const tags = record?.tags && record?.tags.length > 0 ? record?.tags : [];
        const tagsWrap = tags.map((tag, index) => {
            return (
                <TooltipComponent key={`tag-${tag}-${index}`} disabled={tag?.length < 50} text={tag}>
                    <Badge type="light-gray" eclipse={tag?.length > 50} index={index}>
                        {tag}
                    </Badge>
                </TooltipComponent>
            );
        });
        return tagsWrap.length > 0 ? <div style={{ display: 'inline-flex', flexWrap: 'wrap', gap: 4 }}>{tagsWrap}</div> : '-';
    };

    const handleConfirmationModal = (record, e, type) => {
        setSelectedModal(type);
        setModalData({ record, e });
        setConfirmationModalOpen(true);
    };

    const handleSearch = (value) => {
        if (value.length === 0) {
            setData(initialData);
        } else {
            const lowercasedValue = value.toLowerCase();
            const filteredData = initialData.filter((item) => {
                const matchesConnectionName = item?.connection_name.toLowerCase().includes(lowercasedValue);
                const matchesEnvName = item?.env_name.toLowerCase().includes(lowercasedValue);
                const matchesTags = item?.tags && item.tags.some((tag) => tag.toLowerCase().includes(lowercasedValue));

                const matchesProducerTopicsPartitions =
                    item?.producer_topics_partitions && Object.keys(item.producer_topics_partitions).some((key) => key.toLowerCase().includes(lowercasedValue));

                const matchesConsumerGroupTopicsPartitions =
                    item?.consumer_group_topics_partitions &&
                    Object.keys(item.consumer_group_topics_partitions).some((key) => key.toLowerCase().includes(lowercasedValue));

                return matchesConnectionName || matchesEnvName || matchesTags || matchesProducerTopicsPartitions || matchesConsumerGroupTopicsPartitions;
            });

            setData(filteredData);
        }
    };

    const handleSelectedFilters = (urlParams = false, action = null) => {
        const filteredData = initialData.filter((item) => {
            const itemType = item.is_producer ? 'producer' : item.is_consumer ? 'consumer' : !item.is_consumer && !item.is_producer ? 'admin' : undefined;
            const isValidCluster =
                selectedFilters.listOfClusters.length === 0 ||
                selectedFilters.listOfClusters.some((cluster) => (cluster === 'orphan_clients' ? '' : cluster) === item?.connection_name);
            return (
                (selectedFilters.listOfDataPlanes.length === 0 || selectedFilters.listOfDataPlanes.includes(item?.env_name)) &&
                isValidCluster &&
                (selectedFilters.type.length === 0 || selectedFilters.type.includes(itemType))
            );
        });
        setData(filteredData);
        setFilterState(selectedFilters);

        if (urlParams) {
            if (action === 'reset') {
                setData(initialData);
                setFilterState({});
            }

            const searchParams = new URLSearchParams();
            selectedFilters.listOfDataPlanes.forEach((plane) => searchParams.append('engine', plane));
            selectedFilters.listOfClusters.forEach((cluster) => searchParams.append('connection', cluster));
            selectedFilters.type.forEach((type) => searchParams.append('type', type));
            navigate({ search: action === 'reset' ? '' : searchParams.toString() }, { replace: true });
        }
    };

    const openDrawer = (record) => {
        setDrawerOpen(true);
        setSelectedClient(record);
        setSelectedClientReduction(record?.reduction_enabled);
    };

    const drawerInfoData = () => {
        const data = [
            {
                title: 'ID',
                value: selectedClient?.id
            },
            {
                title: 'Engine',
                value: selectedClient?.env_name || '-'
            },
            {
                title: 'Connection name',
                value: selectedClient?.connection_name || '-'
            },
            {
                title: 'Language',
                value: getIconByLang(selectedClient?.language) || '-'
            },
            {
                title: 'Type',
                value: (
                    <div style={{ display: 'inline-flex' }}>
                        {selectedClient?.is_consumer && <span style={{ color: 'var(--primary-color)' }}>Consumer</span>}
                        {selectedClient?.is_producer && selectedClient?.is_consumer && <span style={{ marginRight: 3 }}>,</span>}
                        {selectedClient?.is_producer && <span style={{ color: 'var(--primary-color)' }}>Producer</span>}
                        {!selectedClient?.is_consumer && !selectedClient?.is_producer && <span style={{ color: 'var(--primary-color)' }}>Admin</span>}
                    </div>
                )
            },
            {
                title: 'Tags',
                value: getFormattedTags(selectedClient)
            }
        ];
        return data.map((item, index) => {
            return (
                <div className="client-drawer-body-info-item" key={index}>
                    <div className="left">{item.title}</div>
                    <div className="right">{item.value}</div>
                </div>
            );
        });
    };

    const { components, resizableColumns } = useResizableColumns(columns);

    const handleScroll = (event) => {
        const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
        if (scrollTop + clientHeight === scrollHeight) {
            const nextLoadedItems = data?.slice(0, loadedItems.length + itemsPerScroll);
            setLoadedItems(nextLoadedItems);
        }
    };

    return loading ? (
        <Loader background={false} isFullHeight={true} />
    ) : (
        <div className="clients-page">
            <div className="clients-page-table">
                <Table
                    components={components}
                    columns={resizableColumns}
                    dataSource={loadedItems}
                    pagination={false}
                    loading={loading}
                    rowKey={(record) => record.id}
                    onCustomRowClick={(record) => openDrawer(record)}
                    scroll={{ x: 1000, y: 'calc(100vh - 200px)' }}
                    onScroll={handleScroll}
                />
            </div>

            <Drawer
                title={
                    <div className="client-drawer-title">
                        <span>
                            <ClientsIcon className="client-icon" />
                            Client #{selectedClient?.id}
                            {selectedClient?.errors && <BiError className="error-icon" />}
                        </span>
                        {selectedClient.errors && <Button placeholder="Activity logs" onClick={() => flipLogModal(true)} />}
                    </div>
                }
                placement="right"
                width={'560px'}
                destroyOnClose={true}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                className="client-drawer"
                closeIcon={false}
            >
                <div className="client-drawer-body">
                    <div className="client-drawer-body-top">
                        <div className="client-drawer-body-info">{drawerInfoData()}</div>
                        <div className="client-drawer-body-options">
                            {(countKeys(selectedClient?.producer_topics_partitions) > 0 || countKeys(selectedClient?.consumer_group_topics_partitions)) > 0 && (
                                <div className="topic-options">
                                    <div className="options-title">Connected topics</div>
                                    <div className="topic-options-main">
                                        {countKeys(selectedClient?.producer_topics_partitions) > 0 && (
                                            <div className="topic-options-main-column">
                                                <div className="topic-option-label option-desc">Produce to</div>
                                                <div className="topic-options-option option-label">
                                                    {Object.keys(selectedClient?.producer_topics_partitions)?.map((item) => (
                                                        <div key={`produce-${item}`}>{item}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                        {countKeys(selectedClient?.consumer_group_topics_partitions) > 0 && (
                                            <div className="topic-options-main-column">
                                                <div className="topic-option-label option-desc">Consume from</div>
                                                <div className="topic-options-option option-label">
                                                    {Object.keys(selectedClient?.consumer_group_topics_partitions)?.map((item) => (
                                                        <div key={`consume-${item}`}>{item}</div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="client-drawer-body-bottom">
                        <div className="client-drawer-body-footer">
                            <Button customClassName="modal-btn modal-btn-secondary" placeholder="Close" onClick={() => setDrawerOpen(false)} />
                            <Button
                                customClassName="modal-btn modal-btn-primary"
                                placeholder="Save"
                                disabled={selectedClient?.reduction_enabled === selectedClientReduction}
                                onClick={(e) => handleConfirmationModal(selectedClient, e, 'reduction')}
                            />
                        </div>
                    </div>
                </div>
            </Drawer>

            <ClientLogs open={logModalOpen} clickOutside={() => flipLogModal(false)} clientId={selectedClient?.id} />

            <Modal header="" width="520px" height="240px" displayButtons={false} clickOutside={() => setConfirmationModalOpen(false)} isModalOpen={confirmationModalOpen}>
                <DeleteItemsModal
                    title={modalContent[selectedModal]?.title}
                    desc={modalContent[selectedModal]?.desc}
                    buttontxt={<>I understand, {modalData?.e ? 'Turn on' : 'Turn off'}</>}
                    handleDeleteSelected={() =>
                        selectedModal === 'reduction' ? onReductionChanged(modalData?.record, modalData?.e) : onCompressionChanged(modalData?.record, modalData?.e)
                    }
                    textToConfirm={modalData.e ? 'Turn on' : 'Turn off'}
                    loader={loadingReductionSwitch[modalData?.record?.id]}
                    disableInputConfirmation={true}
                />
            </Modal>
        </div>
    );
});

export default Clients;
