import Heading from "components/heading";
import React, { useState } from "react";
import Input from "components/input";
import Button from "components/button";
import { Form } from "antd";
import FormServerMessage from "components/formServerMessage";
import { confirmPasswordReset, signInWithCustomToken } from "firebase/auth";
import { auth } from "services/firebase";
import firebaseMessages from "utils/firebaseMessages";
import { SESSION_STORAGE_FIREBASE_OOB_CODE, SESSION_STORAGE_TOKEN } from "utils/localStorageConsts";
import { useNavigate } from "react-router-dom";
import Alert from "../components/alert";
import { validatePassword } from "./validatePassword";

const SetNewPasswordProfileForm = ({heading}) => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [serverMessage, setServerMessage] = useState("");
    const [isFormValid, setIsFormValid] = useState(false);
    const [resetSuccess, setResetSuccess] = useState(false);
    const [signInTokenLoading, setSignInTokenLoading] = useState(false);
    const navigate = useNavigate();

    const onFinish = async (values) => {
        const {password} = values;

        try {
            setLoading(true);
            await confirmPasswordReset(auth, sessionStorage.getItem(SESSION_STORAGE_FIREBASE_OOB_CODE), password)
                .then(async () => {
                    setResetSuccess(true);
                    sessionStorage.removeItem(SESSION_STORAGE_FIREBASE_OOB_CODE);
                })
        } catch (error) {
            setServerMessage(firebaseMessages(error.code))
        } finally {
            setLoading(false);
        }
    };

    const signInWithToken = async () => {
        setSignInTokenLoading(true);
        await signInWithCustomToken(auth, sessionStorage.getItem(SESSION_STORAGE_TOKEN))
            .then(() => {
                sessionStorage.removeItem(SESSION_STORAGE_TOKEN);
            })
            .finally(() => setSignInTokenLoading(false));
        navigate("/");
    };

    const compareToFirstPassword = (_, value) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(new Error('The two passwords that you entered do not match!'));
        }
        return Promise.resolve();
    };

    const onFieldsChange = (_, allFields) => {
        const errors = form.getFieldsError();
        const allFieldsNotEmpty = allFields.every(field => field.value);
        setIsFormValid(allFieldsNotEmpty && errors.filter(({errors}) => errors.length).length === 0);
    };

    return (
        <div className="ms-form-inner">
            {!resetSuccess ?
                <div className="forget-password-form">
                    <Heading
                        fontSize={24}
                        fontWeight={600}
                    >
                        {heading}
                    </Heading>

                    <div className="form">
                        <Form
                            name="forget-passowrd"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                            onFieldsChange={onFieldsChange}
                        >
                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        validator: validatePassword
                                    },
                                ]}
                            >
                                <Input
                                    type="password"
                                    label="Password"
                                    placeholder="**********"
                                    customClass="rounded"
                                />
                            </Form.Item>

                            <Form.Item
                                name="confirm"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please confirm your password!',
                                    },
                                    {
                                        validator: compareToFirstPassword,
                                    },
                                ]}
                            >
                                <Input
                                    type="password"
                                    label="Confirm Password"
                                    placeholder="**********"
                                    customClass="rounded"
                                />
                            </Form.Item>

                            <FormServerMessage text={serverMessage}/>

                            <Form.Item style={{marginBottom: "0"}}>
                                <Button
                                    customClassName="btn-primary"
                                    placeholder="Continue"
                                    loading={loading}
                                    htmlType="submit"
                                    disabled={!isFormValid}
                                />
                            </Form.Item>
                        </Form>
                    </div>
                </div> :
                <Alert
                    type="success"
                    title="Success"
                    subtitle="Profile setup successfully done!"
                    buttonLabel="Go to dashboard"
                    onClick={() => signInWithToken()}
                    loading={signInTokenLoading}
                />
            }
        </div>
    );
}

export default SetNewPasswordProfileForm;
