import Heading from 'components/heading';
const ConnectorModalHeader = ({ icon, title, subtitle, goBack }) => {
    return (
        <>
            <div className="ms-modal-header-left" onClick={goBack}>
                {icon}
            </div>
            <div className="ms-modal-header-right">
                <Heading fontSize="24px" fontWeight="600">
                    {title}
                </Heading>
                <Heading fontSize="12px" fontWeight="400" color="var(--modal-desc-color)">
                    {subtitle}
                </Heading>
            </div>
        </>
    );
};

export default ConnectorModalHeader;
