import React, { createContext, useReducer } from 'react';
import Reducer from './reducer';

export const Context = createContext(null);

export const initialState = {
    isLoadingConnections: true,
    connections: [],
    environments: [],
    currentUser: {},
    isAuthenticated: false,
    isSidebarCollapsed: false,
    newJobIndicator: false,
    isAppLoading: false,
    keysList: []
};

const Store = ({ children }) => {
    const [state, dispatch] = useReducer(Reducer, initialState);

    return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>;
};

export default Store;
