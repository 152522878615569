import { Navigate, Outlet } from 'react-router-dom';
import { Context } from 'hooks/store';
import { useContext } from "react";

const PublicRoute = () => {
    const [state] = useContext(Context);

    return !state?.isAuthenticated ? <Outlet /> : <Navigate to="/" replace />;
};

export default PublicRoute;