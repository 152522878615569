const pathDomains = {
    dashboard: '/',
    signin: '/signin',
    forgetPassword: '/forget-password',
    resetPassword: '/reset-password',
    setupProfile: '/setup-profile',
    authHandler: '/auth-handler',
    users: '/users',
    systemView: '/system-view',
    connectedClusters: '/connected-clusters',
    keys: '/keys',
    profile: '/profile',
    sysLogs: '/logs',
    selectAccount: '/select-account',
    asyncJobs: '/tasks',
    auditing: '/auditing'
};

export default pathDomains;
