import '../style.scss';
import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import Input from 'components/input';
import { FaArrowLeft } from 'react-icons/fa6';
import { FaUndo } from 'react-icons/fa';
import { ReactComponent as AIicon } from 'assets/images/ssmAI.svg';
import TooltipComponent from 'components/tooltip/tooltip';
import { Form } from 'antd';

const ConfigItem = forwardRef(({ title, description, type, label, name, initialValue, configForm, min, max, defaultValue, placeholder, regex }, ref) => {
    const [value, setValue] = useState(initialValue);
    const [error, setError] = useState(null);

    useEffect(() => {
        setValue(initialValue);
        configForm.setFieldsValue({ [name]: initialValue });
    }, [initialValue]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setValue(newValue);
        configForm.setFieldsValue({ [name]: newValue });
        validateValue(newValue);
    };

    const validateValue = (value) => {
        let errorMessage = null;
        try {
            if (type === 'text' && regex && !new RegExp(value)) {
                errorMessage = 'Please input a valid value!';
            } else if ((min !== undefined && value < min) || (max !== undefined && value > max)) {
                errorMessage =
                    min !== undefined && max !== undefined
                        ? `Value must be between ${min} and ${max}`
                        : `Value must be ${min !== undefined ? `greater than ${min}` : `less than ${max}`}`;
            }
            setError(errorMessage);
        } catch (error) {
            errorMessage = 'Please input a valid value!';
            setError(errorMessage);
        } finally {
            return errorMessage === null;
        }
    };

    useImperativeHandle(ref, () => ({
        validate: () => validateValue(value)
    }));

    const resetToDefault = () => {
        configForm.setFieldsValue({ [name]: defaultValue });
        setValue(defaultValue);
        setError(null);
    };

    return (
        <div className="config-item">
            <div className="inputs-section-container">
                <span>
                    <p>{title}</p>
                    <label>{description}</label>
                </span>
                <span className="inputs-section">
                    <Form.Item name={name} validateStatus={error ? 'error' : 'success'} help={error}>
                        <Input
                            label={label}
                            type={type}
                            placeholder={placeholder || initialValue}
                            value={value}
                            min={min || null}
                            max={max || null}
                            onChange={handleChange}
                            suffix={
                                <TooltipComponent text={value !== defaultValue ? 'Set default value' : ''}>
                                    <FaUndo
                                        color="rgba(255, 255, 255, 0.7)"
                                        fontSize="12px"
                                        cursor={configForm.getFieldValue(name) !== defaultValue ? 'pointer' : 'not-allowed'}
                                        onClick={resetToDefault}
                                    />
                                </TooltipComponent>
                            }
                        />
                    </Form.Item>

                    <span className="accept-suggestion">
                        <FaArrowLeft color="var(--color-white)" />
                    </span>
                    <Input
                        label={
                            <span>
                                <span>Superstream suggestion</span>
                                <AIicon />
                            </span>
                        }
                        type="text"
                        placeholder={initialValue}
                        value="Soon"
                        disabled
                    />
                </span>
            </div>
        </div>
    );
});

export default ConfigItem;
