import { Handle, Position } from "reactflow";
import { ReactComponent as AddDataPlanIcon } from "assets/images/graphView/addDataPlan.svg";

const AddDataPlaneNode = ({data}) => {
    return (
        <div className="custom-node add-data-plane-node">
            <div className="custom-node-icon add-data-plane-node-icon nodrag" onClick={data.onClick}>
                <AddDataPlanIcon />
            </div>
            <div className="add-data-plane-node-title nodrag" onClick={data.onClick}>
                {data.label}
            </div>
            <Handle
                type="target"
                position={Position.Left}
                id={data.id}
                style={{ top: "50%", background: "var(--primary-color)" }}
                isConnectable={true}
            />
        </div>
    );
};

export default AddDataPlaneNode;